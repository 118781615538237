@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body {
	margin: 0;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #040707;
	line-height: 20px;
	cursor: default;

	&:has(.no-overflow-body) {
		overflow: hidden;
	}
}

.top-message {
	.slide-overlay {
		.content-wrapper {
			margin: 0 auto;
			padding: 30px 120px 0px 120px !important;

			@media (max-width: 767px) {
				padding: 150px 50px 0 50px !important;
			}

			@media (max-width: 425px) {
				padding-top: 165px !important;
			}
		}
	}
}

* {
	box-sizing: border-box;
	--bigsize: 46px;
	--mediumsize: 30px;
	--smallsize: 24px;
	--microsize: 16px;
	--nanosize: 14px;
	--picosize: 12px;

	@media (max-width: 980px) {
		--bigsize: 38px;
		--mediumsize: 26px;
		--smallsize: 22px;
		--microsize: 14px;
		--nanosize: 12px;
	}

	@media (max-width: 575px) {
		--bigsize: 24px;
		--mediumsize: 22px;
		--smallsize: 18px;
		--microsize: 12px;
		--nanosize: 10px;
	}
}

#impersonation-header {
	position: fixed;
	z-index: 2003;
	width: 100%;
	background: #000;
	color: #fff;
	text-align: center;
	padding: 10px 0;
	bottom: 0;
}

#impersonation-header a {
	margin-left: 30px;
	color: #fff;
	font-weight: bold;
}

a {
	text-decoration: none;
	color: #040707;
}

img {
	max-width: 100%;
	display: block;
}

b,
strong {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

.medium-font {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
}

.black-font {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

button {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;
	color: #040707;
}

.content-wrapper {
	width: 100%;
	max-width: 1230px;
	margin: auto;
	padding: 0 15px;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

header {
	position: relative;
	z-index: 2000;
}

header .topbar {
	background-color: #000;
	color: #ffffff;
	text-align: center;
	padding: 5px 0;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	justify-content: space-between;
}

#top-message {
	color: #fff;
	width: 100%;
	text-align: center;
	margin-right: 10px;
	margin-left: 10px;
}

#top-message > p {
	margin: 0;
}

header .topbar button {
	color: #ffffff;
	font-size: 20px;
	align-self: center;
	margin-right: 10px;
	transform: rotate(90deg);
}

header,
#anchor-links {
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
}

header .content-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	height: 60px;
}

header .visible-menu {
	display: inline-flex;
	column-gap: 35px;
}

header .visible-menu .menu-item a {
	display: inline-block;
	font-family: Ubuntu, sans-serif;
	font-weight: 700;
	color: black;
	font-size: 14px;
}

header #logo {
	display: inline-flex;
	position: absolute;
	left: calc(50% - 49px);
	top: calc(50% - 19px);
}

header #logo a {
	display: inline-block;
	line-height: 0;
}

#burger-btn {
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-right: 35px;

	svg.close-burger {
		display: none;
	}

	&.active {
		svg.close-burger {
			display: initial;
		}

		svg.open-burger {
			display: none;
		}
	}
}

header {
	background: white;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 2002;
	box-shadow: 0px 10px 30px #00000029;

	@media (max-width: 767px) {
		&.home-header {
			box-shadow: none;
			background: transparent;

			#view-search {
				position: absolute;
				top: 65px;
				left: 0;
				z-index: 1;
				width: 100%;

				button {
					width: calc(100% - 44px);
					display: flex;
					margin: 0 auto;
					background: #f2f2f2;
					height: 35px;
					padding: 0 15px;
					justify-content: flex-end;
					align-items: center;
					right: 0;
					border-radius: 5px;
					position: relative;

					&::after {
						position: absolute;
						content: 'Search';
						left: 15px;
						color: #000;
						font-size: 14px;
						font-family: 'Ubuntu', Arial, sans-serif;
					}
				}
			}
		}
	}
}

//Search bar CSS Transition - START
.search-transition-enter {
	opacity: 0.4;
	transform: translateY(-100%);
}

.search-transition-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition:
		opacity 300ms,
		transform 300ms;
}

.search-transition-exit {
	opacity: 1;
	transform: translateY(0);
}

.search-transition-exit-active {
	opacity: 0.2;
	transform: translateY(-100%);
	transition:
		opacity 0s,
		transform 0s;
}

//Search bar CSS Transition - END

.login-register-mobile {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100vw;
	bottom: 0;
	left: 0;
	height: 83px;
	background: white;
	z-index: 2004;
	opacity: 0;
	pointer-events: none;
	transition: 0.2s;

	button {
		width: 46%;
		max-width: 164px;
		height: 45px;
		display: inline-flex;
		border: 1px solid black;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		align-items: center;
		margin: 0 6px;

		&.register-button {
			background: black;
			color: white;
		}
	}

	@media (min-width: 481px) {
		display: none;
	}

	&.active {
		opacity: 1;
		pointer-events: none;

		button {
			pointer-events: auto;
		}
	}
}

#top-home-search {
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;
	display: block;
	position: absolute;
	top: 65px;
	left: 0;
	z-index: 1;
	width: 100%;

	button {
		width: calc(100% - 44px);
		display: flex;
		margin: 0 auto;
		background: #f2f2f2;
		height: 35px;
		padding: 0 15px;
		align-items: center;
		justify-content: space-between;
	}

	&.active {
		opacity: 1;
		pointer-events: initial;
	}

	@media (min-width: 767px) {
		display: none !important;
	}
}

header.line-header {
	box-shadow: none;
	border-bottom: 1px solid #ababab;
	background: white;

	@media (max-width: 1260px) {
		border-bottom: none;
	}
}

header .header-options {
	display: inline-flex;
	column-gap: 10px;
	margin-left: auto;
}

#menu-modal {
	opacity: 0;
	pointer-events: none;
	position: fixed;
	top: 0px;
	left: -12px;
	bottom: 0;
	background: rgba(255, 255, 255, 0.95);
	z-index: -1;
	display: flex;
	transition: 0.2s;
	max-width: calc(100% + (100vw - 1200px) / 2);
	width: fit-content;
	padding: 20px 27px 60px calc((100vw - 1200px) / 2);
	transition: 0.2s;
	z-index: 2003;

	@media (min-width: 1261px) {
		z-index: 2001;
	}

	@media (max-width: 1260px) {
		width: 100%;
		max-width: 100vw;
		background: white;
		left: 0;
		padding: 0px 30px 0 30px;
	}

	@media (max-width: 980px) {
		padding: 0;
	}

	nav {
		height: 100%;
		overflow: auto;
		overflow-x: hidden;
		min-width: 300px;

		@media (max-width: 1260px) {
			width: 100%;
			min-width: unset;
			margin: 0 auto;
		}

		@media (max-width: 980px) {
			max-width: unset;
		}
	}
}

.main-menu {
	display: flex;
	position: relative;
	gap: 5px;
	flex-wrap: wrap;

	.menu-item {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 9px 11px;
		border-radius: 7px;

		a {
			color: #040707;
			font-family: 'Ubuntu', sans-serif;
			font-weight: 500;
			display: inline-block;
			width: 100%;
		}

		& > a {
			font-weight: 700;
			font-size: var(--microsize);
			color: #ababab;
			text-transform: uppercase;
			cursor: pointer;

			@media (max-width: 980px) {
				font-size: 14px;
			}
		}

		.sub-menu {
			display: none;
			position: absolute;

			.submenu-item {
				width: 200px;
			}

			.submenu-item.has-children:after {
				content: '';
				position: absolute;
				right: 0px;
				top: 0px;
				height: 43px;
				width: 12px;
				background-image: url(../images/sub-menu-right-arrow.svg);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				transform: rotate(270deg);
			}

			.myUL {
				div {
					position: relative;

					&.caret {
						cursor: pointer;
						-webkit-user-select: none;
						/* Safari 3.1+ */
						-moz-user-select: none;
						/* Firefox 2+ */
						-ms-user-select: none;
						/* IE 10+ */
						user-select: none;
					}

					&.caret::after {
						content: '';
						position: absolute;
						right: 40px;
						top: 0px;
						height: 46px;
						width: 17px;
						background-image: url(../images/sub-menu-right-arrow.svg);
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						transform: rotate(-90deg);
						-webkit-transition: 0.5s ease-in-out;
						-moz-transition: 0.5s ease-in-out;
						-o-transition: 0.5s ease-in-out;
					}

					&.caret-down::after {
						-ms-transform: rotate(45deg);
						/* IE 9 */
						-webkit-transform: rotate(45deg);
						/* Safari */
						transform: rotate(1deg);
						-webkit-transition: 0.5s ease-in-out;
						-moz-transition: 0.5s ease-in-out;
						-o-transition: 0.5s ease-in-out;
					}
				}

				a {
					display: block;
					font-size: 16px;
					font-weight: 400;
					margin-bottom: 20px;
				}

				li {
					&.nested {
						display: none;
					}

					&.active {
						display: block;
					}
				}
			}

			a {
				display: block;
				padding-left: 20px;
				font-size: var(--microsize);
				font-weight: 400;
				margin-bottom: 20px;

				@media (max-width: 980px) {
					margin: 0;
					padding: 11.5px 18px;
					font-size: 16px;
				}
			}

			.info-item {
				margin-top: 30px;

				.item-title {
					display: block;
					position: relative;
					padding-left: 20px;
					font-weight: 700;
					cursor: pointer;

					.icon {
						display: inline-block;
						position: absolute;
						right: 0;

						svg.close-info {
							display: none;
						}

						@media (max-width: 980px) {
							right: 18px;
						}
					}

					&.active {
						svg.close-info {
							display: inline-block;
						}

						svg.open-info {
							display: none;
						}
					}

					@media (max-width: 980px) {
						margin: 0;
						padding: 14px 18px;
						font-size: 16px;
					}
				}

				.info-sub-menu {
					display: none;
					padding: 15px 0;

					&.active {
						display: block;
					}
				}
			}
		}

		&.active {
			background: black;

			& > a {
				color: white;
			}

			.sub-menu {
				display: block;
				top: 100%;
				left: 0;
				padding: 20px 0;
				width: 100%;

				@media (max-width: 1260px) {
					width: 100%;
					max-width: unset;
				}

				@media (max-width: 980px) {
					padding: 13.5px 0 85px 0;
				}
			}
		}
	}

	@media (max-width: 980px) {
		padding: 17px;
		box-shadow: 0px 5px 30px rgb(0 0 0 / 16%);
	}
}

#external li a {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

#user-options {
	text-align: left;
	padding-left: 18px;
	border-bottom: solid 1px #5b5b5b;
	max-width: 155px;
	margin: auto;
}

#user-options li {
	display: flex;
	align-items: center;
}

#user-options a {
	font-size: 13px;
}

#user-options span {
	margin-right: 10px;
}

/*#lang-flag {
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    background-size: 100%;
}

.lang-gr {
    background-image: url(../images/lang-gr.svg);
}

.lang-en {
    background-image: url(../images/lang-en.svg);
}*/

.left-lang {
	display: none;
}

.lang-change {
	margin-left: 10px;
}

#lang-name {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	text-decoration: underline;
	font-size: 16px;
	vertical-align: middle;
	position: relative;
	padding-left: 12px;
	line-height: 22px;
}

#lang-name:before {
	position: absolute;
	content: '';
	left: 0px;
	top: 0;
	width: 2px;
	height: 22px;
	border-left: 2px solid #c8c8c8;
	bottom: 0;
	margin: auto;
}

#lang-name:hover {
	text-decoration: none;
}

@media (max-width: 600px) {
	.left-lang {
		display: block;
		margin: 0;
	}

	.right-lang {
		display: none;
	}

	#lang-name {
		padding: 0;
	}

	#lang-name:before {
		content: none;
	}
}

#lang-switch {
	text-decoration: underline;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

#lang-switch:hover,
#lang-switch:focus {
	color: #fb4401;
}

#close-modal {
	text-align: right;
	position: absolute;
	right: 0;
	cursor: pointer;
	padding: 5px 18px 5px 5px;
}

h1 {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: var(--bigsize);
	margin: 0;
}

.page-heading {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	letter-spacing: -0.44px;
	font-size: 22px;
	text-align: center;
	line-height: 26px;
	margin: 0;
}

h2 {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 20px;
	line-height: var(--mediumsize);
	text-align: center;
	margin: 0;
}

h3 {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: var(--smallsize);
	line-height: 18px;
	margin: 0 0 6px;
}

h4 {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: var(--microsize);
	line-height: 17px;
	margin: 0 0 20px;
	text-align: center;
}

.top-page {
	position: relative;
	z-index: 200;
	padding-top: 37px;
}

#main-wrapper,
.main-wrapper {
	margin-top: 60px;

	@media (max-width: 767px) {
		&.home {
			margin-top: 0;
		}
	}
}

#anchor-links {
	text-align: center;
	margin: 24px 0 20px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	color: #040707;
}

#scroll-to-top {
	cursor: pointer;
	margin: 0 8px;
}

#anchor-links a {
	font-size: 13px;
	margin: 0 25px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	color: #040707;
}

#anchor-links a.active,
#anchor-links span.active {
	color: #fc4400;
	border-bottom: solid 1px #fc4400;
}

#anchor-links a:hover,
#anchor-links a:focus,
#anchor-links span:hover,
#anchor-links span:focus {
	color: #fc4400;
}

#anchor-links.sticky {
	position: fixed;
	left: 0;
	top: 59px;
	right: 0;
	background: #b6bac0;
	z-index: 2000;
	padding: 38px 0 7px;
	margin: 0;
	box-shadow: 0px 10px 30px #76767629;
}

/* HOMEPAGE */
.light-gray {
	background-color: rgba(244, 246, 249, 0.5);
}

#home-slider {
	margin-top: -60px;
	padding-bottom: 34px;
}

#homepage {
	.espa-banner {
		display: flex;
		z-index: 9999;
		max-height: 100%;
		position: fixed;
		bottom: 6px;
		left: 6px;
		height: 70px;
		width: 240px;
		box-shadow: 1px 1px 6px 4px rgba(0, 0, 0, 0.221);
		border-radius: 3px;

		a,
		img {
			width: 100%;
			height: 100%;
			border-radius: 3px;
		}

		@media (max-width: 900px) {
			display: none;
		}
	}

	.splide__list {
		li:nth-child(odd) {
			padding-top: 24px;
		}
	}
}

#home-slider .content-wrapper {
	max-width: 780px;
	padding: 0;
}

.raffle-carousel .owl-item {
	padding: 60px 90px;
}

.raffle-carousel-main,
.raffle-registration {
	background-color: #f4f6f9;
	width: 100%;
	border-radius: 25px;
}

.pop-filters button {
	line-height: 30px;
}

.raffle-carousel-main {
	box-shadow: 2px 7px 70px #434b5759;
	padding: 22px 35px 25px;
	position: relative;
}

.raffle-carousel-main .brand-icon {
	margin-bottom: 20px;
}

.raffle-registration {
	height: 101px;
	margin-top: 15px;
	box-shadow: 2px 7px 29px #434b5759;
	padding: 20px 20px 17px;
	text-align: center;
}

.raffle-carousel-name {
	margin: 25px 0 8px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 22px;
}

.raffle-carousel-name a {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 22px;
	color: #040707;
}

.raffle-carousel-price {
	color: #fc4400;
	font-size: 22px;
}

.raffle-carousel-img {
	display: flex;
	justify-content: center;
	align-items: center;
}

.raffle-carousel .owl-nav button {
	font-size: 0 !important;
}

.slider-go-to-prod a {
	width: 60px;
	height: 60px;
	border: solid 1px #fc4400;
	border-radius: 50%;
	position: absolute;
	right: 50px;
	bottom: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-go-to-prod a:hover {
	background-color: #fc4400;
}

.slider-go-to-prod a:hover svg path {
	fill: #fff;
}

.registation-heading {
	font-size: 15px;
}

.registation-table {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
}

.registation-cell {
	font-size: 10px;
}

.registation-cell span {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 20px;
	display: block;
	border-left: solid 1px #040707;
	padding: 2px 12px;
	width: 50px;
}

.registation-cell:first-child span {
	border-left: none;
}

.owl-carousel .owl-item img {
	margin: auto;
	max-width: 100%;
	height: 300px;
	object-fit: contain;
}

.raffle-carousel .owl-dots {
	text-align: center;
	margin-top: -40px;
	position: relative;
}

.raffle-carousel .owl-dot {
	background: #f1f1f1 !important;
	width: 16px;
	height: 16px;
	margin: 0 5px;
	border-radius: 50%;
	cursor: pointer;
}

.raffle-carousel .owl-dot.active {
	background: #fc4400 !important;
}

.raffle-carousel .owl-nav button {
	position: absolute;
	width: 20px;
	height: 20px;
	top: 225px;
	background-image: url(../images/slider-arrow.svg) !important;
	background-repeat: no-repeat !important;
	background-position: center !important;
}

.raffle-carousel .owl-nav button.owl-prev {
	transform: rotate(-180deg);
	left: 120px;
}

.raffle-carousel .owl-nav button.owl-next {
	right: 120px;
}

.raffle-category {
	padding: 34px 0 45px;
}

.raffle-products {
	margin-top: 35px;
}

.raffle-prod {
	display: flex;
	align-items: center;
	margin-bottom: 35px;
	position: relative;
}

.raffle-prod a {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

.raffle-prod-img {
	background-color: #f4f6f9;
	border-radius: 25px;
	box-shadow: 2px 7px 29px #434b5759;
	width: 108px;
	height: 108px;
	padding: 6px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.raffle-prod-img img {
	max-width: 100%;
}

.raffle-prod-img .brand-icon {
	margin-bottom: 4px;
}

.raffle-prod-details {
	margin-left: 42px;
	flex: 1;
}

.default-btn {
	width: 127px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	line-height: 13px;
	border-radius: 13px;
	box-shadow: 2px 7px 29px #434b5759;
}

.default-btn span {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	margin-left: 5px;
}

.black-btn {
	color: #fff;
	background-color: #040707;
}

.white-btn {
	color: #040707;
	background-color: #fff;
}

.raffle-prod-price {
	font-size: 15px;
	margin-bottom: 8px;
}

.go-to-prod {
	margin-left: auto;
	width: 77px;
	height: 72px;
	background-image: url(../images/arrow-right-shadow.svg);
	background-position: center;
	background-repeat: no-repeat;
}

.raffle-prod:hover > .go-to-prod {
	-webkit-animation: bounceright 0.3s alternate ease 4 forwards;
	animation: bounceright 0.3s alternate ease 4 forwards;
}

.raffle-prod:hover > .go-to-prod {
	display: block;
}

.raffle-finished .raffle-prod-details,
.raffle-finished img {
	opacity: 0.3;
}

#recent {
	background-color: #f4f6f9;
}

#recent .raffle-prod-img {
	background-color: #fff;
}

.load-more {
	text-align: center;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 16px;
	cursor: pointer;
	margin-top: 58px;
	display: none;
}

.load-more.show {
	display: block;
}

.load-more span {
	display: block;
	margin-top: 10px;
}

/* PRODUCT PAGE */
#product {
	padding: 40px 0;

	.top-wrapper {
		display: flex;
		justify-content: space-between;

		@media (max-width: 920px) {
			flex-flow: column nowrap;
			padding: 0 22px;
		}
	}

	.product-info {
		max-width: 390px;
		margin: 0 0 0 15px;

		.product-title {
			display: inline-block;
			font-size: 20px;
			font-weight: 400;
			margin: 28px 0 12px 0;
			line-height: 1;

			@media (max-width: 920px) {
				margin: 15px 0 5px 0;
			}
		}

		.product-code {
			font-size: var(--microsize);
			color: #b1adad;
			margin-bottom: 30px;

			@media (max-width: 920px) {
				font-size: 14px;
				margin: 0 0 15px 0;
			}
		}

		.price {
			font-size: (--nanosize);
			margin-bottom: 25px;
			color: #b1adad;

			.price-now {
				font-size: 18px;
				margin-bottom: 2px;
				color: #040707;
				font-weight: 700;
			}

			span {
				font-weight: 700;
				font-size: (--picosize);
				margin-left: 10px;

				@media (max-width: 767px) {
					margin: 0 0 0 10px;
				}
			}

			@media (max-width: 767px) {
				margin: 0 0 15px 0;
			}
		}

		.color-container {
			margin-bottom: 35px;

			span {
				font-weight: 700;
			}

			.prod-colors {
				margin-top: 12px;

				label {
					width: 25px;
					height: 25px;
					border-radius: 5px;
					outline: 1px solid #cecece;
					cursor: pointer;
				}

				input[type='radio']:checked + label {
					outline: 2px solid #000;
				}
			}

			@media (max-width: 920px) {
				margin: 0 0 20px 0;
			}
		}

		.sizes {
			display: flex;
			flex-flow: row wrap;
			column-gap: 10px;
			margin-bottom: 25px;

			.size-box {
				height: 34px;
				border-radius: 5px;
				box-shadow: 0px 4px 6px #00000029;
				margin-bottom: 10px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				line-height: 13px;
				font-weight: 500;
				min-width: 34px;
				padding: 0px 3px;
			}

			.selected-size {
				border: 1px solid #000;
				background-color: #f2f2f2;
				box-shadow: none;
			}

			input[type='radio']:checked + label {
				border: 1px solid #000000;
				background: #f2f2f2;
			}

			@media (max-width: 767px) {
				margin: 0 0 10px 0;
			}
		}

		.recommended-size {
			font-size: var(--microsize);
			text-decoration: underline;
			margin-bottom: 27px;
			cursor: pointer;

			span {
				font-weight: 700;
			}

			@media (max-width: 920px) {
				margin-bottom: 14px;
			}
		}

		.buy-section {
			display: flex;
			flex-wrap: wrap;
			column-gap: 10px;

			.add-to-cart {
				padding: 10px 42px;
				height: 48px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background: black;
				border-radius: 5px;
				background: black;
				font-size: var(--microsize);
				color: white;
				cursor: pointer;
			}

			.add-wishlist {
				width: 48px;
				height: 48px;
				border-radius: 5px;
				background: #f2f2f2;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

			.add-wishlist.active svg path {
				fill: #000;
			}

			.missing-size {
				display: block;
				width: 100%;
				color: #717171;
				font-size: var(--nanosize);
				margin: 12px 0;

				a {
					display: inline-block;
					text-decoration: underline;
					color: #717171;
				}
			}

			margin-bottom: 40px;

			@media (max-width: 920px) {
				margin-bottom: 0;
			}
		}

		.description-section {
			margin: 0 0 30px 0;

			.drop-item {
				width: 100%;
				display: block;

				&:nth-child(1) {
					border-bottom: 1px solid;
				}

				.drop-title {
					display: block;
					cursor: pointer;
					position: relative;
					padding: 18px 20px 20px 0px;

					&:before {
						position: absolute;
						content: '';
						width: 11px;
						height: 100%;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url(../images/open-text.svg);
						top: 0;
						right: 4px;
					}

					&:after {
						position: absolute;
						content: '';
						width: 11px;
						height: 100%;
						background-repeat: no-repeat;
						background-size: contain;
						background-position: center;
						background-image: url(../images/close-text.svg);
						top: 0;
						right: 4px;
						display: none;
					}

					&.active {
						&:before {
							display: none;
						}

						&:after {
							display: block;
						}
					}
				}

				.drop-subtext {
					display: none;
					font-size: var(--picosize);
					display: none;
					/*                    margin-top: -17px;*/
					margin-bottom: 10px;
				}
			}

			@media (max-width: 920px) {
				margin-bottom: 25px;
			}
		}

		#combine-products {
			.combine-title {
				font-weight: 700;
				text-transform: uppercase;
				margin: 0 0 22px 0;
				font-size: var(--microsize);

				@media (max-width: 920px) {
					font-size: 16px;
				}
			}

			.combine-products {
				display: flex;
				justify-content: space-between;

				.product-item {
					display: inline-block;
					max-width: 180px;

					.image-container {
						width: 100%;
						height: 245px;
						margin-bottom: 13px;

						@media (max-width: 920px) {
							height: 218px;
						}

						img {
							width: 100%;
							height: inherit;
							object-fit: cover;
							object-position: center;
						}
					}

					.product-status {
						display: block;
						font-size: 10px;
						color: #28c790;
						margin: 0 0 8px 0;
						line-height: 1;
					}

					.product-title {
						display: block;
						font-size: var(--nanosize);
						font-weight: 400;
						color: black;
						margin: 0;
						line-height: 1;
						margin: 0 0 8px 0;
					}

					.product-price {
						display: block;
						font-size: var(--nanosize);
						font-weight: 500;
						color: black;
						line-height: 1;
					}
				}

				@media (max-width: 920px) {
					gap: 10px;
					justify-content: flex-start;
				}
			}
		}

		@media (max-width: 920px) {
			max-width: 580px;
			margin: 0;
		}
	}
}

.suggested-products {
	@media (max-width: 480px) {
		display: none;
	}
}

#suggested-products {
	display: flex;
	justify-content: space-between;
	width: 380px;

	@media (max-width: 480px) {
		display: none;
	}
}

#suggested-products .product-item {
	display: inline-block;
	max-width: 180px;
}

#suggested-products .product-item .image-container {
	width: 100%;
	height: 245px;
	margin-bottom: 13px;
}

#suggested-products .product-item .image-container img {
	width: 100%;
	height: inherit;
	object-fit: cover;
	object-position: center;
}

#suggested-products .product-title {
	display: block;
	font-size: var(--nanosize);
	font-weight: 400;
	color: black;
	margin: 0;
	line-height: 1;
	margin: 0 0 8px 0;
}

#suggested-products .product-price {
	display: block;
	font-size: var(--nanosize);
	font-weight: 500;
	color: black;
	line-height: 1;
}

.images-area {
	display: flex;

	@media (max-width: 480px) {
		position: relative;
		max-width: 331px;
		margin: auto;
	}

	.thumb-column {
		display: flex;
		flex-flow: column nowrap;
		row-gap: 24px;
		margin-right: 24px;

		.thumb-item {
			width: 78px;
			height: 78px;
			border-radius: 5px;
			overflow: hidden;
			cursor: pointer;
			border: 1px solid transparent;

			&.active {
				border: 1px solid black;
			}

			img {
				width: 100%;
				height: 100%;
				display: inline-block;
				object-fit: cover;
				object-position: center;
			}
		}

		@media (max-width: 480px) {
			.thumb-item {
				width: 16px;
				height: 16px;
				border-radius: 100%;
				border-color: white;
				border-width: 2px;

				&.active {
					border-color: white;
					background: white;
				}

				img {
					display: none;
				}
			}

			z-index: 1;
			position: absolute;
			margin: 0 0 0 auto;
			right: 10px;
			top: 0;
			height: 100%;
			width: fit-content;
			max-width: 310px;
			row-gap: 7px;
			justify-content: center;
			align-items: flex-end;
		}
	}

	#images-control-overlay {
		position: relative;

		.images-column {
			display: flex;
			row-gap: 15px;
			flex-flow: column nowrap;
			height: 780px;
			overflow-y: scroll;
			scrollbar-width: none;
			scroll-behavior: smooth;
			position: relative;

			@media (max-width: 1200px) {
				height: 535px;
			}

			@media (max-width: 920px) {
				height: 444px;
			}

			&::-webkit-scrollbar {
				width: 0;
			}

			.image-item {
				width: 588px;
				height: 774px;

				@media (max-width: 1200px) {
					width: 400px;
					height: 527px;
				}

				@media (max-width: 920px) {
					width: 331px;
					height: 436px;
				}

				img {
					width: inherit;
					height: inherit;
					display: inline-block;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}

	@media (max-width: 920px) {
		margin-bottom: 9px;
		justify-content: center;
	}
}

#breadcrumbs {
	display: flex;
	width: 100%;
	font-size: var(--picosize);
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;

	.bread-item {
		position: relative;
		padding-right: 5px;
		margin-right: 12px;

		&:after {
			content: '/';
			position: absolute;
			display: inline-block;
			left: 100%;
		}

		&:nth-last-child(1) {
			color: #b1adad;
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;

			&:after {
				display: none;
			}
		}

		a {
			color: #000000;
		}
	}
}
.sizeguide-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	padding: 10px;
}

.sizeguide-modal {
	background: #fff;
	padding: 20px;
	width: 100%;
	max-width: 600px;
	border-radius: 8px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
	position: relative;
	overflow-y: auto;
	max-height: 80vh;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 90%;
		max-height: 70vh;
	}

	@media (max-width: 480px) {
		padding: 10px;
		max-width: 95%;
		max-height: 60vh;
	}
}

.sizeguide-content {
	margin-top: 20px;
	font-size: 1rem;

	@media (max-width: 768px) {
		font-size: 0.9rem;
	}

	@media (max-width: 480px) {
		font-size: 0.85rem;
	}

	.size-table {
		width: 100%;
		border-collapse: collapse;
		margin-top: 10px;

		th,
		td {
			border: 1px solid #d3d3d3;
			padding: 8px;
			text-align: center;
		}

		th {
			background-color: #f3f3f3;
			font-weight: bold;
		}
	}
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: transparent;
	border: none;
	font-size: 1.5rem;
	color: #333;
	cursor: pointer;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		color: #666;
	}
}

.fixed-buttons-homepage {
	position: fixed;
	bottom: 80px;
	right: 25px;
	z-index: 5000;

	@media (max-width: 1024px) {
		bottom: 50px;
	}

	.buttons-container {
		display: flex;
		flex-direction: column;
		gap: 30px;

		button {
			border-radius: 100%;
			padding: 10px;
			width: 63px;
			height: 63px;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0px 3px 6px #00000029;

			&:hover {
				opacity: 0.7;
			}

			@media (max-width: 1024px) {
				width: 45px;
				height: 45px;
			}
		}

		button.telephone {
			background: transparent linear-gradient(222deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
		}

		button.scrollToTop {
			background-color: #fff;

			img {
				transform: rotate(-90deg);
			}
		}
	}
}

#heritage {
	text-align: center;
	margin-top: 40px;
	font-size: 13px;
}

.img-container-double {
	display: flex;
	justify-content: space-between;
	padding: 15px 0 30px;
	flex-wrap: wrap;
}

.img-container-double > div {
	width: 48.25%;
	position: relative;
	overflow: hidden;
}

.img-container-double > div img,
.img-gallery img {
	width: 100%;
}

.img-container-double > div:hover {
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 50px;
}

.img-container-double > div:hover .zoom-overlay,
.img-gallery > div:hover .zoom-overlay {
	background-color: rgba(244, 246, 248, 0.4);
	content: '';
	background-image: url(../images/plus-icon.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	opacity: 1;
}

.panzoom {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.panzoom__viewport {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 1px;
	margin: auto;
}

.panzoom__content {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	transform: translate3d(0, 0, 0) scale(1);
	transform-origin: 0 0;
	transition: none;
	touch-action: none;
	user-select: none;
}

.is-draggable {
	cursor: move;
	cursor: grab;
}

.is-dragging {
	cursor: grabbing;
}

.img-gallery {
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	padding: 15px 0 30px;
	flex-wrap: wrap;
}

.img-gallery > div {
	width: 50%;
	position: relative;
	overflow: hidden;
}

.product-box {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.product-box .product-statuses {
	display: flex;
	gap: 10px;
	padding-bottom: 5px;
	overflow: clip;
}

.product-box .product-statuses span {
	font-size: 10px;
	font-weight: bold;
	text-transform: uppercase;
	color: #28c790;
	line-height: 10px;
	white-space: nowrap;
}

ol {
	padding: 0 0 0 8%;
}

ol li::marker {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

#raffle-ends,
#raffle-starts {
	font-size: 15px;
	padding-left: 8%;
	margin: 30px 0;
}

#raffle-ends span,
#raffle-starts span {
	margin-right: 32px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
}

.product-bottom form {
	max-width: 404px;
	margin: auto;
}

.custom-select {
	position: relative;
	cursor: pointer;
}

.custom-select select {
	display: none;
}

.select-selected {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 14px;
	padding: 18.5px 16px 18.5px 30px;
	background-color: #fff;
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 28px;
}

.select-selected span {
	display: block;
	width: calc(100% - 36px);
	overflow-x: hidden;
}

.select-selected span.new {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
}

.select-selected.select-arrow-active {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select-selected:after {
	position: absolute;
	content: '';
	top: 10px;
	right: 10px;
	width: 47px;
	height: 39px;
	background-image: url(../images/button-arrow-orange.svg);
	background-size: cover;
	background-position: center;
}

.select-items div {
	color: #040707;
	padding: 3px 8px;
	cursor: pointer;
	line-height: 21px;
}

.select-items div:hover {
	background: #ff0000;
	color: #fff;
}

.select-items {
	padding: 0 30px 10px;
	max-height: 170px;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	background: #fff;
	width: 100%;
	border-bottom-right-radius: 28px;
	border-bottom-left-radius: 28px;
	z-index: 100;
}

.select-hide {
	display: none;
}

.same-as-selected {
	background-color: rgba(0, 0, 0, 0.1);
}

@-webkit-keyframes bounceright {
	from {
		-webkit-transform: translateX(0);
	}

	to {
		-webkit-transform: translateX(10px);
	}
}

#go-to-form-wrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	text-align: center;
	padding: 20px 15px;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(20px);
	-webkit-backdrop-filter: blur(50px);
}

#go-to-form-wrapper.hidden {
	opacity: 0;
	z-index: -1;
}

#go-to-form {
	max-width: 404px;
	margin: 0 auto;
}

.faq-item:not(:last-child) {
	border-bottom: solid 1px rgba(2, 3, 3, 0.07);
}

.faq-heading {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	position: relative;
	padding: 25px 5px;
	cursor: pointer;
}

.faq-heading:after {
	position: absolute;
	content: '';
	top: 15px;
	right: 10px;
	width: 47px;
	height: 39px;
	background-image: url(../images/button-arrow-orange.svg);
	background-size: cover;
	background-position: center;
	transition: 0.3s;
}

.faq-item.active .faq-heading:after {
	transform: rotate(180deg);
}

.faq-content {
	display: none;
	padding: 0 40px 20px 5px;
}

.faq-content .orange {
	color: #ea762e;
}

.faq-content a {
	color: inherit;
	text-decoration: underline;
}

.faq-content a:hover {
	color: #fc4400;
}

/* PRODUCT PAGES */
#popup-top {
	height: 42px;
	display: flex;
	align-items: center;
}

#popup-main {
	padding: 25px 15px 38px;
}

#popup-main .custom-select {
	margin-bottom: 13px;
}

#popup-main .select-selected {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 300;
}

#popup-main.has-background {
	border-radius: 25px;
}

#popup-main.checkout.has-background {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

#popup-top #back-to {
	margin: 0 0 0 13px;
}

#close-popup {
	margin-left: auto;
	cursor: pointer;
	margin-right: 11px;
}

#close-popup.hidden {
	display: none;
}

#close-popup:hover svg path {
	fill: #fff;
	stroke: #fff;
}

#fast-checkout-wrapper {
	margin-top: 25px;
	padding-top: 30px;
	border-top: solid 1px #b6bac0;
}

.option-button {
	background-color: #fff;
	box-shadow: 2px 7px 29px #434b5759;
	border-radius: 50px;
	height: 101px;
	padding: 15px 8px 15px 36px;
	display: flex;
	align-items: center;
	text-align: left;
	width: 290px;
	margin: auto auto 20px;
	cursor: pointer;
}

.option-button:last-of-type {
	margin-bottom: 47px;
}

.button-inner {
	width: 160px;
}

.button-contents {
	display: flex;
	width: 100%;
	align-items: center;
}

.button-icon {
	background-position: center;
	background-repeat: no-repeat;
	margin-right: 18px;
	margin-bottom: auto;
}

.pin-icon {
	width: 22px;
	height: 29px;
	background-image: url(../images/pin-icon.svg);
}

.home-icon {
	width: 26px;
	height: 24px;
	background-image: url(../images/home-icon.svg);
	margin-top: 8px;
}

.form-arrow-right {
	width: 53px;
	height: 48px;
	background-image: url(../images/form-arrow-right.svg);
	background-position: center;
	background-repeat: no-repeat;
	margin: auto;
}

.button-title {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

.button-shipping-cost {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	color: #5fc993;
	margin-top: 6px;
}

#popup-main .select-selected {
	padding: 12px 16px 12px 30px;
}

#popup-main .select-selected:after {
	top: 4px;
}

.option-button.selected {
	border: 1px solid #fc4400;
}

#popup-main .select-items {
	margin-top: -8px;
}

.min-margin input[type='text'],
.min-margin input[type='email'],
.min-margin input[type='password'],
.min-margin input[type='tel'],
input[type='number'] {
	margin-bottom: 13px;
}

.product-part {
	padding: 0 0 24px;
}

.cost-details-part {
	padding: 24px 0 18px;
}

.total-part {
	padding: 18px 0;
}

.product-part .raffle-prod {
	margin-bottom: 3px;
}

table {
	width: 100%;
	line-height: 17px;
}

table.checkout-table th {
	text-align: left;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	padding: 6px 0;
}

table.checkout-table td {
	text-align: right;
	padding: 6px;
	font-size: 15px;
	vertical-align: bottom;
}

.checkout-part p {
	font-size: 12px;
	letter-spacing: 0.24px;
	line-height: 14px;
	margin-bottom: 0;
}

.cost-details-part p {
	margin-top: 9px;
}

.shipping-part p {
	margin-top: 3px;
}

table#shipping-details td {
	color: #5fc993;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
}

.square {
	background-color: #ff0000;
	width: 9px;
	height: 9px;
	display: inline-block;
	margin-left: 5px;
	position: relative;
	top: 1px;
}

table#total th,
table#total td {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

table#total td {
	color: #fc4400;
}

.info {
	background: #000000;
	width: 18px;
	height: 18px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-left: 4px;
	position: relative;
	cursor: pointer;
}

.info span {
	position: relative;
	top: 1px;
}

#cost-details .info {
	top: -3px;
}

#info-subscription,
#info-withholding {
	display: none;
}

/* SUCCESS PAGE */
.full-background {
	background: #f4f6f9;
}

main#success {
	padding: 35px 0 42px;
	text-align: center;
}

.personal-heading {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 26px;
	line-height: 31px;
}

.success-subheading {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 20px;
}

main#success p {
	margin: 25px 0;
}

/* ACCOUNT PAGE */
#popup-main .birth-date-options {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 35px;
}

.birth-date-options.profile-edit input {
	width: 31%;
}

.birth-date-options .select-selected {
	padding-left: 20px !important;
}

.birth-date-options .select-day,
.birth-date-options .select-year {
	width: 27%;
}

.birth-date-options .select-month {
	width: 42%;
}

.birth-date-options .select-items {
	padding: 0 13px 10px;
}

#account-info-wrapper .simple-link {
	margin: 60px 0 0;
}

#footer-top-boxes {
	display: flex;
	justify-content: space-between;

	.footer-box {
		display: inline-flex;
		flex-flow: column nowrap;
		align-items: center;
		text-align: center;
		width: 33.333%;
		padding: 33px 15px;
		justify-content: flex-end;

		.box-title {
			font-weight: 700;
			font-size: 18px;
			margin: 0 0 13px 0;
		}

		.box-subtitle {
			font-size: 16px;
			line-height: 22px;
			margin: 0;

			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}

		&:nth-child(1) {
			background: #d9f4f3;
		}

		&:nth-child(2) {
			background: #ffeebf;
		}

		&:nth-child(3) {
			background: #ffd0d0;
		}
	}
}

#footer-top {
	padding: 60px 0 70px;
	background: white;
}

#footer-top nav a {
	display: block;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 15px;
	margin-bottom: 15px;
}

#footer-top .cms-nav {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

#footer-top .footer-level-1 {
	display: flex;
	justify-content: space-between;

	.newsletter-area {
		width: 50%;

		* {
			max-width: 384px;
		}

		.newsletter-title {
			text-align: left;
			font-size: 32px;
		}

		.newsletter-text {
			font-size: 14px;
			line-height: 22px;
		}

		.newsletter-box {
			margin-bottom: 60px;
		}

		.checkbox-area {
			display: flex;
			justify-content: flex-end;
			padding: 10px 0;
			font-size: var(--picosize);

			input {
				border-color: #ababab;
				margin: 3px 5px;
				height: unset !important;
				width: unset !important;
			}

			a {
				margin: 0 0 0 5px;
				text-decoration: underline;
				color: black;
			}
		}

		.social-links {
			display: flex;
			column-gap: 24px;

			a:hover svg path {
				fill: #000;
			}
		}
	}

	.footer-menu-clmn {
		display: inline-flex;
		flex-flow: column nowrap;
		gap: 30px 0;

		a {
			color: black;
			transition: 0.3s ease-out;
			position: relative;
			width: fit-content;

			@media (min-width: 641px) {
				&:after {
					position: absolute;
					content: '';
					bottom: -2px;
					left: 0;
					right: 0;
					transform: scaleX(0);
					transition: transform 250ms ease-in-out;
					transform-origin: 0% 50%;
				}

				&:hover:after {
					border-bottom: 2px solid #000;
					transform: scaleX(1);
				}
			}
		}
	}
}

.footer-bottom-1 {
	.content-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 21px;

		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
	}

	.payment-methods {
		display: inline-flex;
		gap: 35px 8px;
		justify-content: center;
		align-items: center;
		margin-right: 15px;

		.payment-method-box {
			display: inline-flex;
			border-radius: 5px;
			background: #f0f1f5;
			justify-content: center;
			align-items: center;
			width: 64px;
			height: 32px;
		}
	}

	.partners-area {
		display: inline-flex;
		align-items: center;
		column-gap: 20px;
	}

	.partner-box:first-child img {
		max-width: 250px;
	}
}

.footer-bottom-2 {
	border-top: 1px solid #f4f4f4;
	font-size: var(--picosize);
	padding: 16px 0 11px 0;

	a {
		display: inline-block;
		text-decoration: underline;
		color: inherit;
	}

	.content-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 9px 20px;
		align-items: center;
		text-align: center;
	}

	.bottom-menu {
		a:nth-child(odd) {
			padding-right: 13px;
			border-right: 1px solid;
			margin-right: 14px;
		}

		a:nth-last-child(odd) {
			padding-right: 0;
			border-right: 0;
			margin-right: 0;
		}
	}
}

.subscription {
	position: relative;
	width: 100%;
	height: 48px;
	border-radius: 10px;
	border-top-left-radius: 0;
	border: none;
}

.subscription .add-email {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
	padding: 0 120px 0 20px;
	background: #f4f4f4;
	border-radius: 10px;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
}

.Klaviyo-content {
	height: 295px;
}

.Klaviyo-content div[role='radiogroup'] {
	gap: 10px;
}

#klaviyo-newsletter,
#klaviyo-newsletter-en #klaviyo-newsletter input,
#klaviyo-newsletter-en input {
	border: none !important;
	outline: none;
	padding: 0 20px !important;
	border-radius: 10px !important;
	border-top-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

#klaviyo-newsletter button,
#klaviyo-newsletter-en button {
	padding: 0 20px !important;
	border-radius: 10px !important;
	border-top-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	position: relative;
	left: -5px;
}

#klaviyo-newsletter div.needsclick,
#klaviyo-news div.needsclick,
#klaviyo-newsletter-en div.needsclick {
	margin-right: 0 !important;
	font-size: 12px !important;
}

#klaviyo-news button {
	position: relative;
	font-size: 0 !important;
	width: 34px !important;
}

#klaviyo-news button:after {
	position: absolute;
	content: '';
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	margin: auto;
	height: 9px;
	width: 9px;
	background: url(../images/arrow-white.svg) no-repeat 100%;
}

.subscription .submit-email {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100px;
	border: none;
	border-radius: 0;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 10px;
	outline: none;
	margin: 0;
	padding: 0 20px;
	cursor: pointer;
	background: black;
	color: #ffffff;
	transition:
		width 0.35s ease-in-out,
		background 0.35s ease-in-out;
}

.subscription.done .submit-email {
	width: 100%;
	background: black;
}

.subscription .submit-email .before-submit,
.subscription .submit-email .after-submit {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 38px;
	transition:
		visibility 0.35s ease-in-out,
		opacity 0.35s ease-in-out;
	border-radius: 0;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 10px;
	line-height: 46px;
}

.subscription.done .submit-email .before-submit,
.subscription:not(.done) .submit-email .after-submit {
	visibility: hidden;
	opacity: 0;
}

.subscription .submit-email .after-submit {
	transition-delay: 0.35s;
}

.subscription:not(.done) .submit-email .before-submit,
.subscription.done .submit-email .after-submit {
	visibility: visible;
	opacity: 1;
}

#social {
	margin: 20px 0 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#social a {
	display: block;
	margin: 0 13px;
}

#scrollToTopBtn {
	position: fixed;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	border: 2px solid #ff4500;
	bottom: 90px;
	right: 31px;
	background: #fc4400;
	z-index: 99999999 !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s ease-in-out;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
}

#scrollToTopBtn span {
	display: flex;
	transform: rotate(-90deg);
}

#scrollToTopBtn:hover {
	background: #fff;
	-moz-box-shadow: rgba(0, 0, 0, 0.4) 0 4px 8px;
	-webkit-box-shadow: rgb(0 0 0 / 40%) 0 4px 8px;
	box-shadow: rgb(0 0 0 / 40%) 0 4px 8px;
}

#scrollToTopBtn:hover span svg path {
	fill: #fc4400;
}

body.compensate-for-scrollbar {
	overflow: auto !important;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
	border-color: var(--fancybox-accent-color, rgba(252, 68, 0, 0.96)) !important;
}

.loading-spinner {
	display: none;
	width: 80px;
	height: 80px;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 999;
	background: #fff;
	border-radius: 50%;
}

.loading-spinner.visible {
	display: inline-block;
}

.loading-spinner div {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #000;
	border-radius: 50%;
	animation: loading-spinner 1.2s linear infinite;
}

.loading-spinner div:nth-child(1) {
	animation-delay: 0;
	top: 37px;
	left: 66px;
}

.loading-spinner div:nth-child(2) {
	animation-delay: -0.1s;
	top: 22px;
	left: 62px;
}

.loading-spinner div:nth-child(3) {
	animation-delay: -0.2s;
	top: 11px;
	left: 52px;
}

.loading-spinner div:nth-child(4) {
	animation-delay: -0.3s;
	top: 7px;
	left: 37px;
}

.loading-spinner div:nth-child(5) {
	animation-delay: -0.4s;
	top: 11px;
	left: 22px;
}

.loading-spinner div:nth-child(6) {
	animation-delay: -0.5s;
	top: 22px;
	left: 11px;
}

.loading-spinner div:nth-child(7) {
	animation-delay: -0.6s;
	top: 37px;
	left: 7px;
}

.loading-spinner div:nth-child(8) {
	animation-delay: -0.7s;
	top: 52px;
	left: 11px;
}

.loading-spinner div:nth-child(9) {
	animation-delay: -0.8s;
	top: 62px;
	left: 22px;
}

.loading-spinner div:nth-child(10) {
	animation-delay: -0.9s;
	top: 66px;
	left: 37px;
}

.loading-spinner div:nth-child(11) {
	animation-delay: -1s;
	top: 62px;
	left: 52px;
}

.loading-spinner div:nth-child(12) {
	animation-delay: -1.1s;
	top: 52px;
	left: 62px;
}

@keyframes loading-spinner {
	0%,
	20%,
	80%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}
}

p.error-message {
	color: red;
	font-size: 16px;
	text-align: center;
	font-weight: 700;
}

#sizeSelect .css-6j8wv5-Input:before {
	content: none;
}

#sizeSelect .css-14el2xx-placeholder {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 700;
}

#sizeSelect .css-1s2u09g-control,
#sizeSelect .css-1pahdxg-control {
	height: 56px;
	border-radius: 30px !important;
	margin-bottom: 0;
}

#sizeSelect .css-1pahdxg-control {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

#sizeSelect.css-b62m3t-container.hide .css-1pahdxg-control {
	border-radius: 30px !important;
}

.birth-date-options .css-1s2u09g-control,
.birth-date-options .css-1pahdxg-control {
	margin-bottom: 0;
}

.fancybox__content {
	max-width: 600px;
}

.fancybox__content .orange {
	color: #ea762e;
}

.fancybox__content a {
	color: inherit;
	text-decoration: underline;
}

.fancybox__content a:hover {
	color: #fc4400;
}

.pass-visibillity-icon {
	width: 28px;
	position: absolute;
	right: 12px;
	top: 10px;
	padding: 4px;
	cursor: pointer;
}

#hidePassIcon {
	display: none;
}

.my-account-form input {
	font-size: 14px !important;
}

.PhoneInputCountry {
	position: absolute !important;
	top: 0;
	bottom: 13px;
	left: 32px;
}

input.PhoneInputInput {
	padding-left: 70px;
}

.menu-logo {
	max-width: 122px;
}

/*404 Not Found Page*/
.notfound {
	.content-wrapper {
		padding-top: 70px;
		padding-bottom: 70px;

		*:not(.main-categories-banner) {
			max-width: 398px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			display: block;
		}

		h1 {
			line-height: 1;
			font-size: 30px;
			line-height: 42px;
		}

		p {
			font-size: 16px;
			line-height: 18px;
			margin: 27px 0;
		}

		.backtohome-button,
		.viewall-button {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 48px;
			width: 170px;
			border-radius: 0;
			border-bottom-left-radius: 10px;
			border-top-right-radius: 10px;
			outline: none;
			padding: 0 20px;
			background: black;
			color: #ffffff;
			margin-bottom: 60px;
			transition: box-shadow 0.2s;

			&:hover {
				box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
			}

			svg {
				margin: 0 10px 0 0;
			}
		}

		.main-categories-banner {
			display: flex;
			justify-content: space-between;
			position: relative;
			gap: 10px;

			.viewall-button {
				position: absolute;
				display: flex;
				justify-content: center;
				width: -moz-fit-content;
				width: fit-content;
				bottom: 34px;
				right: 0;
				left: 0;
				min-width: 160px;
				margin: 0 auto;

				svg {
					margin: 0 0 0 10px;
				}
			}
		}
	}
}

/* cms content */
.page-title {
	text-align: left;
	margin-bottom: 30px;
}

.cms-content .rounded {
	border-radius: 50px;
}

.cms-content p {
	margin: 25px 0;
}

.cms-content .orange-btn {
	display: block;
	max-width: 405px;
	margin: 50px auto;
}

.cms-content .icons-wrapper {
	display: flex;
	justify-content: space-between;
	margin: 30px auto;
}

.cms-content .icons-wrapper .icon-col {
	display: flex;
	align-items: center;
	width: 32%;
}

.cms-content .icons-wrapper .icon-col .icon {
	margin-right: 10px;
}

.cms-content .icons-wrapper .icon-col .text {
	font-family: 'Ubuntu', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.cms-content .images-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.cms-content .images-grid img:nth-child(1),
.cms-content .images-grid img:nth-child(2) {
	width: 48.5%;
	margin-bottom: 35px;
}

.cms-content .images-grid img:nth-child(3) {
	max-width: 100%;
}

/* cms content end */

.embla.homepage-slider {
	overflow: hidden;
	position: relative;

	.embla__container {
		display: flex;
	}

	.embla__slide {
		position: relative;
		flex: 0 0 100%;

		iframe {
			@media (max-width: 480px) {
				height: 742px;
			}
		}

		img {
			width: 100%;
			height: 50vw;
			min-height: 550px;
			max-height: 1200px;
			object-fit: cover;
			object-position: top center;

			@media (max-width: 767px) {
				height: 110vw;
				min-height: unset;
			}

			@media (max-width: 480px) {
				height: 742px;
			}
		}

		.slide-overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: linear-gradient(to left, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 110%);

			.content-wrapper {
				max-width: 1300px;
				height: 100%;
				margin: 0 auto;
				padding: 0 15px;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				padding: 0px 120px;

				* {
					max-width: 325px;
				}

				@media (max-width: 767px) {
					padding: 120px 50px 0 50px;
					justify-content: flex-start;
					align-items: center;
					text-align: center;
				}

				@media (max-width: 425px) {
					padding-top: 135px;
				}
			}

			.slider-subtext {
				font-size: var(--microsize);
				margin: 0 0 15px 0;
			}

			.slider-text {
				font-weight: 700;
				font-size: var(--mediumsize);
				line-height: 1.3;
				margin-bottom: 18px;

				p {
					margin: 0;
				}
			}

			.viewall-button {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 48px;
				min-width: 170px;
				width: fit-content;
				border-radius: 0;
				border-bottom-left-radius: 10px;
				border-top-right-radius: 10px;
				outline: none;
				margin: 0;
				padding: 0 38px 0 20px;
				background: black;
				color: #ffffff;
				transition: box-shadow 0.2s;
				font-size: 16px;

				&:hover {
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
				}

				&:after {
					right: 20px;
				}

				&:hover:after {
					width: 18px;
					right: 12px;
				}

				@media (max-width: 480px) {
					font-size: 14px;
					line-height: 16px;
					min-width: 160px;
					height: 45px;
				}
			}
		}
	}

	.home-video {
		display: flex;
		justify-content: center;
		position: relative;

		.banner-cta {
			position: absolute;
			bottom: 20%;
			left: 20%;
			min-width: unset;
			background-color: black;
			color: #fff;
			border: 1px solid #fff;

			@media (max-width: 768px) {
				bottom: 10%;
				left: 50%;
				transform: translate(-50%);
			}
		}
	}

	.embla_controls {
		@media (max-width: 980px) {
			padding: 0;
		}
	}
}

.embla_controls {
	display: flex;
	justify-content: space-between;
	padding: 0 58px;
	position: absolute;
	width: 100%;
	top: calc(50% - 21.5px);
	left: 0;

	.embla_control {
		width: 43px;
		height: 43px;
		border-radius: 25px;
		transition: 0.3s;
		display: inline-flex;
		align-items: center;
		padding: 0 15px;

		.text {
			display: none;
			width: 110px;
			text-align: left;
			font-family: Ubuntu, sans-serif;
			font-weight: 400;
			font-size: 12px;
			white-space: nowrap;
			overflow: hidden !important;
			text-overflow: ellipsis;
		}

		&:hover {
			background: white;
			width: 163px;
		}

		&.embla__prev {
			justify-content: flex-start;

			svg {
				transform: rotate(180deg);
			}

			.text {
				padding-left: 15px;
			}

			&:hover {
				padding-right: 30px;

				.text {
					display: initial;
				}
			}
		}

		&.embla__next {
			justify-content: flex-end;

			.text {
				padding-right: 15px;
			}

			&:hover {
				padding-left: 30px;

				.text {
					display: initial;
				}
			}
		}
	}
}

#quad-puzzle-banner {
	display: grid;
	grid-template-columns: repeat(3, 33.333%);

	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	// row-start/column-start/row-end/column-end.
	.banner-item:nth-child(1) {
		grid-area: 1/1/3/2;
	}

	.banner-item:nth-child(2) {
		grid-area: 1/2/2/3;
	}

	.banner-item:nth-child(3) {
		grid-area: 2/2/3/3;
	}

	.banner-item:nth-child(4) {
		grid-area: 1/3/3/4;
	}

	.banner-item img {
		width: 100%;
		height: 100%;
		transition: 0.4s;

		&:hover {
			transform: scale(1.01);
		}
	}
}

#double-banner {
	display: flex;

	.banner-item {
		width: 50%;
		/* height: 50vw;*/
		position: relative;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			object-fit: contain;
			transition: transform 0.5s;

			@media (max-width: 767px) {
				object-fit: cover;
				width: 100vw;
				height: 100vw;
			}
		}

		.title-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.15);

			h3 {
				font-size: var(--bigsize);
				font-weight: 400;
				color: white;
				transition: 0.5s;
			}
		}

		&:hover {
			img {
				transform: scale(1.01);
			}
		}

		@media (max-width: 767px) {
			width: 100%;
			height: 100vw;
		}
	}

	@media (max-width: 767px) {
		flex-direction: column;
	}
}

#collection-banner {
	display: flex;
	align-items: flex-start;
	height: 810px;
	margin-bottom: 80px;

	@media (max-width: 980px) {
		flex-flow: column-reverse nowrap;
		height: unset;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		margin-bottom: 0;
	}

	.banner-item {
		width: 50%;
		height: inherit;

		&:nth-child(1) {
			background: #f4f4f4;

			.grid-container {
				display: flex;
				flex-flow: column nowrap;
				max-width: 516px;
				margin: 0 auto;
				padding: 0 15px;
				justify-content: center;
				height: inherit;

				@media (max-width: 980px) {
					padding: 24px 15px;
				}
			}

			.category-intro {
				font-size: var(--microsize);
				margin: 0 0 10px 0;
			}

			h3 {
				display: block;
				font-size: var(--mediumsize);
				margin: 0 0 15px 0;
			}

			p {
				margin: 0 0 40px 0;
			}

			.images-container {
				display: flex;
				justify-content: space-between;
				column-gap: 20px;
				height: 34.7vw;
				max-height: 500px;

				.first-part {
					width: 57.6%;
					height: inherit;
					max-height: 500px;

					.img-container {
						width: 100%;
						height: 100%;

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: inherit;
						}
					}

					@media (max-width: 980px) {
						max-width: 100%;
						width: 100%;
						height: 587px;
						max-height: unset;
						margin-bottom: 10px;
					}
				}

				.second-part {
					width: 37%;
					height: 100%;
					display: flex;
					flex-flow: column nowrap;
					justify-content: space-between;
					row-gap: 10px;

					.img-container {
						width: 100%;
						height: 100%;

						img {
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: inherit;
						}
					}

					@media (max-width: 980px) {
						flex-flow: row nowrap;
						max-width: 100%;
						width: 100%;
						gap: 10px;
						height: 218px;
					}
				}

				@media (max-width: 980px) {
					height: unset;
					flex-flow: column nowrap;
					max-height: unset;
				}
			}
		}

		&:nth-last-child(1) {
			position: relative;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				height: inherit;
				object-fit: cover;
				transition: transform 0.5s;
			}

			.title-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				background-color: rgba(0, 0, 0, 0.15);
				color: white;

				.category-intro {
					font-size: var(--microsize);
					line-height: 1;
					margin: 0 0 14px 0;
				}

				h3 {
					font-size: var(--smallsize);
					font-weight: 400;
					color: white;
					transition: 0.5s;
					line-height: 1;
				}
			}
		}

		@media (max-width: 980px) {
			width: 100%;
		}
	}
}

#homepage-motto {
	padding: 176px 0;

	.content-wrapper {
		.motto-title {
			position: relative;
			font-weight: 700;
			font-size: calc(var(--bigsize) + 2px);
			padding: 0 48px;
			display: block;
			text-align: left;
			line-height: 1.2;

			.hashtag {
				position: absolute;
				left: 0;
				top: 0;
				font-weight: 700;
				font-size: 48px;
			}
		}

		& > div {
			font-size: calc(var(--bigsize) - 1px);
			line-height: 1.4;
			max-width: 828px;

			strong {
				font-weight: 500;
				transition: 0.3s ease-out;

				&:hover {
					font-style: italic;
				}
			}
		}
	}

	.motto-link {
		color: black;
		display: inline-block;
		font-size: 16px;
		position: relative;
		padding-right: 24px;
		transition: 0.3s ease-out;
		line-height: 18px;
		color: #000;

		&::after {
			content: '';
			position: absolute;
			background: url(../images/arrow-black.svg) no-repeat right;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 12px;
			height: 12px;
			right: 0;
			transition: 0.3s ease-out;

			@media (max-width: 480px) {
				width: 10px !important;
				height: 11px;
				background-size: cover;
			}
		}

		&:hover {
			padding-right: 26px;
		}

		&:hover:after {
			width: 18px;
			right: -5px;
		}

		@media (max-width: 480px) {
			padding-right: 16px !important;
			font-size: 14px;
		}

		/*        .arrow {
            display: inline-block;
            padding: 0 10px;
            transform: translateY(10%);
        }*/
	}

	@media (max-width: 575px) {
		padding: 32px 0;

		.content-wrapper {
			.motto-title {
				padding: 0 32px;

				.hashtag {
					top: -2px;
					font-size: unset;

					svg {
						width: 24px !important;
						height: 35px !important;
					}
				}
			}

			p {
				margin: 12px 0;
			}
		}
	}
}

.homepage-insta {
	margin-bottom: 147px;

	.title {
		h1 {
			margin-bottom: 36px;

			@media (max-width: 900px) {
				font-size: 24px !important;
			}

			@media (max-width: 480px) {
				font-size: 16px !important;
				line-height: 18px !important;
				margin-bottom: 17px;
			}
		}
	}

	@media (max-width: 575px) {
		margin-bottom: 50px;
	}
}

.product-carousel-container {
	max-width: 100%;
	min-height: 481px;
	position: relative;

	@media (max-width: 1343px) {
		min-height: 35.82vw;
	}

	@media (max-width: 1220px) {
		min-height: 481px;
	}

	@media (max-width: 670px) {
		min-height: 326px;
		height: 88vw;
	}
}

.product-tabs {
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;

	.content-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 63px 0;
		align-items: center;
		position: relative;
		max-width: unset;

		@media (max-width: 1220px) {
			flex-direction: column !important;
			gap: 30px 0;
		}

		@media (max-width: 680px) {
			padding: 45px 0;
		}
	}

	.embla {
		overflow: hidden;
		position: relative;
		padding: 0 20px;
		display: none;

		&.active {
			display: block;
		}

		.embla_controls {
			padding: 0;
			width: 100%;
			top: 0;
			z-index: 3;
			position: initial;

			@media (max-width: 680px) {
				display: none;
			}

			.embla_control {
				padding: 0 15px;
				position: absolute;
				top: calc(44% - 21.5px);
				justify-content: center;
				transition: unset;

				&:hover {
					background: white;
					width: 43px;
					padding: 0;
					justify-content: center;
					box-shadow: 0 0 8px rgba(0, 0, 0, 0.11);
				}

				&.embla__prev {
					left: 3px;
				}

				&.embla__next {
					right: 3px;
				}
			}
		}
	}

	.embla__container {
		display: flex;
		margin: 0 -7px;
	}

	.embla__slide {
		position: relative;
		flex: 0 0 50%;

		img {
			width: 100%;
			height: 50vw;
			object-fit: cover;
			object-position: bottom center;
		}
	}

	.tabs-container {
		max-width: 680px;
		width: 50%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		column-gap: 24px;
		flex-wrap: wrap;

		.mobile-tabs-header {
			display: none;
			width: 100%;
			text-align: center;
			margin-bottom: 20px;

			.mobile-header {
				font-size: 16px;
				margin-bottom: 15px;
			}

			select {
				width: 160px;
				border: 1px solid black;
				background: transparent;
				border-radius: 5px;
				padding: 8px 17px 8px 12px;
				line-height: 1;
				font-size: 12px;
				color: #040707;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}

			@media (max-width: 680px) {
				display: block;
				width: 100%;
			}
		}

		.tabs-header {
			display: flex;
			width: 100%;
			margin-bottom: 24px;
			justify-content: center;

			button {
				display: inline-block;
				font-size: var(--microsize);
				font-weight: 700;
				color: #9d9d9d;
				margin: 0 20px;
				border-bottom: 2px solid transparent;
				border-radius: 0;

				&.active {
					color: black;
					border-color: black;
				}

				&:hover {
					color: black;
				}
			}

			@media (max-width: 680px) {
				display: none;
			}
		}

		.product-box {
			// display: inline-flex;
			// flex-flow: column nowrap;
			width: auto;
			padding: 0px 20px;
			max-width: unset;

			.add-wish {
				top: 8px;
				right: 24px;

				@media (max-width: 680px) {
					top: 0px;
					right: 20px;
				}

				@media (max-width: 575px) {
					right: 9px;
				}
			}

			& > svg {
				top: 15px;
				right: 35px;
			}

			.prod-details {
				background: unset;
			}

			.prod-img {
				position: relative;

				a {
					display: block;
					position: relative;

					img {
						width: 100%;
						height: 100%;

						&.on-hover {
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;

							@media (max-width: 900px) {
								display: none !important;
							}
						}
					}
				}
			}

			@media (max-width: 575px) {
				padding: 0px 5px;
			}
		}

		@media (max-width: 1220px) {
			width: 100%;
			margin: 0;
		}
	}

	.main-image-container {
		max-width: 42.1%;
		width: 49%;

		.image-wrapper {
			position: relative;
			display: inline-block;
			float: right;

			.viewall-button {
				position: absolute;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 48px;
				font-size: 16px;
				min-width: 170px;
				width: fit-content;
				border-radius: 0;
				border-bottom-left-radius: 10px;
				border-top-right-radius: 10px;
				outline: none;
				margin: 0;
				padding: 0 38px 0 20px;
				background: black;
				color: #ffffff;
				left: calc(50% - 85px);
				bottom: 120px;
				transition: box-shadow 0.2s;

				&:hover {
					box-shadow: 0 5px 20px rgba(0, 0, 0, 0.16);
				}

				&:after {
					right: 20px;
				}

				&:hover:after {
					width: 18px;
					right: 12px;
				}
			}
		}

		@media (max-width: 1220px) {
			max-width: unset;
			width: unset;

			.image-wrapper {
				.viewall-button {
					position: initial;
				}

				img {
					display: none;
				}
			}
		}

		@media (max-width: 480px) {
			.image-wrapper {
				.viewall-button {
					font-size: 14px;
					line-height: 16px;
					min-width: 160px;
					height: 45px;
				}
			}
		}
	}

	&.righttoleft {
		.content-wrapper {
			flex-direction: row-reverse;
		}

		.main-image-container {
			.image-wrapper {
				float: left;
			}
		}
	}
}

.banner-groups {
	margin-top: 100px;
	margin-bottom: 60px;

	.section-title {
		font-size: 32px;
		line-height: 37px;
		font-weight: 700;
		font-family: 'Ubuntu', Arial, sans-serif;
		text-align: center;
	}

	.tabs-title-container {
		margin-top: 50px;
		display: flex;
		gap: 36px;
		justify-content: center;
		white-space: nowrap;
		overflow: auto;

		&::-webkit-scrollbar {
			display: none;
		}

		.tab-title {
			font-weight: 700;
			text-transform: uppercase;
			border-bottom: 2px solid transparent;
			border-radius: 0;
			padding-bottom: 2px;
			color: #9d9d9d;

			&.active {
				border-color: black;
				color: black;
			}
		}
	}

	.banners-container {
		margin-top: 50px;
		display: flex;
		gap: 24px;

		.banner {
			.image-links {
				position: relative;
				overflow: hidden;

				img {
					transition: 0.3s ease-in-out;
					aspect-ratio: 1;
				}
			}

			.links {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				flex-direction: column;
				gap: 20px;
				opacity: 0;
				transition: 0.3s ease-in-out;

				@media (max-width: 768px) {
					display: none;
				}
			}

			.banner-link {
				font-size: 16px;
				background-color: #fff;
				height: 48px;
				padding: 0 48px;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.mobile-links {
				margin-top: 12px;
				display: none;
				flex-direction: column;
				gap: 20px;

				@media (max-width: 768px) {
					display: flex;
				}

				.banner-link {
					background-color: black;
					color: #fff;
				}
			}

			&:hover {
				img {
					@media (min-width: 768px) {
						transform: scale(1.07);
						transition: 0.3s ease-in-out;
					}
				}

				.links {
					@media (min-width: 768px) {
						opacity: 1;
						transition: 0.3s ease-in-out;
					}
				}
			}

			.banner-title {
				margin-top: 12px;
				font-size: 20px;
				text-align: left;
				font-weight: 700;
				font-family: 'Ubuntu', Arial, sans-serif;
				min-height: 40px;
			}
		}
	}
}

@media (min-width: 1440px) {
	.product-tabs .content-wrapper {
		max-width: 1440px;
	}
}

.popup {
	display: none;
}

#info-page {
	background-color: #f4f4f4;

	& > .content-wrapper {
		display: flex;
		justify-content: space-between;
		max-width: 1026px;
		padding: 75px 15px 120px 15px;

		@media (max-width: 860px) {
			flex-flow: column nowrap;
			padding: 30px 0;
		}

		#pages-sidebar {
			display: inline-flex;
			flex-flow: column nowrap;
			width: 190px;

			@media (max-width: 860px) {
				display: none;
				/* flex-flow: column nowrap;
                padding: 15px 0 25px 0;
                width: 100%;*/
			}

			.page-button {
				display: block;
				width: 100%;
				padding: 18px 5px 18px 18px;
				line-height: 1;
				margin: 0 0 4px 0;
				border-radius: 5px;
				color: black;
				font-weight: 400;
				text-align: left;

				&.active,
				&:hover {
					background: white;
					font-weight: 700;
				}

				@media (max-width: 860px) {
					padding: 20px 7%;
					border-radius: 0;
				}
			}
		}

		.main-content {
			width: calc(100% - 190px - 15px);
			max-width: 720px;
			background: white;
			padding: 30px 23px;
			border-radius: 5px;
			box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

			@media (max-width: 860px) {
				width: 95%;
				margin: 0 auto;
				max-width: unset;
			}

			.back-section {
				display: none;
			}

			.page-heading {
				font-weight: 400;
				font-size: calc(var(--smallsize) - 4px);
				text-align: center;
				margin: 0 0 20px 0;
			}

			.cms-content > p {
				display: block;
				text-align: center;
				max-width: 485px;
				color: #717171;
				margin: 0 auto 50px auto;
			}

			span {
				margin: 3px 10px 0 0;
			}

			&.return-policy {
				position: relative;
				display: grid;
				padding-bottom: 0;

				p {
					strong {
						display: block;
						margin: 15px 0;
					}
				}

				.contact-section {
					display: block;
					background-color: #f4f4f4;
					margin: 0 -23px;

					@media (max-width: 640px) {
						margin: 0;

						span {
							margin: 0;
						}
					}

					.content-wrapper {
						max-width: 430px;
						display: flex;
						flex-flow: row wrap;
						padding: 10px 0 32px 0;

						& > p {
							display: block;
							width: 100%;
							text-align: center;

							@media (max-width: 640px) {
								margin-top: 0;
								padding: 0 10%;
							}

							@media (max-width: 575px) {
								text-align: left;
							}
						}

						.half-part {
							width: 49%;
							display: flex;
							justify-content: center;

							@media (max-width: 640px) {
								flex-flow: column nowrap;
								align-items: center;
							}

							p {
								margin: 0;

								@media (max-width: 640px) {
									text-align: center;
								}

								@media (max-width: 575px) {
									font-size: 12px;
								}
							}

							&.first {
								border-right: 1px solid #ababab;
							}

							a {
								font-weight: 700;
								color: black;
							}
						}
					}
				}

				.image-container {
					display: flex;
					height: 64px;
					align-items: center;
					justify-content: center;
				}

				.content-buttons {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-around;
					max-width: 390px;
					margin: 0 auto;
					margin-bottom: 78px;

					.button {
						width: 180px;
						height: 100px;
						display: flex;
						flex-flow: column nowrap;
						border-radius: 5px;
						text-align: center;
						align-items: center;
						justify-content: center;
						box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
						margin-bottom: 20px;
						cursor: pointer;
						background: white;

						.title {
							font-weight: 700;
							font-size: var(--nanosize);
						}
					}
				}

				.clicked-content {
					display: none;
				}

				&.clicked {
					.back-section {
						display: block;

						@media (max-width: 640px) {
							padding-top: 40px;
						}
					}

					& > .content-wrapper > .page-heading,
					.cms-content > .hide-content,
					.content-buttons {
						display: none;
					}
				}
			}
		}
	}

	& > .content-wrapper.simple-page {
		display: block;
		max-width: 1244px;
		width: 100%;
		padding: 50px 0;
		border-radius: 5px;

		& > .content-wrapper {
			// max-width: 836px;
			padding: 0 22px;
			margin: 0 auto 0 0;
		}

		img {
			display: inline-block;
			max-width: 48%;
			margin: 0 1% 10px 0;
			border: 1px solid black;

			&.main {
				width: 100%;
				max-width: 100%;
				height: auto;
				margin: 0 0 35px 0;
			}
		}

		h1 {
			max-width: 100%;
			font-size: var(--mediumsize);
			display: block;
			padding: 0 0 25px 0;
			margin: 0 0 25px 0;
			border-bottom: 1px solid black;
		}

		.cms-content {
			font-size: var(--microsize);
			line-height: 1.5;
		}
	}
}

#contact {
	background-color: #f2f2f2;

	.breadcrumbs-container {
		display: flex;
		height: 34px;
		align-items: center;
		width: 100%;
		background-color: white;
		background-color: white;

		#breadcrumbs {
			max-width: 1230px;
			margin: 0 auto;
			padding: 0 15px;
		}
	}

	.header-banner {
		display: block;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 16vw;
		min-height: 233px;
		max-height: 280px;
	}

	.cms-content {
		display: flex;
		justify-content: space-between;
		max-width: 1200px;
		padding-bottom: 110px;
		gap: 51px 20px;
	}

	.form-area {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding: 45px 22px 55px;
		width: 100%;
		max-width: 792px;
		box-shadow: 0px 10px 30px #0000001a;
		background: white;
		margin-top: -43px;
		border-radius: 5px;

		.form-container {
			width: 100%;
		}

		.form-container form {
			display: flex;
			margin: auto;
			flex-flow: row wrap;
			max-width: 590px;

			.form-title {
				display: block;
				width: 100%;
				text-align: center;
				font-weight: 400;
				font-size: 20px;
				margin: 0 0 35px 0;
			}

			select {
				width: 100%;
				padding: 14.5px 35px 14.5px 13px;
				border-radius: 5px;
				background: none;
				border: 1px solid black;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				color: #040707;
			}

			.inputs {
				width: 100%;
			}

			input[type='text'],
			input[type='email'] {
				width: 100%;
			}

			textarea {
				border: none !important;
				background-color: #f2f2f2 !important;
			}

			label {
				display: block;
				width: 100%;

				& > span {
					display: block;
					font-weight: 700;
					margin: 12px 0 15px;
				}

				.input-label {
					margin: 0;
					color: #000000;
				}

				.attach-top {
					margin-top: 27px;
				}
			}

			input[type='file'] {
				display: none;
			}

			.uploadfile-button {
				width: 100%;
				padding: 11px 13px;
				border-radius: 5px;
				margin-bottom: 10px;
				border-radius: 5px;
				border: 1px solid #000000;
				text-align: left;
				font-size: 14px;
				line-height: 1;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.black-arrow-button {
				min-width: 245px;
				text-align: left;
				padding-left: 30px;
				margin-left: auto;
				margin-top: 7px;
			}

			.grey-comment {
				color: #7b7b7b;
			}

			.capcha-align {
				margin-left: auto;
				text-align: end;
			}

			.captcha-wrapper iframe {
				transform-origin: right;
			}

			.form-row {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 17px 0 40px;
				gap: 20px 10px;

				p {
					margin: 0;
				}
			}
		}
	}

	.info-area {
		max-width: 290px;

		.content-wrapper {
			padding: 10px 0 32px 0;

			& > p {
				display: block;
				width: 100%;
				text-align: center;
			}

			h2 {
				font-size: 16px;
				color: #717171;
				margin: 75px 0 15px 0;
				text-align: left;
			}

			.contact-item {
				width: 100%;
				display: flex;
				margin-bottom: 13px;

				span {
					margin-right: 24px;
				}

				p {
					margin: 0;
				}

				&.first {
					border-right: 1px solid #ababab;
				}

				a {
					font-weight: 700;
					color: black;
				}
			}
		}
	}
}

@media (max-width: 820px) {
	#home-slider {
		margin-top: -30px;
	}

	#home-slider .content-wrapper {
		max-width: 660px;
		width: 100%;
	}

	.raffle-carousel .owl-item {
		padding: 30px;
	}

	.raffle-carousel-main,
	.raffle-registration {
		box-shadow: 2px 7px 29px #434b5759;
	}

	.raffle-carousel .owl-nav button {
		top: 195px;
	}

	.raffle-carousel .owl-nav button.owl-prev {
		left: 7%;
	}

	.raffle-carousel .owl-nav button.owl-next {
		right: 7%;
	}

	.raffle-carousel .owl-dots {
		margin-top: -10px;
	}

	#anchor-links a,
	#scroll-to-top {
		margin: 0 10px;
	}

	#anchor-links a:last-child {
		margin-right: 0;
	}

	#scroll-to-top {
		margin-left: 0;
	}
}

@media (max-width: 600px) {
	.slider-go-to-prod a {
		right: 20px;
		bottom: 23px;
	}

	#burger-btn {
		background-color: transparent;
		margin-right: 10px;
	}

	#burger-btn img {
		width: 24px;
		height: 23px;
	}

	.go-to-prod {
		display: block;
	}

	#product-title,
	#product-price,
	#heritage,
	#heritage h2 {
		text-align: left;
	}

	#product-title,
	#product-price {
		padding-left: 5px;
	}

	.cms-content .icons-wrapper {
		flex-direction: column;
		align-items: flex-start;
		margin: 0;
	}

	.cms-content .icons-wrapper .icon-col {
		width: 100%;
		max-width: 200px;
		margin-bottom: 35px;
	}

	.cms-content .icons-wrapper .icon-col:last-child {
		margin-bottom: 0;
	}
}

@media (max-width: 480px) {
	.raffle-prod-details {
		margin-left: 18px;
	}

	.img-container-double {
		display: block;
	}

	.img-container-double > div {
		width: 100%;
		max-width: 300px;
		margin: 0 auto 20px;
	}

	.img-gallery {
		margin-left: -15px;
		margin-right: -15px;
	}

	#raffle-ends,
	#raffle-starts {
		padding-left: 0;
	}

	#raffle-ends span,
	#raffle-starts span {
		margin-right: 10px;
	}

	.popup .content-wrapper {
		padding: 0;
	}

	.cms-content .images-grid {
		flex-direction: column;
	}

	.cms-content .images-grid img:nth-child(n) {
		width: 100%;
		margin-bottom: 35px;
	}

	.cms-content .rounded {
		height: 236px;
		object-fit: cover;
	}

	.cms-content .images-grid img:nth-child(3) {
		height: 182px;
		margin-bottom: 0;
	}

	.cms-content .orange-btn {
		margin: 25px auto 0;
	}
}

@media (max-width: 420px) {
	.birth-date-options .select-day,
	.birth-date-options .select-year {
		width: 31%;
	}

	.birth-date-options .select-month {
		width: 31%;
	}
}

@media (max-width: 600px), (max-height: 500px) {
	#close-modal {
		top: 20px;
		right: 15px;
		padding-right: 0;
	}

	#close-modal svg path {
		stroke: #000;
		fill: #000;
	}

	#main-menu {
		text-align: left;
		background: #e9edf3;
		border-bottom: none;
		max-width: none;
	}

	#main-menu li {
		padding-left: 46px;
	}

	#user-options {
		max-width: none;
		padding-left: 0;
		border-bottom: none;
	}

	#external li,
	#user-options li,
	#lang {
		padding-left: 15px;
	}

	#main-menu li {
		padding-bottom: 11px;
	}

	#user-options li {
		padding-bottom: 8px;
		padding-top: 11px;
	}

	#external li {
		padding-top: 17px !important;
		padding-bottom: 17px !important;
	}

	#lang {
		padding-top: 8px;
		padding-bottom: 16px;
	}

	#menu-modal ul#external {
		background: #e9edf3;
	}

	#main-menu li:last-child {
		border-bottom: none;
		padding-bottom: 12px;
	}

	#user-options svg path {
		stroke: #040707;
	}

	#user-options svg path#Path_224 {
		fill: #040707;
	}

	#product,
	#heritage,
	#raffle-ends,
	#raffle-starts,
	.faq-content {
		font-size: 16px;
	}
}

.search-screen {
	position: fixed;
	width: 100%;
	height: calc(100vh - 60px);
	z-index: 1000;
	background: rgba(255, 255, 255, 0.97);
	overflow: auto;
	scrollbar-width: none;
	top: 60px;

	@media (max-width: 767px) {
		top: 0;
		height: 100%;
		z-index: 9999;
		background-color: #fff;

		.content-wrapper {
			padding: 0;
		}

		.exit-search {
			max-width: unset;
			width: 100%;
			padding: 0 33px;
			top: 20px;
		}
	}

	&::-webkit-scrollbar {
		width: 0;
	}

	#search-close {
		cursor: pointer;
		pointer-events: auto;
		position: relative;
		z-index: 9;
		width: fit-content;
		margin-left: auto;
		display: block;

		@media (max-width: 767px) {
			svg {
				width: 15px;
				height: 15px;
			}
		}

		@media (max-width: 480px) {
			svg {
				width: 13px;
				height: 13px;

				line {
					stroke: #717171;
				}
			}
		}
	}

	.results-section {
		padding: 48px 0;
		max-width: 782px;
		margin: 0 auto;
		display: flex;

		@media (max-width: 767px) {
			flex-direction: column;
			gap: 20px 0;
			padding: 36px 22px;

			.content-wrapper {
				padding: 0;
			}
		}

		.keyword-title {
			font-weight: 700;
			font-size: 16px;
			margin: 0 0 22px 0;
			display: block;
		}

		.keyword-item {
			color: #ababab;
			font-size: 16px;
			margin: 0 0 22px 0;
			display: block;
			width: 100%;
			text-align: left;
		}

		.keyword-results {
			width: 50%;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		.product-box {
			display: inline-flex;
			flex-flow: column nowrap;
			width: auto;
			padding: 0px;
			max-width: 180px;

			& > svg {
				top: 15px;
				right: 35px;
			}

			.prod-details {
				background: unset;
			}

			.prod-img {
				position: relative;

				a {
					display: block;
					position: relative;

					img {
						width: 100%;
						height: 100%;

						&.on-hover {
							display: block;
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
						}
					}
				}
			}

			@media (max-width: 575px) {
				padding: 0px 5px;
			}
		}
	}

	.quote-container {
		border-bottom: 1px solid grey;
		padding-top: 2%;
	}

	.quote {
		font-style: italic;
		font-size: 1.2em;
	}

	.quote-author {
		color: gray;
		font-size: 0.9em;
	}

	.highlight {
		font-weight: bold;
		color: black;
	}

	.searchbar {
		width: 100%;
		margin: 38px auto 0;
		max-width: 790px;
		width: 80%;
		position: relative;

		@media (max-width: 767px) {
			margin: 0;
			padding: 13px 22px 12px;
			width: 100%;
			box-shadow: 0 10px 30px #00000029;
			position: unset;
		}

		@media (max-width: 767px) {
			margin-bottom: 28px;
		}

		&::before {
			content: '';
			position: absolute;
			background: url(../images/search-icon.svg) no-repeat center;
			left: 0;
			bottom: 13px;
			width: 22px;
			height: 22px;

			@media (max-width: 767px) {
				content: none;
			}
		}

		.searchbar-input {
			width: 100%;
			padding: 1.5% 1.5% 9px 43px;
			background: none;
			border-bottom: 1px solid black;
			border-radius: 0;
			height: unset;

			@media (max-width: 767px) {
				height: 30px;
				border: none;
				background-color: #f2f2f2;
				border-radius: 5px;
				padding: 1.5% 30px 1.5% 10px;
				border: none !important;
			}

			&::-webkit-input-placeholder,
			&::placeholder {
				opacity: 1;
				font-size: 24px;
				color: #050507;

				@media (max-width: 767px) {
					font-size: 18px;
				}

				@media (max-width: 480px) {
					font-size: 16px;
				}
			}

			&:focus {
				border: none;
				border-bottom: 1px solid black;
			}
		}
	}

	.before-search-section {
		.products-results {
			display: flex;
			width: 50%;
			justify-content: space-between;

			.product-box {
				.prod-details {
					.flex-col {
						flex-flow: column nowrap;
						gap: 0;

						h4 {
							font-size: 14px;
							max-width: unset;
							margin: 0 0 5px 0;
						}

						.prod-price {
							text-align: left;
							line-height: 1;
							gap: 0;

							span {
								display: none;
							}
						}
					}
				}

				.quick-buy,
				.add-wish {
					display: none !important;
				}
			}
		}
	}

	.after-search-section {
		display: none;
		flex-wrap: wrap;

		.go-to-search-page {
			width: 100%;
			text-align: center;
			padding: 40px 22px;

			@media (max-width: 767px) {
				padding: 20px 0;
			}

			button {
				background: black;
				color: white;
				margin-left: auto;
				padding: 15px;
				position: relative;
				z-index: 2005;
			}
		}

		.products-results {
			display: flex;
			width: 50%;
			flex-flow: column nowrap;
			justify-content: space-between;
			gap: 10px;

			@media (max-width: 767px) {
				width: 100%;
			}

			.product-item {
				display: block;
				width: 100%;
				text-align: left;
				width: 100%;

				.product-box {
					flex-flow: row nowrap;
					max-width: unset;
					width: 100%;
					padding: 20px;
					background: white;
					box-shadow: 0px 10px 30px rgb(0 0 0 / 20%);

					.prod-img {
						width: 70px;
					}

					.prod-details {
						padding: 0 0 0 22px;
						width: calc(100% - 70px);

						.flex-col {
							height: 100%;
							flex-flow: column nowrap;
							justify-content: space-between;
							gap: 0;

							h4 {
								font-size: 14px;
								max-width: unset;
								margin: 0 0 5px 0;
							}

							.prod-price {
								text-align: right;
								line-height: 1;
								gap: 0;
							}
						}
					}

					.quick-buy,
					.add-wish {
						display: none !important;
					}
				}
			}
		}
	}

	&.search-clicked {
		.before-search-section {
			display: none;
		}

		.after-search-section {
			display: flex;

			@media (max-width: 767px) {
				flex-direction: column;
				gap: 20px 0;
			}

			.keyword-item {
				color: #000;
			}
		}
	}
}

// -----------------------------------------------------------------------------
// CHECKOUT RELATED STYLES -----------------------------------------------------

/* Checkout Header */
#header-checkout {
	header {
		box-shadow: 0px 10px 30px #00000029;
		position: relative;

		.content-wrapper {
			justify-content: space-between;
		}

		#checkout-logo {
			max-width: 218px;

			@media (max-width: 575px) {
				max-width: 160px;
			}
		}
	}

	.steps-container {
		margin: 50px auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: 575px) {
			margin: 30px auto 30px;
		}

		.back {
			cursor: pointer;
			align-self: center;
			font-size: 16px;

			@media (max-width: 768px) {
				display: none;
			}
		}

		.back::before {
			content: '';
			display: block;
			background: url('../images/arrow-right.svg') no-repeat;
			transform: rotate(180deg);
			width: 10px;
			height: 21px;
			float: left;
			margin: -4px 15px 0px 0px;
			cursor: pointer;
		}

		.checkout-steps {
			display: flex;
			justify-content: center;
			margin: auto;
			width: 100%;
			padding: 0;

			@media (max-width: 768px) {
				padding: 0;
			}

			.checkout-step {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 0px;
				position: relative;
				max-width: 250px;
				width: 100%;
				box-sizing: border-box;

				.step-numb {
					width: 40px;
					height: 40px;
					font-weight: 700;
					border-radius: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 2;

					&.clickable {
						cursor: pointer;
					}

					&.disabled {
						cursor: not-allowed;
					}

					&.completed {
						border: 1px solid #000;
					}
				}

				.step-description {
					text-align: center;
					color: #b5bac3;
				}
			}

			.step-line {
				height: 2px;
				width: calc(100% - 35px);
				background-color: #b5bac3;
				position: absolute;
				left: calc(50% + 18px);
				z-index: 1;

				&.completed {
					background-color: #000;
				}
			}
		}
	}
}

/* Checkout Header Ends */

/* CHECKOUT STEP 1*/
.checkout-step1-page {
	.breadcrumbs-container {
		display: none;
	}

	@media (max-width: 640px) {
		.content-wrapper {
			padding: 0px;
		}
	}

	.already-loggedin-container {
		max-width: 575px;
		margin: 10px auto 0;

		h2 {
			font-size: 20px;

			@media (max-width: 575px) {
				font-size: 16px;
			}
		}

		.actions {
			margin-top: 35px;
			display: flex;
			gap: 10px;

			button {
				max-width: 50%;
				width: 100%;
			}

			@media (max-width: 640px) {
				display: block;

				button {
					max-width: 100%;
					width: 100%;
					margin-bottom: 20px;
				}
			}
		}
	}
}

/* CHECKOUT STEP 1 ENDS*/

/* CHECKOUT STEP 2 STARTS ---------------------------- */
.checkout-step2-page {
	.wrapper {
		display: flex;
		flex-direction: column;
	}

	.billing-information-container {
		display: flex;
		flex-direction: column;

		@media (max-width: 575px) {
			align-items: center;
		}
	}

	.billing-info-title {
		flex: 1;
		font-size: 20px;
		font-family: 'Ubuntu', sans-serif;
		font-weight: 500;
		width: 100%;
		text-align: left;
		margin: 0;

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	.select-receipt-invoice {
		padding-left: 15px;
		margin-top: 25px;
		margin-bottom: 30px;
		display: flex;
		gap: 40px;

		@media (max-width: 980px) {
			padding-left: 0px;
			margin-bottom: 25px;
		}

		label {
			align-items: center;
			color: #000;
			cursor: pointer;
			display: flex;
			gap: 8px;

			input[type='radio'] {
				display: block;
				height: 20px;
				width: 20px;
				cursor: pointer;
				accent-color: #000;

				&:checked {
					background-color: black;
					border-color: black;
				}
			}
		}
	}

	.step2-container {
		display: grid;
		grid-template-columns: 1fr 450px;
		gap: 200px;

		@media (max-width: 1080px) {
			gap: 50px;
		}

		@media (max-width: 980px) {
			grid-template-columns: 1fr;
		}

		.addresses-container {
			display: flex;
			margin-bottom: 30px;
			gap: 17px;

			@media (max-width: 980px) {
				max-width: 100%;
			}

			#addressSelect {
				width: 100%;
			}

			.address-item {
				border: 2px solid #444;
				padding: 0 30px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-bottom: 0px;
				min-height: 80px;
				cursor: pointer;
				gap: 7px;
				transition: 0.5s;

				&:not(.active):hover {
					opacity: 0.5;
				}

				h4 {
					margin: 0px;
					text-align: left;
				}

				span {
					font-size: 14px;
				}
			}

			.address-item.active {
				border: 2px solid #000 !important;
			}

			.address-item.create-new {
				width: 100%;
				background-color: #f2f4f8;
				color: #6e7486;
				border: 2px solid #f2f4f8;
				height: 48px !important;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				h4 {
					width: 150px;
				}

				img {
					background-color: #000;
					padding: 7px;
					width: 28px;
					border-radius: 50px;
				}
			}
		}

		.custom-checkbox {
			margin-top: 20px;
			padding-top: 5px;
			font-size: 14px;

			@media (max-width: 575px) {
				padding-top: 0px;
			}
		}

		.billing-info-form {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-auto-rows: auto;
			column-gap: 17px;
			row-gap: 10px;

			@media (max-width: 575px) {
				display: flex;
				flex-direction: column;
				gap: 12px;
			}

			.full {
				grid-column: span 2;
			}

			input::placeholder {
				opacity: 1;
				color: #6e7486;
			}

			.input-error {
				font-size: 11px;
				color: red;
				font-weight: 500;
				margin: 3px 0;
			}

			.input-help {
				font-size: 11px;
				color: #6e7486;
				font-weight: 500;
				margin: 3px 0;
			}

			.react-tel-input .form-control {
				position: relative;
				font-size: 14px;
				letter-spacing: 0.01rem;
				margin-top: 0 !important;
				margin-bottom: 0 !important;
				padding-left: 48px;
				margin-left: 0;
				background: #f2f2f2;
				border: 0px !important;
				border-radius: 5px;
				line-height: 25px;
				height: 48px !important;
				width: 100% !important;
				outline: none;
			}

			.react-tel-input input:focus {
				border: 1px solid #0154ff !important;
			}
		}

		.order-synopsis {
			@media (max-width: 980px) {
				order: 2;
				margin-top: 0px;
			}
		}

		.delivery-information-container {
			margin: 50px 0 35px;

			@media (max-width: 980px) {
				order: 0;
				margin: 70px 0 65px;
				max-width: 100%;
			}

			@media (max-width: 575px) {
				margin: 30px 0 30px;
			}

			.delivery-info-title {
				font-size: 20px;
				font-family: 'Ubuntu', sans-serif;
				font-weight: 500;
				width: 100%;
				text-align: left;
				margin: 0 0 30px;

				@media (max-width: 575px) {
					text-align: center;
				}
			}

			p {
				font-weight: 400;
				color: #6e7486;

				@media (max-width: 575px) {
					text-align: center;
				}
			}

			.shipping-info-form {
				margin-top: 45px;
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-auto-rows: auto;
				column-gap: 17px;
				row-gap: 10px;

				@media (max-width: 575px) {
					display: flex;
					flex-direction: column;
					gap: 12px;
				}

				.full {
					grid-column: span 2;
				}

				input::placeholder {
					opacity: 1;
					color: #6e7486;
				}

				.input-error {
					font-size: 11px;
					color: red;
					font-weight: 500;
					margin: 3px 0;
					box-shadow: 0px;
				}

				.react-tel-input .form-control {
					position: relative;
					font-size: 14px;
					letter-spacing: 0.01rem;
					margin-top: 0 !important;
					margin-bottom: 0 !important;
					padding-left: 48px;
					margin-left: 0;
					background: #f2f2f2;
					border: 0px !important;
					border-radius: 5px;
					line-height: 25px;
					height: 48px !important;
					width: 100% !important;
					outline: none;
				}

				.react-tel-input input:focus {
					border: 1px solid #0154ff !important;
				}

				.custom-checkbox {
					margin-top: 100px;
					padding-top: 5px;
					grid-column-start: 1;
					grid-column-end: 3;
					font-size: 14px;

					@media (max-width: 980px) {
						order: -1;
						margin-top: 0px;
					}

					@media (max-width: 575px) {
						padding-top: 0px;
					}
				}
			}

			textarea {
				margin-top: 15px;
			}

			button {
				margin-top: 16px;
				width: 100%;
			}
		}

		.next-step {
			width: 100%;
			margin-bottom: 50px;

			button {
				width: 375px;

				@media (max-width: 575px) {
					width: 100%;
				}
			}

			&.desktop-view {
				@media (max-width: 980px) {
					display: none;
				}
			}

			&.mobile-view {
				margin-top: 50px;

				@media (min-width: 981px) {
					display: none;
				}
			}
		}
	}

	// Order synopsis
	#order-synopsis-checkout {
		margin-top: -100px;

		@media (max-width: 980px) {
			margin-top: 0px;
		}

		.coupons-container {
			margin-bottom: 25px;
		}
	}

	// Order synopsis
}

/* CHECKOUT STEP 2 ENDS ---------------------------- */

/* Checkout Step 3 STARTS -------------------------- */
.checkout-step3-page {
	.step3-container {
		display: grid;
		grid-template-columns: 1fr 450px;
		grid-template-rows: masonry;
		gap: 18px;

		@media (max-width: 980px) {
			grid-template-columns: 1fr;
		}

		@media (max-width: 480px) {
			display: block;
		}

		.left {
			max-width: 570px;
			width: 100%;
			margin-bottom: 75px;

			@media (max-width: 980px) {
				margin: auto;
			}
		}

		.shipping-options {
			margin-bottom: 25px;

			.shipping-options-title {
				width: 100%;
				font-size: 20px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				text-align: left;
				margin: 0;

				@media (max-width: 980px) {
					text-align: center;
				}
			}

			.select-shipping-type {
				// padding-left: 15px;
				margin-top: 25px;
				margin-bottom: 30px;
				display: flex;
				flex-wrap: wrap;
				white-space: nowrap;
				gap: 25px;

				@media (max-width: 980px) {
					padding-left: 0px;
					margin-bottom: 25px;
					justify-content: center;
				}

				@media (max-width: 550px) {
					flex-direction: column;
					align-items: center;
					gap: 25px;
				}

				// label {
				// 	align-items: center;
				// 	color: #000;
				// 	cursor: pointer;
				// 	display: flex;
				// 	gap: 10px;
				// 	font-weight: 600;

				// 	input[type='radio'] {
				// 		display: block;
				// 		height: 20px;
				// 		width: 20px;
				// 		cursor: pointer;
				// 		accent-color: #000;

				// 		&:checked {
				// 			background-color: black;
				// 			border-color: black;
				// 		}

				// 		@media (max-width: 980px) {
				// 			margin-left: -1px;
				// 		}
				// 	}
				// }

				.option {
					height: 35px;
					border: 1px solid #000;
					padding: 12px;
					display: flex;
					align-items: center;
					gap: 12px;
					font-size: 15px;
					font-weight: 600;
					line-height: 30px;
					box-shadow: 5px 5px 5px 0px rgba(201, 193, 193, 0.75);
					-webkit-box-shadow: 5px 5px 5px 0px rgba(201, 193, 193, 0.75);
					-moz-box-shadow: 5px 5px 5px 0px rgba(201, 193, 193, 0.75);
					cursor: pointer;
					transition: 0.5s;

					&.box-now {
						border: 2px solid lightgreen;
						color: lightgreen;
						font-weight: 900;
					}

					&:hover {
						opacity: 0.5;
					}

					&.active {
						background-color: #000;
						color: #fff;

						&.box-now {
							background-color: lightgreen;
							color: #fff;
							font-weight: 900;
						}
					}
				}
			}

			.shipping-options-container {
				display: flex;
				flex-direction: column;
				gap: 8px;

				.shipping-option {
					padding: 9px;
					border: 1px solid #e2e5ea;
					display: flex;
					align-items: center;
					cursor: pointer;
				}

				.map-outer {
					height: 70vh;
				}

				.shipping-option.courier {
					gap: 25px;
					height: 45px;

					@media (max-width: 450px) {
						gap: 12px;
					}

					.custom-checkbox .checkmark {
						top: 0px;

						&:after {
							border-width: 0 1px 1px 0;
						}
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 25px;

						@media (max-width: 450px) {
							gap: 12px;
						}

						.option-icon {
							width: 55px;
							text-align: center;
						}

						img {
							max-width: 55px;
							max-height: 28px;
							margin: auto;

							@media (max-width: 390px) {
								max-width: 35px;
							}
						}

						h4 {
							margin: 0px;
						}

						span {
							font-size: 15px;

							@media (max-width: 375px) {
								font-size: 14px;
							}
						}
					}
				}

				.stores-filtering {
					width: 300px;
					margin-bottom: 20px;

					@media (max-width: 980px) {
						width: 100%;
					}

					@media (max-width: 550px) {
						width: 100%;
					}
				}

				.stores-options {
					max-height: 400px;
					overflow-y: auto;
					border: 1px solid #f3f3f3;
				}

				.shipping-option.store {
					height: 90px;
					gap: 50px;
					padding: 9px 20px;

					@media (max-width: 450px) {
						gap: 30px;
					}

					input[type='radio'] {
						display: block;
						-ms-transform: scale(1.5);
						/* IE 9 */
						-webkit-transform: scale(1.5);
						/* Chrome, Safari, Opera */
						transform: scale(1.5);
						cursor: pointer;
						accent-color: #000;
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 12px;

						h4 {
							font-size: 14px;
							margin: 0px;
							text-align: left;

							@media (max-width: 515px) {
								font-size: 12px;
							}
						}

						span {
							font-size: 12px;
							text-align: right;

							@media (max-width: 515px) {
								font-size: 9px;
							}
						}
					}
				}

				.boxnow-selected-option {
					margin: 25px 0;
					font-size: 24px;
					color: lightgreen;
					font-weight: 500;
					max-width: 300px;
					border: 1px solid lightgreen;
					padding: 12px;
					border-radius: 8px;
				}
			}
		}

		.payment-options {
			margin-bottom: 25px;

			.payment-options-title {
				width: 100%;
				font-size: 20px;
				font-family: 'Roboto Condensed';
				font-weight: 600;
				text-align: left;
				margin: 0 0 35px;

				@media (max-width: 980px) {
					text-align: center;
				}
			}

			.payment-options-container {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.payment-option {
					height: 90px;
					width: 100%;
					padding: 0 20px;
					display: flex;
					align-items: center;
					gap: 50px;
					border: 1px solid #e2e5ea;
					cursor: pointer;

					input[type='radio'] {
						display: block;
						-ms-transform: scale(1.5);
						/* IE 9 */
						-webkit-transform: scale(1.5);
						/* Chrome, Safari, Opera */
						transform: scale(1.5);
						cursor: pointer;
						accent-color: #000;
					}

					.title {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 50px;

						@media (max-width: 450px) {
							gap: 12px;
						}

						// img {
						//     max-width: 35px;
						// }

						span {
							font-size: 14px;

							@media (max-width: 350px) {
								font-size: 13px;
							}
						}
					}

					&.selected {
						background-color: #f2f4f8;
					}
				}

				.sub-options {
					margin-top: -12px;

					.sub-option {
						height: 50px;
						background-color: #f8fafd;
						display: flex;
						align-items: center;
						border-top: 1px solid #f2f4f8;
						padding: 16px 20px;

						.name {
							width: 160px;
						}

						img {
							margin-right: 0;
							margin-left: auto;
							cursor: pointer;
						}

						.installments-info {
							margin-right: 20px;
						}

						#installments-select {
							border-radius: 4px;
							border: 1px solid #000;
							background-color: #fff;
							height: 30px;
						}

						@media (max-width: 480px) {
							font-size: 12px;
						}
					}
				}
			}
		}

		.gift-checkbox-container {
			padding: 9px;
			border: 1px solid #e2e5ea;
			display: flex;
			align-items: center;
			gap: 50px;
			height: 45px;
			margin-bottom: 35px;

			@media (max-width: 450px) {
				gap: 12px;
			}

			.custom-checkbox .checkmark {
				top: 0px;

				&:after {
					border-width: 0 1px 1px 0;
				}
			}

			.title {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 15px;

				@media (max-width: 450px) {
					gap: 12px;
				}

				img {
					max-width: 55px;
					max-height: 28px;
				}

				span {
					font-size: 15px;

					@media (max-width: 350px) {
						font-size: 13px;
					}
				}
			}
		}

		.privacy-newsletter-checkbox {
			margin-bottom: 30px;

			.custom-checkbox {
				@media (max-width: 450px) {
					font-size: 14px;
				}

				.checkmark {
					top: 0px;

					@media (max-width: 500px) {
						top: 1px;
					}
				}
			}
		}

		.next-step {
			max-width: 375px;

			@media (max-width: 980px) {
				max-width: 100%;
			}

			button {
				width: 100%;
			}

			&.desktop-view {
				@media (max-width: 980px) {
					display: none;
				}
			}

			&.mobile-view {
				margin-top: 50px;

				@media (min-width: 981px) {
					display: none;
				}
			}
		}
	}
}

// CHECKOUT STEP 3 ENDS ----------------------------------

.cart-box .order-synopsis .discount {
	position: relative;

	&:hover {
		cursor: pointer;
	}

	.details-indicator {
		position: absolute;
		top: 1px;
		left: 65px;
		color: #fff;
		font-weight: 900;
		width: 18px;
		height: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 19px;
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}
	}
}

.modal-dialog-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: baseline;
	overflow: auto;

	.modal-dialog {
		z-index: 10000;
		position: relative;
		animation: fadeIn 0.5s;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		.close-modal-btn {
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;

			@media (max-width: 768px) {
				top: 15px;
				right: 15px;
			}
		}
	}
}

.discounts-poupup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}

	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.discounts-popup {
		max-width: 575px;
		width: 100%;
		margin: 20px 0;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.discounts-popup-container {
			padding: 40px 20px 40px;
			max-width: 450px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}

			.header {
				margin-bottom: 45px;

				.title {
					font-size: 24px;
					font-family: 'Roboto Condensed';
					font-weight: 600;
					text-align: center;

					@media (max-width: 420px) {
						margin-left: 1px;
					}

					@media (max-width: 360px) {
						font-size: 20px;
					}

					span {
						background: transparent linear-gradient(290deg, #6b19f9 0%, #2253f5 100%) 0% 0% no-repeat padding-box;
						-webkit-background-clip: text;
						background-clip: text;
						-webkit-text-fill-color: transparent;
						font-size: 34px;
					}
				}
			}

			.body {
				h4 {
					font-size: 16px;
					font-family: 'Roboto Condensed';
					margin: 0;
					text-align: left;
				}

				.discounts-features {
					margin: 20px 0 30px;
					display: flex;
					flex-direction: column;
					gap: 14px;

					li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 20px;
						margin-bottom: 20px;

						h5 {
							font-size: 17px;
							margin: 0;
							text-align: left;
							font-weight: 400;
						}

						span {
							font-size: 17px;
							color: #28c790;
						}
					}
				}

				.email {
					border: 1px solid #0154ff;
				}

				input::placeholder {
					opacity: 1;
					color: #4d4d4d;
				}

				.checkboxes-container {
					margin: 20px 0;

					.custom-checkbox {
						font-size: 12px !important;
					}
				}
			}

			.footer {
				width: 100%;

				button {
					width: 100% !important;
					max-width: 100% !important;
				}
			}
		}
	}
}

/* CUSTOM CHECKBOX */
.custom-checkbox {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 17px;
	cursor: pointer;
	font-size: 16px;
	color: #6e7486;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 4px;
		left: 0;
		height: 20px;
		width: 20px;
		border: 1px solid #b5bac3;
		border-radius: 3px;
	}

	/* When the checkbox is checked, add a blue background */
	input:checked ~ .checkmark {
		border: 1px solid #b5bac3;
		background-color: #fff;
		border-radius: 3px;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.checkmark:after {
		left: 6px;
		top: 2px;
		width: 6px;
		height: 10px;
		border: solid #000;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

/* CUSTOM CHECKBOX */

.search-input-container {
	position: relative;
	margin-bottom: 15px;

	input {
		width: 100%;
		height: 40px;
		border: 0px;
		border-bottom: 2px solid #000;
		background-color: transparent;
		color: #000;
		border-radius: 0px;
		font-size: 14px;
		outline: none;

		&::placeholder {
			opacity: 1 !important;
			color: #7b7b7b;
			font-size: 14px;
		}
	}

	.search-icon {
		position: absolute;
		top: 12px;
		right: 12px;

		svg {
			width: 24px;
			height: 24px;
		}
	}
}

#boxnowmap {
	z-index: 100;
}

.boxnow-lockers-poupup-modal {
	.modal-dialog-overlay {
		align-items: center;
	}

	@media (max-width: 768px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: auto;
		}
	}

	@media (max-width: 575px) {
		.modal-dialog-overlay {
			position: fixed;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 10000;
			background: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: flex-end;
			overflow: auto;
		}
	}

	.boxnow-lockers-popup {
		overflow-y: auto;
		max-height: 100vh;
		max-width: 1200px;
		width: 100%;
		background-color: #fff;

		@media (max-width: 575px) {
			margin: 0;
			border-radius: 20px 20px 0 0;
		}

		.boxnow-lockers-popup-container {
			padding: 40px 20px 40px;
			margin: auto;

			@media (max-width: 420px) {
				padding: 40px 20px 40px;
			}
		}

		.locker-options-container {
			margin-top: 20px;
			display: grid;
			grid-template-columns: 1fr;
			gap: 10px;

			.locker-option {
				padding: 10px;
				display: flex;
				gap: 15px;
				justify-content: start;
				box-shadow: 1px 0px 5px 1px rgba(150, 148, 148, 0.75);
				-webkit-box-shadow: 1px 0px 5px 1px rgba(150, 148, 148, 0.75);
				-moz-box-shadow: 1px 0px 5px 1px rgba(150, 148, 148, 0.75);
				cursor: pointer;
				transition: 0.5s all;

				&:hover {
					opacity: 0.5;
					transform: scale(0.97);
				}

				h4 {
					text-align: left;
					font-size: 18px;
					font-family: 'Roboto Condensed';
					margin: 0;
				}

				p {
					margin: 0;
				}
			}
		}
	}
}

// Swiper Settings (Taken from Metaixmio)

.swiper-wrapper,
.swiper-slide {
	cursor: grab;
}

.swiper {
	// max-width: ''; // ** Get this number from calculating the total width of contents. Pass this ONLY to each 'section > .swiper'

	.swiper-pagination-bullets {
		display: inline-flex;
		justify-content: center;
		bottom: 0; // reset Swiper defaults
	}

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		margin: 0 8px;
		background-color: #fff;
		opacity: 1; // reset Swiper defaults

		@media (max-width: 480px) {
			margin: 0 4px;
		}

		&-active {
			background-color: black;
			width: 24px;
			border-radius: 5px;
			transition: all 0.3s;
		}
	}

	.swiper-button {
		&-prev,
		&-next {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			margin: 0; // reset Swiper defaults
			top: 200px;
			border: 1px solid #fff;
			background-color: #fff;
			// top/height/border/background: are for letting button be on top of entrire-slides
			// change background & border colors depending on parent background

			&::after {
				// reseting Swiper defaults
				font-size: 12px;
				color: #333;
			}
		}

		&-prev {
			left: 0;
		}

		&-next {
			right: 0;
		}
	}

	.swiper-slide {
		padding: 0 20px; //50% of button width
	}
}

#giftcard-page {
	.giftcard-content-wrapper {
		max-width: 600px;
	}

	.giftcard-header-banner {
		background-image: url('../images/giftcard-hero.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 65vh;

		.giftcard-container {
			height: 100%;
			width: 100%;
			position: relative;
			padding: 35px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@media (max-width: 640px) {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 20px 0;
			}
		}

		@media (max-width: 900px) {
			background-size: contain;
			height: 50vh;
		}

		@media (max-width: 768px) {
			background-size: contain;
			height: 50vh;
		}

		@media (max-width: 620px) {
			background-size: contain;
			height: 35vh;
		}

		@media (max-width: 480px) {
			background-size: contain;
			height: 30vh;

			.giftcard-container {
				padding: 15px 0;
			}
		}
	}

	.input-container {
		display: flex;
		flex-direction: column;
		// gap: 12px;
		margin-bottom: 25px;

		input {
			border-radius: 0px;
			background-color: #f2f4f8;
			outline: none;
			border: none;
			font-size: 14px;
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
		}

		input::placeholder {
			opacity: 1;
			color: #666;
			font-size: 14px;
		}

		.login-password-input {
			position: relative;

			span {
				position: absolute;
				width: 35px;
				right: 10px;
				top: 10px;
			}
		}
	}

	.giftcard-steps {
		max-width: 600px;
		position: relative;
		padding: 25px 0px 75px;

		@media (max-width: 615px) {
			padding: 45px 12px 75px;
		}

		.prev-step {
			left: -15vw;
			position: absolute;
			display: flex;
			align-items: center;
			gap: 20px;
			cursor: pointer;

			@media (max-width: 920px) {
				left: -100px;
			}

			@media (max-width: 820px) {
				left: -50px;
			}

			@media (max-width: 720px) {
				left: -20px;
			}

			@media (max-width: 650px) {
				left: 10px;
				gap: 10px;
			}

			@media (max-width: 420px) {
				top: 7px;
			}

			img {
				transform: rotate(180deg);

				@media (max-width: 650px) {
					width: 7px;
				}
			}

			span {
				font-size: 16px;

				@media (max-width: 650px) {
					font-size: 12px;
				}
			}
		}

		.giftcard-step {
			width: 100%;

			// Common styles for all steps
			input::placeholder {
				opacity: 1;
			}

			h3 {
				font-size: 24px;
				text-align: center;
				font-family: 'Roboto Condensed';

				@media (max-width: 640px) {
					font-size: 20px;
				}
			}

			.next-step-button {
				width: 100%;
				max-width: 375px;
				margin: 25px auto 0px;

				button {
					width: 100%;
					padding: 17px 0 !important;
				}
			}

			// Specific styles for each step
			.first-step {
				.receiver-tabs {
					width: 100%;
					display: flex;
					justify-content: space-around;
					border-bottom: 3px solid #ebedeb;
					padding-bottom: 17px;
					margin: 45px auto 38px;
					position: relative;

					@media (max-width: 575px) {
						gap: 10px;
					}

					h4 {
						font-size: 16px;
						font-weight: 600;
						margin: 0;
						color: #a3a9b9;
						cursor: pointer;

						@media (max-width: 575px) {
							font-size: 16px;
						}
					}

					h4.active {
						color: #000;
					}
				}

				.receiver-tabs:has(.active-first):before {
					content: '';
					position: absolute;
					top: 34px;
					left: 0px;
					margin: auto;
					width: 50%;
					height: 3px;
					background-color: #000;

					@media (max-width: 615px) {
						left: -12px;
						width: 52%;
					}
				}

				.receiver-tabs:has(.active-second):before {
					content: '';
					position: absolute;
					top: 34px;
					right: 0px;
					margin: auto;
					width: 50%;
					height: 3px;
					background-color: #000;

					@media (max-width: 615px) {
						right: -12px;
					}
				}

				.tab-content input {
					margin-bottom: 12px;
				}
			}

			.second-step {
				.giftcard-options-container {
					margin: 35px auto;
					display: flex;
					justify-content: center;

					@media (max-width: 650px) {
						display: block;
					}

					.giftcard-options {
						display: grid;
						gap: 18px;
						grid-template-columns: repeat(6, 1fr);

						@media (max-width: 650px) {
							grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
						}

						@media (max-width: 350px) {
							gap: 10px;
						}

						.option {
							padding: 14px 27px;
							border-radius: 5px;
							border: 1px solid #f3f3f3;
							cursor: pointer;

							@media (max-width: 650px) {
								padding: 14px 0px;
								display: flex;
								justify-content: center;
								align-items: center;
							}

							h4 {
								font-size: 17px;
								margin: 0;
							}
						}

						.option:hover {
							border: 1px solid #ccc;
							opacity: 0.7;
						}

						.option.active {
							border: 1px solid #000;
						}
					}
				}

				.giftcard-quantity {
					max-width: 180px;
					margin: auto;
					margin-bottom: 45px;
					text-align: center;

					h4 {
						line-height: normal;
						margin: 0;
						font-size: 24px;
						padding-bottom: 16px;
					}
				}
			}

			.third-step {
				.giftcard-message {
					margin-top: 18px;
					height: 195px;
				}
			}

			.fourth-step {
				.input-container {
					margin: 27px 0 37px;
					display: flex;
					flex-direction: column;

					input {
						margin-bottom: 12px;
					}

					.error {
						border: 1px solid red;
					}
				}
			}

			.fifth-step {
				.input-container {
					margin: 27px 0 37px;

					.billing-input {
						margin-bottom: 12px;

						input {
							color: #000;
						}

						.input-error {
							color: red;
						}
					}

					.phone-input {
						position: relative;

						label {
							position: absolute;
							top: 0;
							left: 48px;
							font-size: 10px;
							line-height: 15px;
							color: #717171;
						}
					}

					#countrySelect {
						margin-bottom: 12px;
					}
				}

				.payment-options {
					margin-bottom: 25px;

					.payment-options-title {
						width: 100%;
						font-family: 'Roboto Condensed';
						font-weight: 600;
						text-align: center;
						margin: 0 0 35px;

						@media (max-width: 980px) {
							text-align: center;
						}
					}

					.payment-options-container {
						display: flex;
						flex-direction: column;
						gap: 12px;

						.payment-option {
							height: 90px;
							width: 100%;
							padding: 0 20px;
							display: flex;
							align-items: center;
							gap: 50px;
							border: 1px solid #e2e5ea;

							input[type='radio'] {
								display: block;
								-ms-transform: scale(1.7);
								/* IE 9 */
								-webkit-transform: scale(1.7);
								/* Chrome, Safari, Opera */
								transform: scale(1.7);
							}

							.title {
								width: 100%;
								display: flex;
								align-items: center;
								gap: 50px;

								@media (max-width: 450px) {
									gap: 12px;
								}

								// img {
								//     max-width: 35px;
								// }

								span {
									font-size: 14px;

									@media (max-width: 350px) {
										font-size: 13px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.checkout-success-page {
	.order-success-message {
		margin: auto;
		max-width: 475px;
		padding: 0 25px;

		@media (max-width: 435px) {
			padding: 0 8px;
		}

		img {
			margin: auto;
			margin-bottom: 18px;
		}

		h3 {
			font-size: 20px;
			text-align: center;
			margin: 0px;
			line-height: 26px;

			span {
				color: #0154ff;
			}
		}
	}

	.checkout-success-card {
		box-shadow: 0px 10px 30px #0000001a;
		max-width: 965px;
		margin: 75px auto 100px;
		padding: 35px 100px 50px;

		@media (max-width: 600px) {
			padding: 35px 20px 50px;
			margin: 45px auto 80px;
		}

		.order-information {
			h1 {
				font-family: 'Roboto Condensed';
				font-size: 20px;
				text-align: left;
				margin: 0 0 30px;

				@media (max-width: 768px) {
					text-align: center;
					margin: 0 0 20px;
					font-size: 16px;
				}
			}

			ul {
				display: grid;
				grid-template-columns: 1fr 1fr;
				row-gap: 30px;
				column-gap: 30px;
				justify-content: space-between;
				margin-bottom: 75px;

				@media (max-width: 768px) {
					grid-template-columns: 1fr;
				}

				h4 {
					font-size: 14px;
					font-family: 'Roboto Condensed';
					text-align: left;
					margin-bottom: 5px;
				}

				.giftcard-message-column {
					grid-column: 1 / span 2;
				}

				.checkout-receipt .payment {
					margin-bottom: 10px;
				}
			}
		}

		.customer-information-message {
			display: flex;
			justify-content: space-between;

			@media (max-width: 950px) {
				flex-direction: column;
			}

			.message {
				.store-hours {
					max-width: unset;
					padding: 0;

					@media (max-width: 950px) {
						margin-bottom: 40px;
					}
				}

				h1 {
					font-family: 'Roboto Condensed';
					font-size: 20px;
					text-align: left;
					margin: 0 0 30px;

					@media (max-width: 768px) {
						text-align: center;
						margin: 0 0 20px;
						font-size: 16px;
					}
				}

				p {
					color: #6e7486;
					width: 90%;

					@media (max-width: 768px) {
						font-size: 14px;
					}
				}
			}

			.track-order {
				width: 100%;
				max-width: 280px;
				align-self: center;

				@media (max-width: 950px) {
					align-self: flex-start;
				}

				@media (max-width: 480px) {
					max-width: unset;
				}

				button {
					max-width: 100%;
					padding: 0 !important;
					height: 55px;
					width: 100%;
				}
			}
		}
	}
}
