body {
	-webkit-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

::selection {
	background-color: #040707;
	color: #fff;
	text-shadow: none;
}

.top-message {
	.page {
		padding-top: 85px;
	}

	@media (max-width: 480px) {
		.page {
			padding-top: 105px !important;
		}
	}
}

body.cart-open {
	overflow: hidden;
	position: relative;
	margin-left: -606px;
}

body.overflow {
	overflow: hidden;
}

body.cart-open:after {
	position: absolute;
	content: '';
	background-color: rgba(0, 0, 0, 0.2);
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
	z-index: 2003;
}

.page {
	padding-top: 61px;
}

.grid-col {
	display: grid;
}

.flex-col {
	display: flex;
}

button {
	font-family: Ubuntu, Arial, sans-serif;
	border-radius: 5px;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
}

form button {
	padding: 15px;
}

#slider_placeholder {
	background: unset;
	display: unset;
	height: unset;
	width: unset;
}

.newsletter-sub-message {
	margin: 60px 0 70px;
	text-align: center;
	font-size: 24px;
	font-weight: 700;
}

.arrow-button {
	position: relative;
	min-width: 180px;
	min-height: 48px;
}

.black-button {
	background-color: #000000;
	color: #ffffff;
	height: 48px;
	border-radius: 5px;
	padding: 15px 20px;
}

.white-arrow-button {
	background-color: #ffffff;
	color: #000000;
	border: 1px solid #000;
}

.black-arrow-button {
	background-color: #000000;
	color: #ffffff;
}

.arrow-button:after {
	content: '';
	position: absolute;
	background: url(../images/arrow-white.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	right: 34px;
	transition: 0.3s ease-out;
}

.white-arrow-button:after {
	content: '';
	position: absolute;
	background: url(../images/arrow-black.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	right: 34px;
	transition: 0.3s ease-out;
}

.black-arrow-button:hover:after,
.white-arrow-button:hover:after {
	width: 18px;
	right: 28px;
}

.acceptance {
	font-size: 12px;
	line-height: 18px;
	color: #717171;
}

.acceptance .red {
	position: initial;
	left: 0;
	top: 0;
	font-size: 14px;
}

.captcha-wrapper iframe {
	transform: scale(0.77);
	transform-origin: 0 0;
}

.no-result {
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	font-weight: 700;
	color: #d12b2b;
}

.empty-content {
	text-align: center;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
}

#view-cart {
	position: relative;
}

#view-cart .active span {
	background-color: #000;
	font-size: 8px;
	border-radius: 50%;
	position: absolute;
	top: -1px;
	left: 16px;
	padding: 1px 3px;
	line-height: 12px;
	min-width: 12px;
	height: 12px;
	text-align: center;
	color: #fff;
}

#pager {
	gap: 10px;
	justify-content: center;
	margin-top: 80px;
}

#pager li button {
	height: 35px;
	width: 35px;
	background-color: #fff;
	border-radius: 5px;
	font-size: 14px;
	line-height: 22px;
	transition: 0.3s ease-out;
}

#pager li button:hover {
	box-shadow: 0px 4px 6px rgb(0 0 0 / 16%);
}

#pager li.active button {
	background-color: #000;
	color: #fff;
}

#pager .arrow {
	width: 35px;
	height: 35px;
	display: flex;
	align-items: center;
	justify-content: center;
}

#pager .arrow.back {
	cursor: pointer;
	transform: rotate(180deg);
}

#pager .arrow.front {
	cursor: pointer;
}

.select__input {
	color: transparent !important;
}

/* toast */
.Toastify__toast.black-background {
	background-color: #000;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	font-family: 'Ubuntu', Arial, sans-serif;
	max-width: 282px;
	margin: auto;
}

.black-background svg {
	fill: #fff;
}

.Toastify__toast-container--top-center.toast-content {
	top: 62px;
}

.black-background .Toastify__progress-bar--info,
.black-background .Toastify__progress-bar--success,
.black-background .Toastify__progress-bar--warning,
.black-background .Toastify__progress-bar--error,
.black-background .Toastify__progress-bar-theme--light,
.black-background .Toastify__progress-bar-theme--dark {
	background: #f4f4f4;
}

/* end toast */

/*general inputs*/
input[type='password'],
input[type='email'],
input[type='text'],
input[type='tel'],
input[type='date'],
textarea,
.content-phone .form-control.phone-input {
	height: 48px;
	border: none;
	background-color: #f2f2f2;
	width: 100%;
	border-radius: 5px;
	font-size: 14px;
	line-height: 22px;
	color: #000;
	padding: 19px 14px 12px;
	outline: none;
}

.content-phone .form-control.phone-input {
	padding-left: 48px;
}

textarea {
	min-height: 133px;
	max-width: 588px;
	width: 100% !important;
	padding: 16px 14px;
}

textarea::placeholder {
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	max-width: 472px;
	font-family: Ubuntu, Arial, sans-serif;
	opacity: 1;
}

input.add-email::-webkit-input-placeholder {
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	max-width: 472px;
	font-family: Ubuntu, Arial, sans-serif;
	opacity: 1;
}

input.add-email::-ms-input-placeholder {
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	max-width: 472px;
	font-family: Ubuntu, Arial, sans-serif;
	opacity: 1;
}

.input-label a {
	text-decoration: underline;
	color: #161919;
}

input:required {
	padding-left: 14px;
}

.form-input {
	position: relative;
	margin-bottom: 17px;
}

input[type='radio'],
input[type='checkbox'] {
	display: none;
}

input[type='radio'] + .radio-label {
	display: block;
	cursor: pointer;
	padding-left: 27px;
	position: relative;
	line-height: 33px;
	display: flex;
	align-items: center;
}

input[type='radio'] + .radio-label:before {
	content: '';
	border-radius: 50%;
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 1px solid #000000;
	background: #fff;
	transition: 0.2s;
	position: absolute;
	left: 1px;
}

input[type='radio'] + .radio-label:after {
	content: '';
}

input[type='radio']:checked + .radio-label:after {
	position: absolute;
	left: 7px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	width: 10px;
	height: 10px;
	background: #000;
}

input[type='checkbox'] + .input-label {
	display: block;
	cursor: pointer;
	padding-left: 22px;
	position: relative;
}

.input-label::before,
.input-label::after {
	content: ' ';
}

input[type='checkbox'] + .input-label:before {
	content: '';
	border-radius: 2px;
	display: inline-block;
	width: 14px;
	height: 14px;
	background: #fff;
	transition: 0.2s;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 1px solid #ababab;
}

input[type='checkbox']:checked + .input-label:after {
	position: absolute;
	left: 6px;
	top: 0px;
	bottom: 0;
	margin: auto;
	width: 3px;
	height: 7px;
	border: solid #000000;
	border-width: 0 1px 1px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

input[type='checkbox'] + .input-label_newsletter {
	display: block;
	cursor: pointer;
	padding-left: 22px;
	position: relative;
}

.input-label_newsletter::before,
.input-label_newsletter::after {
	content: ' ';
}

input[type='checkbox'] + .input-label_newsletter:before {
	content: '';
	border-radius: 2px;
	display: inline-block;
	width: 14px;
	height: 14px;
	background: #fff;
	transition: 0.2s;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 1px solid #ababab;
}

input[type='checkbox']:checked + .input-label_newsletter:after {
	position: absolute;
	left: 6px;
	top: 0px;
	bottom: 0;
	margin: auto;
	width: 3px;
	height: 7px;
	border: solid #000000;
	border-width: 0 1px 1px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

form .text-label label {
	border: none;
	outline: none;
	display: block;
	position: absolute;
	left: 14px;
	/* bottom: 0;*/
	top: 0;
	font-size: 14px;
	line-height: 22px;
	color: #7b7b7b;
	pointer-events: none;
	/*margin: auto;*/
	height: fit-content;
	transition: 0.2s ease-out;
}

form input:placeholder-shown + label {
	transform: translateY(13px);
}

form input:not(:placeholder-shown) + label,
form input:focus + label {
	transform: translateY(0);
	font-size: 10px;
}

form input:not(:placeholder-shown),
.content-phone .form-control.phone-input:not(:placeholder-shown),
.content-phone .form-control.phone-input:not(:placeholder-shown) + .flag-dropdown {
	border: 1px solid #000;
}

form .text-label input:focus-visible + label,
form .text-label input:focus + label,
form .text-label.actived > label,
form .phone-label label {
	/* top: 1px;
    bottom: unset;*/
	font-size: 10px;
	color: #717171;
}

form .phone-label label {
	left: 48px;
}

.form-input.text-label.actived input:invalid,
.form-input.text-label.actived .no-validate,
.form-input.text-label.error input,
form .text-label.error > .css-b62m3t-container > div:last-child,
form .text-label.error > .css-1pahdxg-control {
	background-color: #ffe8e6 !important;
}

form .text-label.actived > input {
	background-color: #f4f4f4;
	border: 1px solid #000;
}

input:focus-visible,
input:focus {
	border: 1px solid #000;
}

*:focus::-webkit-input-placeholder {
	opacity: 0;
}

*:focus:-moz-placeholder {
	opacity: 0;
}

*:focus::-moz-placeholder {
	opacity: 0;
}

*:focus:-ms-input-placeholder {
	opacity: 0;
}

*:focus::placeholder {
	opacity: 0;
}

::-webkit-input-placeholder {
	color: #7b7b7b;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	font-size: 14px;
	opacity: 0;
}

:-ms-input-placeholder {
	color: #7b7b7b;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	font-size: 14px;
	opacity: 0;
}

::placeholder {
	color: #7b7b7b;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 400;
	font-size: 14px;
	opacity: 0;
}

.red {
	display: none;
}

input.error {
	box-shadow: 0.93px 3px 16px #ff0000a6;
}

.simple-link {
	text-align: right;
	margin-top: 12px;
}

.simple-link a {
	font-size: var(--picosize);
	line-height: 18px;
	text-decoration: underline;
	color: #000000;
}

button.submit:disabled {
	opacity: 0.4;
}

button.arrow-button.submit:after,
#go-to-form:after,
.orange-btn:after {
	content: '';
}
.profile-btn img {
	width: 22px;
	height: 22px;
}
button.submit:hover:after,
#go-to-form:hover:after,
.orange-btn:hover:after,
.profile-btn:hover:after {
	-webkit-animation: bounceright 0.3s alternate ease 4 forwards;
	animation: bounceright 0.3s alternate ease 4 forwards;
}

button.submit a {
	color: #fff;
}

form label:last-of-type input {
	margin-bottom: 0;
}

form .text-label.actived > div:first-child {
	border: 1px solid #000;
	border-radius: 5px;
}

.react-select__placeholder {
	color: #000 !important;
}

.breads {
	background-color: #fff;
}
/*page register success */
.grey-back {
	background-color: #f4f4f4;
}

.info-box-message {
	max-width: 600px;
	margin: auto;
	text-align: center;
	background-color: #fff;
	border-radius: 5px;
	padding: 56px 48px;
}

.info-box-message.checkout {
	box-shadow: -1px 0px 64px 14px rgba(217, 207, 207, 0.75);
	-webkit-box-shadow: -1px 0px 64px 14px rgba(217, 207, 207, 0.75);
	-moz-box-shadow: -1px 0px 64px 14px rgba(217, 207, 207, 0.75);
}

.info-box-message > div {
	font-size: 16px;
	line-height: 20px;
}

.info-box-message strong {
	font-size: 20px;
	line-height: 26px;
}

.info-box-message img {
	margin: 0 auto 40px;
}

.space-arround {
	padding: 100px 0;
}
/*end page register success */
/*Category page */
.category-page {
	color: #000000;
}

.category-page #breadcrumbs,
.back-news-page #breadcrumbs {
	padding: 10px 0;
}

.cat-list {
	padding: 60px 0 70px;
}

.banner-cat {
	background-color: #f2f2f2;
	padding: 50px 0;
	min-height: 242px;
	overflow: hidden;
}

.banner-cat > div {
	gap: 20px 6%;
	position: relative;
	min-height: 164px;
	/* justify-content: space-between;*/
}

.banner-cat > div:after {
	position: absolute;
	content: '';
	background: transparent linear-gradient(90deg, #f2f2f200 0%, #f2f2f2 100%);
	right: -88px;
	top: 0;
	bottom: 0;
	width: 202px;
	height: 242px;
	margin: auto;
}

.left-side {
	height: fit-content;
	margin: auto 0;
	max-width: 230px;
}

.left-side p {
	font-weight: 400;
	margin-bottom: 0;
}

.right-side {
	gap: 24px;
	margin-right: -102px;
	overflow-x: auto;
	overflow-y: hidden;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.right-side::-webkit-scrollbar {
	display: none;
}

.right-side img {
	mix-blend-mode: multiply;
	max-width: 180px;
	min-width: 160px;
	aspect-ratio: 1;
}

.right-side h4 {
	text-transform: uppercase;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: fit-content;
	height: fit-content;
	margin: auto;
	padding: 0 20px;
	font-size: 14px;
	line-height: 16px;
}

.sub-item {
	position: relative;
	background-color: rgba(0, 0, 0, 0.39);
	display: inline-table;
	overflow: hidden;
	border-radius: 10px;
	/*  margin: auto;*/
	border: 2px solid transparent;
}

.sub-item.choosen {
	border: 2px solid #040707;
}

.category-page h1 {
	font-size: 24px;
	line-height: 28px;
}

.cat-list h1 {
	margin-bottom: 32px;
}

.cat-list h1 span {
	color: #ababab;
}

.cat-list ul {
	gap: 30px;
	margin-bottom: 20px;
	position: relative;
	align-items: end;
}

.up-filters-cat .d-inline-block {
	max-width: 300px;
}

.cat-list ul li,
.cat-list ul li button {
	font-size: 12px;
	color: #000000;
	line-height: 32px;
	font-family: 'Ubuntu', Arial, sans-serif;
}

.short-filter {
	position: relative;
	padding-right: 15px;
}

.sort {
	margin-left: auto;
}

.sort .css-14el2xx-placeholder {
	min-width: 95px !important;
}

.short-filter::after {
	content: '';
	position: absolute;
	border-top: 1px solid;
	border-right: 1px solid;
	width: 6px;
	height: 6px;
	border-color: #000;
	transform: rotate(135deg);
	position: absolute;
	top: 23%;
	right: 0;
	transition: transform 0.5s;
}

.product-list {
	grid-template-columns: repeat(4, minmax(160px, 282px));
	gap: 30px 24px;
}

.product-box {
	position: relative;
	color: #000000;
}

.product-box .add-wish {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
	padding: 13px;
}

.product-box .add-wish .active path {
	fill: #000;
}

.product-box svg:hover path {
	fill: #000;
}

.sub-item a {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
}

.prod-img {
	position: relative;
	overflow: hidden;
}

.discount-container {
	position: absolute;
	top: 17px;
	left: 8px;
	z-index: 1;
	background-color: #e50000;
	font-size: 14px;
	color: #fff;
	padding: 2px 6px;
	border-radius: 5px;
	font-weight: 600;

	@media (max-width: 750px) {
		font-size: 12px;
	}
}

.on-hover {
	transition:
		opacity 0.4s linear,
		transform 0.3s ease-in-out;
	opacity: 0.4;
	width: 0;
	height: 0;
	transform: translateX(100%);
}

.product-box:hover .on-hover {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #fff;
	opacity: 1;
	width: 100% !important;
	height: 100% !important;
	transform: translateX(0);
}

.product-box .prod-colors input {
	width: 15px;
	height: 15px;
	display: block;
	border-radius: 4px;
	cursor: pointer;
	appearance: none;
	margin: 0;
	border: 1px solid #cecece;
}

.product-box .prod-colors input:checked {
	border: 2px solid #000000;
	width: 17px;
	height: 17px;
}

.productTabs-select {
	position: relative;
	width: fit-content;
	margin: auto;
}

.contact-select {
	position: relative;
	width: 100%;
	margin-bottom: 17px;
}

.productTabs-select:after {
	content: '';
	position: absolute;
	right: 7px;
	top: 0;
	bottom: 0;
	width: 8px;
	height: 8px;
	background: url(../images/feather-arrow-right.svg) no-repeat center;
	width: 9px;
	height: 6px;
	margin: auto;
	pointer-events: none;
}

.contact-select:after {
	content: '';
	position: absolute;
	right: 19px;
	top: 0;
	bottom: 0;
	width: 8px;
	height: 8px;
	background: url(../images/feather-arrow-right.svg) no-repeat center;
	width: 9px;
	height: 6px;
	margin: auto;
	pointer-events: none;
}

.prod-details {
	padding: 15px 0;
	/*background-color: #fff;*/
}

#product .prod-colors label {
	width: 25px;
	height: 25px;
}

.prod-details > .flex-col {
	justify-content: space-between;
	gap: 20px;
}

.prod-colors.flex-col {
	justify-content: left;
	gap: 5px;
	align-items: center;
	flex-wrap: wrap;
}

#product .product-info .sizes .size-box:disabled {
	background: #f4f4f4 0% 0% no-repeat padding-box;
	color: #ababab;
	box-shadow: none;
	pointer-events: none;
}

.prod-details > .flex-col > div {
	max-width: 160px;
}

.prod-details h4 {
	font-weight: 400;
	line-height: 20px;
	text-align: left;
	margin-bottom: 11px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	-webkit-box-align: start;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	min-height: 60px;
}

.prod-price {
	font-size: 16px;
	line-height: 16px;
	font-weight: 500;
	white-space: nowrap;
}

.prod-price span {
	font-size: 12px;
	line-height: 13px;
	margin-right: 7px;
	text-decoration: line-through;
}

.quick-buy,
.prod-dim {
	transition:
		visibility 0.1s linear,
		opacity 0.4s linear;
	visibility: hidden;
	opacity: 0;
	width: 0;
	height: 0;
}

.quick-buy span,
.prod-dim,
.prod-dim button {
	font-size: 0;
	color: #000;
}

.prod-dim button:disabled {
	color: #ababab;
	box-shadow: none;
	pointer-events: none;
}

.product-box:hover .quick-buy {
	position: absolute;
	bottom: 16px;
	right: 0;
	left: 0;
	background-color: #fff;
	max-width: 255px;
	margin: auto;
	border-radius: 5px;
	visibility: visible;
	opacity: 1;
	width: 92%;
	min-height: 35px;
	justify-content: center;
	align-items: center;
	z-index: 2;
	height: auto;
	padding: 5px;
}

.product-box:hover .quick-buy span {
	font-size: 16px;
	line-height: 18px;
	font-weight: 400;
	visibility: visible;
	opacity: 1;
}

.product-box:hover .btn-hover:hover span {
	font-size: 0;
	width: 0;
	height: 0;
}

.btn-hover:hover .prod-dim {
	visibility: visible;
	opacity: 1;
	gap: 13px;
	width: fit-content;
	height: auto;
	flex-wrap: wrap;
	justify-content: center;
}

.btn-hover:hover .prod-dim button {
	width: fit-content;
	height: 25px;
	padding: 1px;
	font-family: 'Ubuntu', Arial, sans-serif;
	font-size: 12px;
	line-height: 13px;
	font-weight: 500;
	background-color: #f2f2f2;
	border-radius: 5px;
	min-width: 25px;
	padding: 1px 2px;
}

.product-filters {
	position: fixed;
	top: 0;
	height: 100%;
	right: 0;
	width: calc(294px + (100vw - 1200px) / 2);
	background-color: #ffffff;
	padding: 80px calc(3px + (100vw - 1200px) / 2) 80px 19px;
	z-index: 999999;
	box-shadow: -10px 0px 10px 1px #00000029;
}

.header-filters {
	margin: 0 3px 26px 6px;
	margin-bottom: 26px;
}

.product-filters .clear-all {
	text-decoration: underline;
	color: #000;
	line-height: 22px;
}

.product-filters h2 {
	text-align: left;
}

.product-filters div.flex-col {
	justify-content: space-between;
	margin-bottom: 14px;
}

.filter-items li.choosen > a {
	border: 1px solid #000000;
	background-color: #f2f2f2;
	box-shadow: none;
}

.filter-content {
	padding: 18.5px 6px 17.5px;
}

.filters-list {
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	height: 90%;
}

.filters-list::-webkit-scrollbar {
	display: none;
}

.filters-list li:not(:first-child) .filter-content {
	border-top: 1px solid #f2f2f2;
}

.filter-content strong {
	position: relative;
	display: block;
	cursor: pointer;
	line-height: 16px;
	color: #000;
}

.filter-content strong:before {
	content: '';
	position: absolute;
	background-color: #000000;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 11px;
	height: 1px;
}

.filter-content strong:after {
	transition: transform 0.8s;
	content: '';
	position: absolute;
	background-color: #000000;
	top: 0;
	bottom: 0;
	right: 0.5px;
	margin: auto;
	width: 11px;
	height: 1px;
	transform: rotate(90deg);
}

.filter-content strong.active-filter:after {
	transform: rotate(0deg);
}

.filter-items {
	margin: 14px 0 3px;
	gap: 9px 10px;
}

.category-filter,
.color-filter,
.brand-filter,
.model-filter,
.attribute-filter {
	grid-template-columns: repeat(2, 1fr);
}

.color-filter .fil-col {
	max-width: 44px;
	width: 100%;
	aspect-ratio: 1;
	margin-right: 10px;
}

.filter-items.color-filter li > a {
	justify-content: unset;
	word-break: break-word;
	text-align: left;
}

.size-filter {
	grid-template-columns: repeat(3, 70px);
}

.filter-items.size-filter li > a {
	min-height: 34px;
}

.filter-items li > a {
	background-color: #fff;
	box-shadow: 0px 4px 6px #00000029;
	min-height: 52px;
	width: 100%;
	padding: 4px;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 12px;
	line-height: 16px;
	color: #000000;
	font-weight: 500;
	border-radius: 5px;
}

.filter-items li > a.disabled {
	background-color: #f6f6f6;
	color: lightgray;
	pointer-events: none;
}

.size-filter div > a {
	min-height: 34px;
}

#range-price .filterslide-track.filterslide-track-0,
#range-price .filterslide-track.filterslide-track-2 {
	background-color: #f4f4f4;
	top: 24px;
}

#range-price .horizontal-slider {
	height: 36px;
	position: relative;
}

#range-price .filterslide-track {
	background-color: #000;
	top: 24px;
}

#range-price .filterslide-thumb {
	background: #fff;
	font-weight: normal;
	border: 4px solid #030303;
	width: 20px;
	height: 20px;
	box-shadow: none;
	top: 16px;
}

.prices-box {
	margin-top: 14px;
	justify-content: space-between;
}

.prices-box label {
	font-size: 14px;
	font-weight: 500;
	color: #000;
}

.prices-box input {
	height: 30px;
	border-radius: 4px;
	border: 1px solid #b0adad;
	outline: none;
	font-size: 12px;
	line-height: 13px;
	color: #000;
	font-weight: 500;
	max-width: 70px;
	margin-right: 6px;
	font-family: Ubuntu, Arial, sans-serif;
	padding: 5px;
	cursor: default;
}

.show-more-prods {
	text-align: center;
	font-size: 14px;
	line-height: 22px;
	color: #000;
	margin-top: 62px;
}

.progress-percentage {
	height: 5px;
	max-width: 100%;
	width: 384px;
	background-color: #f2f2f2;
	margin: auto;
	position: relative;
	margin-top: 20px;
}

.progress-percentage:after {
	position: absolute;
	content: '';
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #000;
}

.see-more {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	min-width: 170px;
	border-radius: 0px 10px;
	border: 1px solid #000000;
	margin-top: 38px;
	transition: 0.3s ease-out;
}

.see-more:hover {
	color: #fff;
	background-color: #000;
}

.filters-btn {
	position: fixed;
	align-items: center;
	font-size: 12px;
	line-height: 22px;
	padding: 12px 33px;
	bottom: 50px;
	right: 0;
	left: 0;
	width: fit-content;
	margin: auto;
	border-radius: 50px;
	z-index: 1999;
}

.filters-btn img {
	margin-right: 12px;
}

.category-page .css-b62m3t-container .css-1s2u09g-control,
.category-page .css-b62m3t-container .css-1pahdxg-control {
	min-height: unset !important;
	background-color: #fff !important;
	padding: 0 !important;
	height: unset !important;
	outline: none !important;
	border: none !important;
}

.category-page .css-1s2u09g-control > div:first-child,
.category-page .css-1pahdxg-control > div:first-child {
	padding: 0 !important;
	width: auto !important;
	min-width: 71px !important;
	outline: none !important;
	border: none !important;
	display: flex;
}

.category-page .css-14el2xx-placeholder::after {
	top: 28%;
	width: 5px;
	height: 5px;
}

.category-page .css-14el2xx-placeholder {
	margin: 0 !important;
	color: #000000 !important;
	font-size: 12px;
	padding-right: 20px;
}
/*end Category */

/*login- register*/

.login-modal {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.84);
	z-index: 9998;
}

@-webkit-keyframes appear {
	0% {
		opacity: 0;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}
}

.login-modal main {
	background-color: #fff;
	max-width: 792px;
	height: fit-content;
	height: -moz-fit-content;
	height: -webkit-fit-content;
	min-height: 402px;
	margin: auto;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 53px 18px 40px;
	overflow: auto;
	scrollbar-width: none;
	-webkit-animation: appear 400ms ease-in-out 1;
	animation: appear 400ms ease-in-out 1;
}

.login-modal main::-webkit-scrollbar {
	display: none;
}

.login-content main {
	min-height: 556px;
}

/*.guest-register-content main{
    max-height: 402px;
}*/

.register-content main {
	min-height: 778px;
}

.register-content .acceptance input[type='checkbox'] + .input-label:before {
	bottom: unset;
	top: 3px;
}

.login-modal main > button {
	position: absolute;
	top: 21px;
	right: 25px;
}

.login-modal ul,
.login-alter > div {
	justify-content: center;
	gap: 24px;
}

.login-modal li button {
	padding: 16px 17px;
	background-color: #fff;
	box-shadow: 0px 4px 6px #00000029;
	min-width: 180px;
	border: 1px solid #fff;
	border-radius: 5px;
	margin-bottom: 36px;
	font-size: 16px;
	line-height: 18px;
	font-family: Ubuntu, Arial, sans-serif;
	font-weight: 700;
}

.login-modal li button.active-log-tab {
	background-color: #f2f2f2;
	border: 1px solid #000000;
	box-shadow: none;
}

#login-form {
	text-align: center;
}

#login-form .captcha-wrapper div {
	margin: auto !important;
}

#login-form button.submit {
	margin-top: 36px;
}

#login-wrapper {
	max-width: 384px;
	margin: auto;
}

#login-wrapper .captcha-wrapper iframe {
	transform: translate(40px, 25px) scale(0.77);
}

.login-alter {
	margin-top: 23px;
	padding-top: 19px;
	text-align: center;
	position: relative;
}

.login-alter:after {
	content: '';
	position: absolute;
	border-top: 1px solid #f4f4f4;
	top: 0;
	right: -18px;
	left: -18px;
	height: 1px;
}

.login-alter span {
	font-size: 14px;
	line-height: 16px;
	display: block;
	margin-bottom: 32px;
	color: #000;
}

.login-alter div > button {
	border: 1px solid #707070;
	border-radius: 5px;
	height: 48px;
	padding: 10px 22px;
	justify-content: space-between;
	width: 180px;
	align-items: center;
	font-size: 16px;
	line-height: 18px;
	color: #000;
}

#account-info-wrapper,
.register-wrapper {
	max-width: 588px;
	margin: auto;
}

.register-wrapper > .grid-col {
	gap: 40px 24px;
	grid-template-columns: 282px 1fr;
}

.register-wrapper > .grid-col button {
	min-width: 282px;
	width: 100%;
	text-align: left;
	padding-left: 35px;
}

.grid-col.gender-sec {
	grid-template-columns: 75px 1fr;
	gap: 19px 15px;
	margin: 23px 0;
}

.grid-col.gender-sec label {
	font-family: Ubuntu, Arial, sans-serif;
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	color: #000;
	margin-right: 0;
	width: fit-content;
}

.sec-col-reg > div {
	color: #000;
	font-size: 14px;
	line-height: 20px;
}

.sec-col-reg ul {
	list-style-type: disc;
	padding-left: 17px;
}

.sec-col-reg button {
	margin-top: auto;
}

.inputs {
	grid-template-columns: 1fr 1fr;
	gap: 0 24px;
}

#account-info .grid-col.gender-sec {
	margin: 2px 0 25px;
}

.accept-sec {
	gap: 24px;
	margin-bottom: 18px;
}

#account-info button {
	margin: 12px auto 25px;
	display: block;
}

.back-btn {
	gap: 10px;
	align-items: center;
	margin-bottom: 16px;
}

.form-input.text-label > .custom-pass {
	position: absolute !important;
	left: 0;
	margin-top: 10px;
	display: none;
	width: 100%;
}

.form-input.text-label.actived > .custom-pass {
	display: block;
}

.custom-pass p {
	margin: 0 !important;
}

/*end login- register*/
/*start cart */
.back-page {
	background-color: #f4f4f4;
	background: linear-gradient(to right, #ffffff 51%, #f4f4f4 51%);
}

#cart-page .css-b62m3t-container > div:last-child,
#cart-page .css-1pahdxg-control,
#cart-widget .css-b62m3t-container > div:last-child,
#cart-widget .css-1pahdxg-control {
	min-height: 34px !important;
	width: 54px;
	background-color: #fff !important;
	border: 1px solid #000000;
	color: #000000;
}

#cart-page .css-qc6sy-singleValue,
#cart-widget .css-qc6sy-singleValue {
	color: #000000 !important;
}

#cart-page .css-qc6sy-singleValue::after,
#cart-widget .css-qc6sy-singleValue::after {
	top: 37%;
	right: 3px;
}

.card-sides {
	grid-template-columns: 51% 49%;
}

.cart-box {
	background-color: #ffff;
	box-shadow: 0px 10px 30px #0000001a;
	padding: 26px 51px 46px;
	border-radius: 5px;
	font-size: 14px;
	color: #000;
	line-height: 20px;
}

.cart-box h1,
.left-cart h1 {
	font-size: 20px;
	line-height: 24px;
	color: #000;
	text-align: center;
}

.cart-box h1 {
	text-align: left;
	margin-bottom: 35px;
}

.discount-coupon {
	text-decoration: underline;
	margin-bottom: 26px;
}

.order-synopsis {
	gap: 18px;
	padding-bottom: 27px;
	border-bottom: 1px solid #f4f4f4;
	margin-bottom: 24px;
}

.cart-box .order-synopsis .grid-col {
	grid-template-columns: 1fr 1fr;
	gap: 18px 10px;
}

.right-cart > div {
	padding: 72px 0;
	max-width: 486px;
	margin-right: 0;
	margin-left: auto;
	width: 100%;
}

.cart-box button {
	width: 100%;
	padding: 15px;
	margin-top: 20px;
}

.cart-box .coupon-input {
	margin-top: 10px;
}

.right-cart .coupon-input .coupons-badges {
	margin-top: 20px;
}

.right-cart .coupon-input button {
	margin-top: 15px;
	margin-bottom: 25px;
}

.coupons-badges {
	margin-top: 25px;
	display: flex;
	gap: 19px;
	flex-wrap: wrap;

	.badge {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		border-radius: 21px;
		font-size: 14px;
		font-family: 'Roboto Condensed';
		font-weight: 700;
		color: #000;
		padding: 7px 19px;
		border: 1px solid #000;
		cursor: pointer;
		transition: 0.5s;

		img {
			cursor: pointer;
			width: 11px;
		}
	}

	.badge:hover {
		opacity: 0.7;
	}
}

.cart-box .loyalty-notification {
	color: #717171;
	font-size: 12px;
}

/*.left-cart {
    background-color: #fff;
}
*/

.left-cart > div {
	padding: 35px 26px 40px 15px;
	max-width: 612px;
	margin-left: auto;
}

.left-cart h1 {
	text-align: left;
	margin: 44px 0 40px;
}

.cart-items {
	gap: 42px;
	margin-bottom: 10px;
}

.cart-item {
	grid-template-columns: 120px 1fr auto;
	gap: 24px;
	color: #000;
	border-bottom: 1px solid #ababab;
	padding-bottom: 42px;
}

.cart-item:last-child {
	border: none;
}

.cart-item > .flex-col {
	flex-direction: column;
	justify-content: space-between;
}

.cart-item .bottom-right {
	text-align: right;
}
.cart-item .cart-item-error {
	color: #d12b2b;
	padding-top: 15px;
}

.cart-item .price {
	font-size: 16px;
	line-height: 18px;
}

.cart-item .edit.flex-col {
	justify-content: space-between;
}

.cart-item-info > div {
	margin-bottom: 10px;
}

.cart-item h4 {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: left;
	margin-bottom: 11px;
	max-width: 250px;
}

.cart-item .color,
.cart-item .dimension {
	font-size: 14px;
	color: #ababab;
	line-height: 16px;
}

.cart-item .quantity {
	margin-top: auto;
	margin-bottom: 0;
}

.cart-item-info span {
	font-weight: 500;
}

.cart-item .color-hex {
	margin-top: 9px;
	border-radius: 2px;
}

.empty-cart {
	margin: 60px 0 70px;

	.head-account {
		position: relative;
		width: 100%;
		text-align: center;

		button {
			position: absolute;
			left: 0;
		}

		h1 {
			font-size: 20px;
			line-height: 24px;

			@media (max-width: 480px) {
				font-size: 16px;
				line-height: 18px;
			}
		}
	}

	.empty-content {
		margin-top: 40px;
	}

	@media (max-width: 640px) {
		margin: 20px 0 60px;
	}
}

#coupons-icon {
	width: 22px;
	height: 22px;
	display: none;
}

.box-cust {
	background: #fff;
	box-shadow: 0px 10px 30px #0000001a;
	padding: 30px 10px 37px;
	max-width: 593px;
	margin: auto;
}

.box-cust > .flex-col {
	max-width: 425px;
	justify-content: space-between;
	margin: auto;
	gap: 5px;
}

.vertical-line {
	border-left: 1px solid #ababab;
}

.box-cust span {
	margin-left: 27px;
	font-size: 14px;
	line-height: 20px;
}

.box-cust img {
	height: fit-content;
}

.box-cust span > a {
	color: #000;
	font-weight: 700;
}

.discount {
	color: #28c790;
}

.amount {
	text-align: right;
}

.total .amount {
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}

.cart-ship-info {
	gap: 9px;
	margin-top: 21px;
}

.cart-ship-info li {
	background-color: #f4f4f4;
	padding: 6px 9px 6px 18px;
	border-radius: 100px;
}

.cart-ship-info li img {
	margin-right: 12px;
}

/*end cart */

/*start account*/

.sidebar a {
	font-size: 14px;
	line-height: 20px;
	color: #000;
	padding: 12px 5px 12px 20px;
	border-radius: 5px;
	display: flex;
	gap: 24px;
	transition: 0.1s ease-out;
	/*white-space: nowrap;*/
	margin-bottom: 4px;
}

.sidebar a:hover,
.active-side {
	background-color: #fff;
	font-weight: 700;
}

.account-page {
	background-color: #f4f4f4;
}

.account-page .content-wrapper {
	/* max-width: 1026px;*/
	padding-top: 68px;
	padding-bottom: 89px;
}

.account-page h1 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 400;
	color: #000;
	text-align: center;
}
.account-page p {
	text-align: center;
}

.account-page .content-wrapper > h2 {
	text-align: left;
}

.account-page .content-wrapper > .grid-col {
	margin-top: 37px;
	grid-template-columns: minmax(150px, 282px) 1fr;
	gap: 24px;
}

.account-page .main-content {
	background: #fff;
	padding: 36px 20px;
	box-shadow: 0px 10px 30px #00000029;
	border-radius: 5px;
}

.acc-content {
	max-width: 690px;
	margin: auto;
}

.account-page .main-content:nth-child(2) {
	margin-top: 30px;
}

.log-out {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #000;
	display: block;
	text-align: center;
}

h1.acc-title {
	margin-bottom: 30px;
}

.my-account-form {
	max-width: 486px;
	margin-right: auto;
	margin-left: auto;
}

.right-sit {
	margin-top: 28px;
	margin-left: auto;
	display: block;
	min-width: 204px;
}

/*address*/
.address-list-headers > ul,
.address-item > ul {
	grid-template-columns: 22% 22% 22% 70px 1fr;
	gap: 21px 4.6%;
	padding-left: 8.27%;
	padding-right: 10px;
}

.address-list-headers > ul {
	margin: 36px 0 16px;
}

.address-list-headers > ul li {
	font-size: 14px;
	color: #717171;
	line-height: 20px;
}

.address-item > ul {
	background-color: #f4f4f4;
	height: 70px;
	border-radius: 5px;
	align-items: center;
}

.address-item > ul li {
	font-size: 12px;
	line-height: 20px;
	color: #000;
	font-weight: 700;
}

.address-item {
	margin-bottom: 17px;
}

.address-list li.controls {
	gap: 20px;
	justify-self: end;
}

.add-address button {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 13.5px 16px 13.5px 22px;
	border-radius: 5px;
	border: 1px solid #000;
	text-align: left;
	min-width: 204px;
	justify-content: space-between;
	margin-left: auto;
	gap: 10px;
	transition: 0.3s ease-out;
}

.add-address button svg line {
	transition: 0.3s ease-out;
}

.add-address button:hover {
	color: #fff;
	background-color: #000;
}

.add-address button:hover svg line {
	stroke: #fff;
}

.loyalty-points button {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 13.5px 16px 13.5px 22px;
	border-radius: 5px;
	border: 1px solid #000;
	text-align: left;
	min-width: 204px;
	justify-content: space-between;
	margin-left: auto;
	gap: 10px;
	transition: 0.3s ease-out;
	margin-top: 20px;
}

.loyalty-points button:hover {
	color: #fff;
	background-color: #000;
}

.loyalty-points h4 {
	font-size: 15px;
	margin-top: 50px;
}

@media all and (max-width: 640px) {
	.loyalty-points h4 {
		font-size: 15px;
		margin-top: 0px;
		margin: 0 40px;
		padding-bottom: 50px;
	}
}

/*order history */

.order-list > ul,
.ordhist-item > ul {
	grid-template-columns: 20% 16% 12% 18% 130px;
	gap: 20px;
}

.ordhist-item > ul {
	padding: 8px 8px 8px 57px;
	overflow: hidden;
}

.ordhist-item img {
	object-fit: contain;
	min-width: 40px;
}

.ordhist-item .prods > ul li:first-child {
	margin-left: auto;
}

.ordhist-item .prods > ul {
	gap: 2px;
	/* justify-content: end;*/
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.ordhist-item .prods > ul::-webkit-scrollbar {
	display: none;
}

.ordhist-item .status {
	font-size: 10px;
	line-height: 14px;
	font-weight: 400;
	color: #717171;
}
.ordhist-item button {
	position: relative;
	font-size: 13px;
	line-height: 15px;
	color: #000;
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #000;
	text-align: left;
	transition: 0.3s ease-out;
	top: 8px;
}
.ordhist-item button:hover {
	color: #fff;
	background-color: #000;
}
/*end account*/

/*start wishlists */

.wishlist-page {
	background-color: #f4f4f4;
	color: #000;
}

.wishlist-page > .content-wrapper.body {
	padding-bottom: 96px;
}

.wishlist-page h1 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 700;
	color: #000;
	text-align: center;
}

.wishlist-page .page-title {
	justify-content: space-between;
	padding-top: 92px;
	padding-bottom: 30px;
	margin-bottom: 0;
}

.wishlist-page .page-title > button,
.wishlist-page .page-title > div {
	width: 244px;
}

.wishlist-page .page-title button span.mobile900:hover {
	text-decoration: underline;
}

.wishlist-page .add-address button {
	min-width: 244px;
}

.wishlist-page .name {
	margin-top: 23px;
	margin-bottom: 8px;
	color: #000;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
}

.wishlist-page .quantity {
	color: #717171;
	font-size: 14px;
	line-height: 21px;
}

.wishlist-items {
	grid-template-columns: repeat(2, minmax(150px, 440px));
	gap: 30px;
}

.masonry-pictures {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 24px;
	height: 284px;
}

.masonry-pictures > div:first-child {
	width: 65.1%;
}

.masonry-pictures img {
	object-fit: cover;
	object-position: top;
	width: 100%;
}

.masonry-pictures .grid-col {
	gap: 14px;
	width: 30.46%;
}

.edit-wishlist button {
	font-size: 16px;
	line-height: 18px;
	font-family: Ubuntu, Arial, sans-serif;
	color: #000;
	margin-left: auto;
	align-items: center;
}

.edit-wishlist img {
	margin-right: 9px;
}

.one-wishlist .page-title {
	padding-bottom: 72px;
}

/*end wishlists */

/*modals wishlist */

.modal {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.84);
	z-index: 2003;
}

.modal-content {
	background-color: #fff;
	max-width: 588px;
	height: fit-content;
	margin: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 56px 102px 42px;
	border-radius: 5px;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-webkit-animation: appear 400ms ease-in-out 1;
	animation: appear 400ms ease-in-out 1;
}

.modal-content::-webkit-scrollbar {
	display: none;
}

.modal-content h2 {
	font-size: 20px;
	line-height: 24px;
	color: #000;
	justify-content: center;
}

.modal-content h2 img {
	margin-right: 8px;
}

.modal-content .exit {
	position: absolute;
	top: 20px;
	right: 25px;
}

.add-to-wish-pop .modal-content {
	padding-right: 0;
	padding-left: 0;
}

.wishlists-list {
	margin-top: 37px;
	max-height: 280px;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	gap: 17px;
	padding: 0 102px;
	background:
		linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, #00000010, rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, #00000010, rgba(0, 0, 0, 0)) 0 100%;
	background:
		linear-gradient(white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
		radial-gradient(farthest-side at 50% 0, #00000010, rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 50% 100%, #00000010, rgba(0, 0, 0, 0)) 0 100%;
	background-repeat: no-repeat;
	background-color: white;
	background-size:
		100% 40px,
		100% 40px,
		100% 14px,
		100% 14px;
	background-attachment: local, local, scroll, scroll;
}

.wishlists-list::-webkit-scrollbar {
	display: none;
}

.mini-wishlist {
	grid-template-columns: 78px 1fr 45px;
	gap: 24px;
}

.mini-wishlist button {
	background-color: #f4f4f4;
	border-radius: 200px;
	height: 45px;
	padding: 14px;
	align-self: center;
}

.mini-wishlist img {
	object-fit: cover;
	object-position: top;
}

.info-wishlist .name {
	font-size: 16px;
	line-height: 18px;
	font-weight: 700;
	color: #000;
	margin-bottom: 20px;
}

.info-wishlist .quantity {
	font-size: 14px;
	line-height: 20px;
	color: #717171;
}

.modal-content .add-address {
	background-color: #fff;
	padding: 20px 0 20px;
	background-color: #fff;
	margin-bottom: -40px;
}

.modal-content .add-address button {
	margin: auto;
}

.no-img {
	height: 78px;
	background-color: #f4f4f4;
}

.new-wishlist-addition p {
	margin: 16px auto 22px;
	text-align: center;
	max-width: 250px;
	color: #000000;
	line-height: 20px;
}

.wish-btn {
	text-align: left;
	padding-left: 32px;
}

.add-wish-modal {
	padding-top: 77px;
	padding-bottom: 66px;
}

p.edit-wish-info {
	width: 100%;
	text-align: left;
	max-width: unset;
	margin: 27px auto 14px;
}

.submit.edit-save {
	width: 100%;
}

.extra-del {
	position: relative;
	margin-top: 27px;
	padding-top: 30px;
}

.extra-del:before {
	content: '';
	position: absolute;
	top: 0;
	right: -102px;
	left: -102px;
	border-top: 1px solid #f4f4f4;
}

.extra-del button {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	min-width: 180px;
	border-radius: 5px;
	border: 1px solid #000;
	display: block;
	text-align: center;
}

.extra-del p {
	margin: 0px auto 10px;
}

.no-image {
	width: 100%;
	height: 100%;
	background-color: #fff;
	/*min-width: 134px;*/
	min-height: 134px;
}

.box-empty-list {
	max-width: 588px;
	background-color: #fff;
	margin: 0 auto 100px;
	border-radius: 5px;
	padding: 62px 48px;
	text-align: center;
}

.box-empty-list .submit {
	padding: 15px;
	min-width: 228px;
}

.box-empty-list p {
	margin: 21px auto 14px;
	font-size: 14px;
	line-height: 20px;
	color: #000;
}

/* Order Page Start*/
.order-page {
	background-color: #ffffff;
	border-radius: 5px;
	padding: 40px 40px 20px 40px;

	.order-status {
		padding: 0px 40px 0px 40px;
	}

	.order-status p {
		font-size: 16px;
		line-height: 21px;
		font-weight: bold;
		-webkit-font-smoothing: antialiased;
	}

	.order-information {
		padding: 10px 40px 20px 40px;
		display: flex;
		justify-content: space-between;
	}

	.order-information > div {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.order-information > div span:first-child {
		color: #707070;
	}

	.order-products-container {
		margin-top: 40px;
		padding: 20px 40px 20px 0px;
		border-top: 1px solid #e8e8e8;
	}
	.order-product {
		display: flex;
		justify-content: space-between;
	}
	.order-product .media {
		display: flex;
		align-items: center;
	}
	.order-product .media figure {
		border: 1px solid #f3f3f3;
		border-radius: 8px;
	}
	.order-product .media .name {
		color: #000;
		font-weight: 400;
		margin: 5px 0;
	}
	.order-product .media .dimension {
		color: #707070;
		font-weight: 400;
		margin: 5px 0;
	}
	.order-product .media a.name:hover {
		text-decoration: underline;
	}
	.order-synopsis-information {
		margin: 10px 0;
		border-top: 1px solid #e8e8e8;
		padding: 20px 40px 20px 0px;
		display: flex;
		justify-content: space-between;
		gap: 30px;

		.order-billing-information {
			p {
				margin-bottom: 30px;
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		.order-total-summary {
			align-self: center;
			p {
				font-size: 17px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				gap: 60px;
			}
		}
	}
	.order-actions .cancel {
		padding: 10px 20px;
		background: transparent;
		border: 1px solid #333;
		border-radius: 4px;
		color: #333;
		margin-right: 10px;
	}
	.order-actions button:hover {
		color: #fff;
		background-color: #333;
	}

	@media all and (max-width: 950px) {
		.order-synopsis-information {
			display: block;
			justify-content: space-between;
		}
	}

	@media all and (max-width: 768px) {
		.order-information {
			padding: 10px 40px 0px 40px;
			display: block;
		}

		.order-information > div {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 0px;
			margin-bottom: 15px;
		}

		.order-information > div span:first-child {
			color: #707070;
		}

		.order-products-container {
			margin-top: 40px;
			border-top: 1px solid #e8e8e8;
		}
		.order-product {
			display: flex;
			justify-content: space-between;
			position: relative;
			margin-bottom: 20px;
		}
		.order-product .price {
			position: absolute;
			bottom: 0px;
			right: 0px;
		}

		.order-synopsis-information {
			padding: 20px 40px 20px 40px;
		}

		.order-actions {
			padding: 0px 40px;
		}
	}
	@media all and (max-width: 425px) {
		.order-status {
			padding: 0px 16px 0px 16px;
		}
		.order-information {
			padding: 10px 16px 0px 16px;
		}
		.order-information > div {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 4px;
			margin-bottom: 25px;
		}
		.order-products-container {
			padding: 20px 16px 20px 0px;
		}
		.order-product {
			display: flex;
			justify-content: space-between;
			margin-bottom: 35px;
		}
		.order-product .media {
			align-items: flex-start;
		}
		.order-product .media figure {
			margin: 15px 10px;
		}
		.order-product .media .name {
			font-size: 14px;
		}
		.order-product .price {
			position: absolute;
			bottom: -20px;
			right: 10px;
		}
		.order-synopsis-information {
			.order-total-summary {
				p {
					font-size: 15px;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					gap: 10px;
				}
			}
		}
	}
}
@media all and (max-width: 768px) {
	.order-page {
		padding: 20px 0;
	}
}

/* Order Page End*/

.products-return-table {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 10px;
	padding-right: 10px;
}
.products-return-table .active {
	background-color: lightgray !important;
}
h2 {
	font-size: 26px;
	margin: 20px 0;
	text-align: center;
	small {
		font-size: 0.5em;
	}
}
.responsive-table .table-header {
	padding: 18px 0px;
}
.table-header .col {
	font-size: 13px;
}
.table-header .product {
	text-align: left;
	margin-left: 25px;
}
.checkbox-area {
	margin-top: 10px;
}
.table-row .col {
	display: flex;
	align-items: center;
	justify-content: center;
}
.product {
	gap: 5px;
}
.qty {
	position: relative;
}
.qty .sub {
	position: absolute;
	font-size: 30px;
	left: 40px;
}
.qty .add {
	position: absolute;
	font-size: 20px;
	right: 40px;
}
.responsive-table {
	li {
		border-radius: 3px;
		padding: 15px 10px;
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		text-align: center;
	}
	.table-header {
		background-color: #000;
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.03em;
	}
	.table-row {
		background-color: #ffffff;
		box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
	}
	.col-1 {
		flex-basis: 4%;
	}
	.col-2 {
		flex-basis: 40%;
	}
	.col-3 {
		flex-basis: 25%;
	}
	.col-4 {
		flex-basis: 25%;
	}

	@media all and (max-width: 767px) {
		.table-header {
			display: none;
		}
		.table-row {
			position: relative;
		}
		li {
			padding-bottom: 50px;
			display: block;
			text-align: left;
		}
		.col {
			flex-basis: 100%;
		}
		.col {
			display: flex;
			padding: 10px 0;
			justify-content: start;
			&:before {
				padding-right: 20px;
				color: #6c7a89;
				content: attr(data-label);
			}
		}
		.table-row .return-checkbox {
			position: absolute;
			padding: 0px;
			right: 25px;
			bottom: 10px;
			&:before {
				color: #6c7a89;
			}

			.checkbox-area {
				position: absolute;
				top: 2px;
				left: 88px;
			}
		}
		.table-row .product {
			gap: 0px;
			justify-content: start;
			align-items: flex-start;
			font-weight: bold;
			// flex-direction:row-reverse;
			&:before {
				padding-right: 0px;
				content: '';
			}
		}
		.table-row .product img {
			width: 60px;
			height: auto;
			margin-right: 20px;
		}
		.checkbox-area {
			margin-top: 10px;
		}
		.qty .sub {
			position: absolute;
			font-size: 30px;
			left: 190px;
		}
		.qty span {
			position: absolute;
			left: 210px;
			cursor: pointer;
		}
		.qty .add {
			position: relative;
			font-size: 19px;
			margin-left: 0px;
			left: 30px;
		}
	}
	@media all and (max-width: 340px) {
		.qty {
			margin: 0 0 0 0;
		}
		.qty .sub {
			font-size: 30px;
			left: 180px;
		}
		.qty span {
			left: 200px;
			cursor: pointer;
		}
		.qty .add {
			position: relative;
			font-size: 19px;
			left: 20px;
		}
	}
}
.return-reason-input textarea {
	margin-top: 10px;
	background-color: #ffffff;
	max-width: 100%;
}
.return-iban-input {
	margin-top: 20px;
}
.return-iban-input input {
	margin-top: 10px;
	background-color: #ffffff;
}
.no-wishlists {
	padding: 56px 48px 40px;
	margin-top: 25px;
}

.no-wishlists button {
	align-self: center;
	background-color: #f4f4f4;
	border-radius: 200px;
	height: 45px;
	padding: 14px;
}

.interest-prods {
	margin-top: 100px;
}

.interest-prods h1 {
	text-align: left;
	font-size: 24px;
	line-height: 28px;
}

#personalised-products {
	padding-bottom: 45px;
}

#personalised-products h3 {
	margin-bottom: 29px;
}

#personalised-products .content-wrapper {
	position: relative;
}

#personalised-products .embla_controls {
	top: -10px;
	left: -15px;
	gap: 16px;
	justify-content: end;
	padding: 0;
}

.embla_pers {
	overflow: hidden;
}

.embla_pers .embla__container {
	display: flex;
}

.embla_pers .embla__slide {
	flex: 0 0 23.5%;
	margin-right: 24px;
}
/*follow order*/
#follow-order .inputs {
	position: relative;
}

#follow-order form {
	max-width: 513px;
	margin: auto;
}

#follow-order input {
	border-radius: 100px;
}

#follow-order p {
	color: #717171;
	margin-bottom: 34px;
}

#follow-order form button {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 14px;
	padding: 0;
}

.order-status-items {
	gap: 43px;
	margin: 32px 0 19px;
}

.order-status-item {
	gap: 46px;
	align-items: center;
}

.back-icon {
	background-color: #ababab;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	display: flex;
}

.back-icon img {
	margin: auto;
}

.order-status-item img {
	width: auto;
	max-width: unset;
}

.order-status-item li.flex-col {
	justify-content: space-between;
	width: 100%;
	gap: 10px 46px;
	color: #000;
	line-height: 20px;
	font-size: 14px;
}

.order-status-item:not(:first-child) li.img {
	position: relative;
}

.order-status-item:not(:first-child) li.img:after {
	position: absolute;
	content: '';
	border-top: 50px solid #ababab;
	right: 0;
	left: 0;
	width: 1px;
	height: 43px;
	margin: auto;
	bottom: -6px;
}

.order-status-item li.flex-col span {
	color: #f4f4f4;
	margin: 0 16px 0 22px;
}

.order-status-item li.flex-col > div:last-child {
	font-weight: 700;
}

.further-msg .line {
	margin-left: -116px;
	margin-right: -116px;
}

#follow-order .further-msg p {
	max-width: 342px;
	text-align: center;
	margin: 34px auto 10px;
	color: #000000;
	line-height: 20px;
}

.further-msg a {
	padding: 13px;
	margin: auto;
	text-align: center;
	margin-top: 15px;
	width: fit-content;
	min-width: 308px;
	border-radius: 5px;
	font-size: 16px;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
}

/* checkout start*/
#checkout.back-page {
	background: linear-gradient(to right, #ffffff 57.9%, #f4f4f4 57.9%);
}

#checkout .card-sides {
	grid-template-columns: 57.9% 42.1%;
}

.left-cart > .checkout-left {
	padding: 82px 126px 59px 8px;
	max-width: 729px;
}

#checkout .left-cart h1 {
	margin: 0 0 32px;
}

.delivery-step {
	margin-top: 50px;
}

.delivery-step .checkbox-area {
	margin-bottom: 25px;
}

.address-select-list {
	margin-bottom: 30px;
}

#checkout .radio {
	margin-bottom: 32px;
}

.radio label {
	display: flex;
	align-items: center;
	margin-right: 35px;
	font-weight: 700;
	color: #000;
	cursor: pointer;
}

.radio input[type='radio'] {
	display: inline-block;
	appearance: none;
	width: 20px;
	height: 20px;
	background: #ffffff;
	outline: 1px solid #000;
	border: none;
	border-radius: 50%;
	flex-shrink: 0;
	margin: auto 7px auto 0;
}

.radio input[type='radio']:checked {
	border: 5px solid #ffffff;
	background: #000000;
}

#checkout .left-cart h4 {
	font-size: 14px;
	line-height: 16px;
	text-align: left;
	margin: 32px 0 15px;
}

#checkout .submit {
	padding-left: 33px;
	text-align: left;
	margin-top: 20px;
}

.step-number {
	width: 21px;
	min-width: 21px;
	height: 21px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	background-color: #ababab;
	border-radius: 100%;
	justify-content: center;
	margin-right: 11px;
}

.step-number.active {
	background-color: #000000;
}

header .checkout-header {
	justify-content: space-between;
	height: 75.6px;
}

.checkout-steps > div {
	max-width: 160px;
	font-size: 14px;
	line-height: 20px;
	color: #000000;
}

#checkout-failed-page img {
	margin-bottom: 32px;
}

#checkout-failed-page h2 {
	margin-bottom: 25px;
}

#checkout-failed-page .info-box-message > div {
	font-size: 14px;
	margin: 0 auto 40px;
	max-width: 384px;
	text-align: center;
}

.shadow-header {
	box-shadow: 0px 10px 30px #0000000a;
	border: none;
}

#safe-payment {
	border-radius: 50px;
	border: 1px solid #717171;
	padding: 10px 16.5px;
	color: #717171;
	font-size: 16px;
	line-height: 22px;
}

#safe-payment img {
	margin-right: 16px;
}

#checkout .right-cart > div {
	max-width: 384px;
}

#order-synopsis-checkout .cart-box {
	padding-bottom: 37px;
}

#order-synopsis-checkout .cart-items {
	gap: 18px;
}

#order-synopsis-checkout .cart-item {
	grid-template-columns: 78px 1fr;
	padding: 0;
	border: none;
}

#order-synopsis-checkout .cart-item h4 {
	margin-bottom: 8px;
}

#order-synopsis-checkout .cart-item .color {
	font-size: 12px;
	line-height: 13px;
}

#order-synopsis-checkout .cart-item .quantity {
	color: #717171;
}

#order-synopsis-checkout .cart-item .price {
	font-size: 16px;
}

#order-synopsis-checkout .cart-item-info span {
	font-weight: 400;
}

div.quant-col {
	justify-content: space-between;
	margin-bottom: 0;
}

#order-synopsis-checkout .order-synopsis {
	border-bottom: none;
	padding: 0;
	margin: 0;
	padding-top: 31px;
}

.line {
	border-top: 1px solid #f4f4f4;
	margin-top: 34px;
	margin-left: -51px;
	margin-right: -51px;
}

.step2-page .shipping-part .radio {
	gap: 19px;
}

#store-pickup-fields {
	margin: 13px 0;
}

#courier-fields {
	margin-top: 13px;
}

#courier-fields > div {
	gap: 24px;
}

#courier-fields label {
	position: relative;
	flex-direction: column;
	max-width: 180px;
	width: 100%;
	height: 100px;
	justify-content: space-between;
	padding: 16px 0 15px;
	color: #ababab;
	font-weight: 700;
	border-radius: 5px;
	box-shadow: 0px 4px 6px #00000029;
	margin: 0;
	text-align: center;
}

#courier-fields input {
	display: none;
}

#courier-fields input[type='radio']:checked + label {
	border: 1px solid #000;
	background-color: #f4f4f4;
	box-shadow: none;
}

#courier-fields .radio input[type='radio']:checked {
	border: 1px solid;
}

.payment-part label.radio-label {
	background-color: #fff;
	border: 2px solid #f4f4f4;
	padding: 0 27px 0 74px;
	border-radius: 5px;
	height: 80px;
	margin-right: 0;
	margin-bottom: 11px;
	font-weight: 400;
	line-height: 20px;
	font-size: 14px !important;
}

.payment-part li {
	position: relative;
}

.payment-part input[type='radio'] {
	position: absolute;
	left: 28px;
	top: 0;
	bottom: 0;
	margin: auto;
	appearance: none;
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	outline: 1px solid #c4c1bf;
	border-radius: 50%;
	display: block;
	z-index: 1;
	border: none;
	cursor: pointer;
}

.payment-part input[type='radio']:checked {
	background-color: #292929;
	border: 5px solid #f4f4f4;
	outline: 1px solid #000;
}

.payment-part input[type='radio'] + .radio-label:before,
.payment-part input[type='radio'] + .radio-label:after {
	content: none;
}

.payment-part input[type='radio']:checked + .radio-label {
	background-color: #f4f4f4;
}

.payment-part input[type='radio']:checked + .radio-label span {
	font-weight: 700;
}

.payment-part label.radio-label span {
	align-items: center;
}

.payment-part img {
	margin-right: 20px;
	mix-blend-mode: multiply;
}

.step3-page form {
	max-width: 491px;
}

#checkout.step3-page .submit {
	text-align: center;
	padding: 15px;
	min-width: 194px;
}

.step3-page .acceptance .input-label {
	margin-left: auto;
	width: fit-content;
}

#checkout .shipping-part .radio {
	margin-bottom: 45px;
}

#checkout .payment-part .radio {
	margin-bottom: 18px;
}

.order-completed-page.page {
	@media (max-width: 900px) {
		padding: 0px;
	}
}

.order-completed-page > div:first-child {
	padding-top: 25px;
	padding-bottom: 72px;
}

.order-completed-page h1 {
	font-size: 20px;
	line-height: 24px;
	color: #000;
}

.order-summary-content {
	max-width: 996px;
	background-color: #fff;
	padding: 49px 78px 55px 66px;
	margin: auto;
	box-shadow: 0px 10px 40px #a7a7a729;
	border-radius: 5px;
	color: #000;
}

.order-summary-content .notice {
	margin-top: 30px;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.order-summary-content .notice > div:first-child {
	max-width: 520px;
}

.order-summary-content .line {
	border-color: #eeeeee;
	margin-left: -66px;
	margin-right: -77px;
}

.order-summary-content button {
	padding: 15px;
	align-self: baseline;
}

.order-summary-content .notice .store-hours-checkout {
	padding-top: 30px;
}
.order-summary-content .notice .store-hours-checkout li {
	padding-bottom: 20px;
	font-size: 15px;
	display: flex;
	justify-content: space-between;
}

.message-success {
	max-width: 400px;
	margin: auto;
	text-align: center;
}

.message-success img {
	margin: auto;
}

.message-success .order-number {
	font-size: 20px;
	line-height: 28px;
	color: #000;
	margin: 32px auto;
}

ul.shipping-synopsis {
	margin-top: 48px;
}

.shipping-synopsis {
	grid-template-columns: repeat(2, 1fr);
	max-width: 600px;
	gap: 37px 20px;
}

.head-info {
	font-weight: 700;
	margin-bottom: 17px;
}
/*end check out*/
.title.flex-col h1 {
	font-size: 32px;
	line-height: 37px;
}

.homepage-blogs .title {
	justify-content: space-between;
	align-items: center;
	margin-bottom: 45px;
}

.homepage-blogs .embla {
	overflow: hidden;
	position: relative;

	@media (min-width: 768px) {
		margin-right: -27px;
	}
}

.homepage-insta .embla {
	overflow: hidden;
	position: relative;
}

.content-embla {
	max-width: 1440px;
	margin: auto;
	overflow: hidden;
}

.taggbox-container {
	max-width: 1460px;
	margin: auto;
}

.homepage-blogs {
	overflow: hidden;
	margin-bottom: 138px;

	@media (max-width: 575px) {
		margin-bottom: 20px;
	}
}

.homepage-blogs .embla__slide {
	position: relative;
	flex: 0 0 auto; /* Slide covers 80% of the viewport */
	max-width: 382px;
	margin-right: 27px;
	width: calc(25% - 27px);
	display: flex;
	justify-content: center;

	@media (max-width: 767px) {
		flex: 0 0 45%;
		margin: 0 5px;
		width: 50%;
		padding-left: 5px;
		justify-content: flex-start;
	}

	@media (max-width: 575px) {
		flex: 0 0 80%;
		width: 80%;
		padding-left: 10px;
	}
}

.homepage-blogs .embla:before {
	display: none;
	content: '{ "slidesToScroll": 1, "draggable": true}';
}

.homepage-blogs .embla_controls {
	padding: 0px 15px;
	top: calc(50% - 64px);
	pointer-events: none;
	max-width: 1280px;
	margin: auto;
	right: 0;

	@media (max-width: 1080px) {
		padding: 0 5%;
	}

	@media (max-width: 680px) {
		display: none;
	}
}

.white-back {
	background-color: #fff;
	border: 1px solid #000000;
}

.homepage-blogs .embla_controls .embla_control:hover,
.homepage-insta .embla_controls .embla_control:hover,
#personalised-products .embla_controls .embla_control:hover,
.stores-slider .embla_controls .embla_control:hover {
	width: 43px;
	padding: 0 15px;
	background-color: #000000;
}

.homepage-blogs .embla_controls .embla_control:hover svg path,
.homepage-insta .embla_controls .embla_control:hover svg path,
#personalised-products .embla_controls .embla_control:hover svg path,
.stores-slider .embla_controls .embla_control:hover svg path {
	stroke: #fff;
}

.homepage-blogs .embla_control,
.stores-slider .embla_controls .embla_control {
	pointer-events: auto;
}

.homepage-blogs .title a {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	position: relative;
	padding-right: 24px;
	transition: 0.3s ease-out;
}

.homepage-blogs .title a::after {
	content: '';
	position: absolute;
	background: url(../images/arrow-black.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 12px;
	height: 12px;
	right: 0;
	transition: 0.3s ease-out;
}

.homepage-blogs .title a:hover {
	padding-right: 26px;
}

.homepage-blogs .title a:hover:after {
	width: 18px;
	right: -5px;
}

.news-img {
	position: relative;
	border: 1px solid #000000;
	overflow: hidden;
	width: fit-content;
}

.news-box .news-img img {
	aspect-ratio: 1;
}

.category-label {
	position: absolute;
	font-size: 12px;
	line-height: 13px;
	color: #fff;
	background: #000;
	padding: 6.5px 10px;
	right: 0;
	bottom: 0;
	font-weight: 700;
}

.news-details {
	padding: 24px 15px 10px 0;
	max-width: 290px;
	white-space: normal;
}

.news-details a {
	color: #000;
}

.news-details h3 {
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 8px;
}

.news-img img {
	transition: 0.3s ease-in-out;
}

.news-box:hover img {
	transform: scale(1.1);
}

.homepage-insta .embla__slide {
	position: relative;
	flex: 0 0 33.333%;
	max-width: 404px;
}

.homepage-insta .embla_controls {
	padding: 0px 105px;
}

/*start news page*/

.back-news-page {
	background-color: #f4f4f4;
}

.news-sides {
	gap: 49px 20px;
}

.left-news-side {
	max-width: 791px;
}

.newslist {
	gap: 59px 27px;
	grid-template-columns: repeat(2, minmax(130px, 1fr));
}

.top-pad {
	padding-top: 49px;
	padding-bottom: 76px;
}

.right-news-side {
	margin-left: auto;
	max-width: 282px;
	width: 100%;
}

.cats-list {
	margin-bottom: 49px;
}

.cats-list ul li a {
	font-size: 14px;
	line-height: 16px;
	color: #000;
	font-weight: 700;
	padding: 14px 0;
	display: block;
	border-bottom: 1px solid #dbdbdb;
}

.cats-list ul li a span {
	color: #ababab;
}

.border-title {
	font-size: 16px;
	line-height: 18px;
	color: #000;
	padding: 14px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #000;
	display: block;
	text-align: center;
	margin-bottom: 19px;
}

.promoted-article {
	margin-bottom: 71px;
}

.promoted-article h1 {
	font-size: 30px;
	line-height: 38px;
}

.promoted-article .news-details {
	padding-top: 11px;
	max-width: unset;
}

.news-newsletter-area {
	background-color: #ffff;
	border-radius: 5px;
	padding: 30px 28px;
	margin-bottom: 30px;
}

.news-newsletter-area h4 {
	font-size: 16px;
	margin-bottom: 23px;
}

.news-newsletter-area .acceptance .input-label {
	margin-left: auto;
	width: fit-content;
	color: #000;
}

.news-newsletter-area .form-input {
	margin-bottom: 9px;
}

.news-newsletter-area .input-label a {
	color: #000;
}

.news-newsletter-area .arrow-button {
	min-width: unset;
	width: 54px;
	min-height: 48px;
	position: absolute;
	top: 0;
	right: 0;
}

.news-newsletter-area .arrow-button:after {
	right: 0;
	left: 0;
}

.news-newsletter-area input[type='email'] {
	padding-right: 54px;
}

.news-summary {
	display: none;
}

.back-news-page .news-summary {
	display: block;
	margin-top: 17px;
}

.entry-body {
	padding: 38.5px 0 64px;
	color: #000;
}

.entry-body h1 {
	font-size: 30px;
	line-height: 38px;
	margin-bottom: 15px;
}

.entry-info {
	border-top: 1px solid #000;
	margin-top: 27px;
	padding-top: 27px;
	line-height: 20px;
	font-size: 14px;
}

.entry-info p {
	margin: 0;
}

.related-news {
	padding: 90px 0 104px;
}

.related-news h2 {
	font-size: 24px;
	margin-bottom: 24px;
	color: #000;
	text-align: left;
}

.related-news .grid-col {
	grid-template-columns: repeat(3, minmax(245px, 1fr));
	gap: 25px;
	overflow: auto;
	white-space: nowrap;
	-ms-overflow-style: none;
	scrollbar-width: none;
	background-image: linear-gradient(to right, #f4f4f4, #f4f4f4), linear-gradient(to right, #f4f4f4, #f4f4f4),
		linear-gradient(to right, rgba(171, 171, 171, 0.25), rgba(255, 255, 255, 0)),
		linear-gradient(to left, rgba(171, 171, 171, 0.25), rgba(255, 255, 255, 0));
	background-position:
		left center,
		right center,
		left center,
		right center;
	background-repeat: no-repeat;
	background-size:
		20px 100%,
		20px 100%,
		10px 100%,
		10px 100%;
	background-attachment: local, local, scroll, scroll;
}

.related-news .grid-col::-webkit-scrollbar {
	display: none;
}

.news-entry {
	position: relative;
}

.share-buttons {
	position: sticky;
	top: 149px;
	display: grid;
	gap: 11px;
	margin-top: -180px;
	height: 100%;
}

.share-buttons button svg path {
	transition: 0.3s ease-out;
}

.share-buttons button:hover svg path {
	fill: #000;
}

.back-news-page #personalised-products {
	background-color: #fff;
	padding-top: 49px;
}
/*end news page*/

/*store finder google maps*/

#google-map {
	height: 750px;
	width: 100%;
	position: relative;
}

#google-map.active {
	height: 370px;
}

.store-box {
	padding: 28px 27px 30px 35px;
}

.store-box .form-input:after {
	content: '';
	position: absolute;
	top: 13px;
	right: 15px;
	background: url(../images/search-icon.svg) no-repeat center;
	width: 22px;
	height: 22px;
}

#google-map h1,
.back-store-page h1 {
	margin-bottom: 19px;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
}

#google-map .pin {
	position: relative;
	width: 0;
	height: 0;
	text-align: center;
	cursor: pointer;
}

#google-map .pin img {
	position: absolute;
	bottom: 0;
	right: -23px;
}

#google-map .pin-modal {
	display: none;
}

#google-map .pin-modal.active {
	display: block;
}

#google-map .search-overlay {
	position: absolute;
	top: 52px;
	left: 120px;
	width: 384px;
	background: #000;
	box-shadow: 0px 10px 30px #0000001a;
	border-radius: 5px;
	color: #fff;
}

#google-map .closest-shop {
	margin-bottom: 22px;
}

#google-map .info-maps {
	gap: 11px;
}

#google-map .info-maps .flex-col {
	gap: 9px;
	align-items: center;
}

#google-map .store-name {
	font-weight: 700;
	margin-bottom: 11px;
	justify-content: space-between;
}

#google-map .buttons {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid rgba(255, 255, 255, 0.5);
	margin: 0 -27px -30px -35px;
}

#google-map button {
	color: #fff;
	font-size: 14px;
	border-radius: 0;
}

#google-map .buttons button {
	width: 50%;
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 54px;
}

#google-map .store-full-info h1 {
	margin: 32px 0 24px;
	text-align: left;
}

#google-map .buttons button:first-child {
	border-right: 1px solid rgba(255, 255, 255, 0.5);
}

#google-map .buttons button:last-child {
	font-size: 16px;
	padding-right: 20px;
}

#google-map .store-full-info {
	display: none;
}

#google-map .store-full-info button {
	position: relative;
	padding-left: 20px;
}

#google-map .store-full-info button:before {
	content: '';
	position: absolute;
	background: url(../images/arrow-white.svg) no-repeat right;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 18px;
	height: 12px;
	left: -6px;
	transform: rotate(180deg);
	transition: 0.3s ease-out;
}

#google-map .more-info .title,
#google-map .more-info .closest-shop,
#google-map .more-info .buttons,
#google-map .more-info form,
#google-map .more-info .store-box > h1 {
	display: none;
}

#google-map .more-info .store-full-info {
	display: block;
}

.back-store-page {
	padding: 70px 0 140px;
	background-color: #f4f4f4;
}

.active-store {
	gap: 10px 24px;
	align-items: center;
}

.box-active-store {
	background-color: #fff;
	box-shadow: 0px 10px 30px #0000001a;
	border-radius: 5px;
	width: 100%;
}

.store-hours {
	max-width: 32%;
	padding: 41px 20px;
}

.back-store-page h1 {
	text-align: left;
	margin-bottom: 24px;
}

.store-hours > div {
	max-width: 194px;
	text-align: left;
	margin: auto;
}

.store-gallery {
	max-width: 792px;
	padding: 32px 8.5% 20px;
	position: relative;
}

.store-hours .hours {
	gap: 20px;
}

.store-hours .hours li {
	justify-content: space-between;
	gap: 10px;
}

.embla.stores-slider {
	overflow: hidden;
}

.embla.stores-slider img {
	width: 100%;
	margin: auto;
	object-fit: cover;
}

.stores-slider .embla__slide {
	position: relative;
	flex: 0 0 100%;
}

.stores-slider .embla_controls {
	padding: 0px 10%;
	top: -17px;
	left: 0;
	bottom: 0;
	margin: auto;
	height: fit-content;
	pointer-events: none;
}

.numb {
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	font-weight: 700;
	margin-top: 22px;
}

/*end store finder google maps*/
/*cart widget*/

@-webkit-keyframes widget-appear {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes widget-appear {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

#cart-widget .cart-widget {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	overflow: auto;
	background-color: #fff;
	/*    max-width: calc(487px + (100vw - 1200px)/2);*/
	max-width: 606px;
	width: 100%;
	padding: 80px 120px 20px 60px;
	z-index: 2004;
	box-shadow: 0px 10px 30px rgb(0 0 0 / 16%);
	-webkit-animation: widget-appear 0.5s ease-in-out;
	animation: widget-appear 0.5s ease-in-out;
}

#cart-widget .cart-items {
	gap: 30px;
	margin-bottom: 0;
	/*  position: relative;
    z-index: 1;*/
	/* max-height: 60%;*/
	padding-right: 5px;
	/* overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(50% 0, farthest-side, #00000010, rgba(0,0,0,0)), radial-gradient(50% 100%,farthest-side, #00000010, rgba(0,0,0,0)) 0 100%;
    background: linear-gradient(white 30%, rgba(255,255,255,0)), linear-gradient(rgba(255,255,255,0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, #00000010, rgba(0,0,0,0)), radial-gradient(farthest-side at 50% 100%, #00000010, rgba(0,0,0,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;*/
}

#cart-widget .cart-items::-webkit-scrollbar {
	display: none;
}

#cart-widget .cart-item {
	padding-bottom: 30px;
}

#cart-widget h2 {
	text-align: left;
	margin-bottom: 36px;
}

.sticky-cart-total {
	position: sticky;
	bottom: 0;
	z-index: 1;
	background-color: #fff;
	padding-top: 18px;
	border-top: 1px solid #ababab;
}

#cart-widget .total,
#cart-widget .buttons-cart {
	justify-content: space-between;
	gap: 18px 10px;
}

.simpler-btn {
	margin-top: 18px;
}

#cart-widget .buttons-cart {
	margin: 30px auto;
}

#cart-widget .buttons-cart button:first-child {
	padding: 14px;
	border: 1px solid #000000;
	min-width: 180px;
	transition: 0.3s ease-out;
}

#cart-widget .buttons-cart button:first-child:hover {
	color: #fff;
	background-color: #000;
}

#cart-widget .transparent-side {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: transparent;
	z-index: 2004;
}

/*end cart widget*/

/*menu extras*/

#menu-modal nav::-webkit-scrollbar {
	width: 3px; /* width of the entire scrollbar */
	height: 100%;
}

#menu-modal nav::-webkit-scrollbar-track {
	background: #fff; /* color of the tracking area */
	border-radius: 23px;
}

#menu-modal nav::-webkit-scrollbar-thumb {
	background-color: #ababab; /* color of the scroll thumb */
	border-radius: 23px; /* roundness of the scroll thumb */
	border: 3px solid #ababab; /* creates padding around scroll thumb */
}

/*end menu extras*/

/*search extras*/

.exit-search {
	max-width: 790px;
	margin: auto;
	height: 20px;
	position: absolute;
	right: 0;
	top: 54px;
	cursor: pointer;
	display: block;
	width: 100%;
	text-align: end;
	left: 0;
	pointer-events: none;
}

.search-results .searchbar {
	position: relative;
	max-width: 790px;
	margin: 0 auto 60px;
}

.search-results input.searchbar-input {
	width: 100%;
	padding: 1.5% 1.5% 9px 43px;
	background: none;
	border-bottom: 1px solid black !important;
	border-radius: 0;
	height: unset;
}

.search-results input.searchbar-input:focus-visible,
.search-results input.searchbar-input:focus {
	border: none;
}

.search-results .searchbar::before {
	content: '';
	position: absolute;
	background: url('../images/search-icon.svg') no-repeat center;
	left: 0;
	bottom: 13px;
	width: 22px;
	height: 22px;
}

.search-results .cat-list ul {
	margin-bottom: 10px;
}
/*end search extras*/
/*footer extras*/
.footer-clmns {
	gap: 24px;
	justify-content: space-between;
	width: 50%;
}

.payment-methods div:last-child {
	padding: 0 20px 0 6%;
	white-space: nowrap;
	display: grid;
	grid-template-columns: 1fr 1fr;
	place-items: end center;
}

input.add-email::-webkit-input-placeholder {
	opacity: 1;
}

input.add-email::-ms-input-placeholder {
	opacity: 1;
}

input.add-email::placeholder {
	opacity: 1;
}

/*end footer extras*/

/*cookies consent*/
#cookie-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2010;
	width: 100%;
	background-color: #363636;
	color: #fff;
	padding: 20px;
	border-radius: 8px 8px 0 0;
	font-size: 14px;

	.content-wrapper {
		max-width: 880px;
	}
}
@media (max-width: 1090px) {
	#cookie-banner:has(.cookies-categories) {
		height: 100%;
		max-height: 550px;
		overflow: auto;
	}
}
#cookie-banner hr {
	margin: 15px 0;
	height: 0;
	border-width: 1px 0 0;
	border-style: solid;
	border-color: #707070;
}
#cookie-banner .buttons {
	display: flex;
	justify-content: space-between;
}
@media (max-width: 485px) {
	#cookie-banner .buttons {
		flex-direction: column;
		gap: 10px;
	}
}
#cookie-banner button {
	border: 1px solid #bbb;
	color: #bbb;
	border-radius: 4px;
	padding: 11px 23px;
	font-size: 14px;
}
#cookie-banner button:hover,
#cookie-banner button:active,
#cookie-banner button:focus {
	border-color: #bbb;
	background: #2c2c2c;
}
.cookies-categories .explanation {
	padding: 10px;
}
.cookies-categories .explanation h3 {
	font-size: 16px;
}
.cookies-categories .explanation p {
	margin: 0 0 0px 0;
	padding-top: 5px;
}
.cookies-categories .header {
	padding: 10px;
	border-bottom: 1px solid #fff;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
	font-size: 16px;
}
.cookies-categories .options-wrapper {
	margin-top: 5px;
}
.cookies-categories .options-wrapper .option {
	padding: 10px;
	border-bottom: 1px solid #f3f3f3;
	display: flex;
	justify-content: space-between;

	input[type='checkbox'] {
		display: block;
	}
	details > p {
		color: #bbb;
	}
}
.cookies-categories .options-wrapper .option .description {
	width: 100%;
}
.cookies-categories p {
	font-weight: 500;
	color: #f3f3f3;
	padding: 10px 0px 15px 0;
}
.cookies-categories .footer {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.buttons {
		gap: 20px;
	}

	.buttons .agree {
		background-color: #fff;
		color: #000 !important;
	}
	.buttons .agree:hover {
		border-color: #bbb;
		background: #fff !important;
		opacity: 0.7 !important;
	}
}
@media (max-width: 600px) {
	.cookies-categories .footer {
		margin-top: 10px;
		display: block;
	}
}
/*end cookies consent*/

.notfound .main-categories-banner .category-wrapper {
	position: relative;
}

.notfound .main-categories-banner .category-wrapper img {
	width: 100%;
}
/*mobile media*/
@media (min-width: 901px) {
	.mobile901 {
		display: none !important;
	}
}

@media (min-width: 641px) {
	.mobile641 {
		display: none;
	}
}

@media (max-width: 1440px) {
	.right-side {
		margin-right: 0;
	}

	.banner-cat > div:after {
		right: 15px;
	}

	.product-filters {
		padding-right: 120px;
		width: 100%;
		max-width: 402px;
	}
}

@media (max-width: 1200px) {
	.further-msg .line {
		margin-left: -50px;
		margin-right: -50px;
	}

	.right-cart > div {
		max-width: 400px;
	}

	#cart-widget .cart-widget {
		padding-right: 15px;
	}

	body.cart-open {
		margin-left: -487px;
	}

	#cart-widget .cart-widget {
		max-width: 487px;
		padding: 80px 25px 20px;
	}
}

@media (max-width: 1024px) {
	.account-page .content-wrapper > .grid-col {
		grid-template-columns: 230px 1fr;
	}

	.address-list-headers > ul,
	.address-item > ul {
		padding-left: 3%;
	}

	.left-cart > .checkout-left {
		padding-right: 70px;
	}

	#checkout .right-cart > div {
		max-width: 340px;
	}

	.masonry-pictures {
		gap: 13px;
	}

	.wishlist-items {
		gap: 30px 15px;
	}

	.store-gallery {
		padding: 32px 5% 20px;
	}

	.stores-slider .embla_controls {
		padding: 0 5.5%;
	}

	.exit-search {
		max-width: unset;
		width: 76%;
		top: 50px;
	}
}

@media (max-width: 1100px) {
	.footer-bottom-1 .content-wrapper {
		flex-direction: column;
		gap: 30px 0;
		align-items: center;
	}
}

@media (max-width: 900px) {
	.mobile900 {
		display: none !important;
	}

	.account-page .content-wrapper > .grid-col {
		grid-template-columns: unset;
		margin: 0;
	}

	.account-page .content-wrapper {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.navigation-page h1 {
		padding: 25px 22px !important;
		background-color: #fff;
		font-size: 20px;
		line-height: 24px;
		text-align: left;
		font-weight: 700;
	}

	.log-out-sec {
		padding: 37px 22px 50px;
	}

	.sidebar a {
		font-weight: 700;
		padding: 20px 5px 20px 22px;
		border-bottom: 1px solid #dddddd;
		margin-bottom: 0;
	}

	.head-account {
		position: relative;
		padding: 0 42px;
	}

	.head-account > button {
		width: 20px;
		height: 20px;
		transform: rotate(180deg);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 22px;
		margin: auto;
		padding: 0;
	}

	.back-page,
	#checkout.back-page {
		background: #f4f4f4;
	}

	.back-page .content-wrapper {
		padding: 0;
	}

	.card-sides,
	#checkout .card-sides {
		grid-template-columns: 1fr;
	}

	.left-cart {
		background-color: #fff;
	}

	.left-cart,
	.right-cart {
		padding: 0 15px;
	}

	.left-cart > div {
		padding: 35px 0 40px;
	}

	.left-cart > div {
		max-width: unset;
	}

	.right-cart > div,
	#checkout .right-cart > div {
		max-width: 588px;
		margin: auto;
	}

	.left-cart h1 {
		margin: 0;
		text-align: center;
	}

	.cart-box h1 {
		text-align: center;
	}

	.left-cart .head-account {
		margin-bottom: 35px;
	}

	.left-cart > .checkout-left {
		padding: 120px 0 26px;
		margin: auto;
		max-width: 588px;
	}

	.horizontal-line {
		border-top: 1px solid #000000;
		width: 64px;
		margin-top: 10px;
	}

	.step-number {
		margin-bottom: 15px;
	}

	header .checkout-header {
		box-shadow: 0 10px 30px #00000029;
		height: 50px;
	}

	#safe-payment {
		border: none;
		font-size: 14px;
		padding: 0;
	}

	.shadow-header {
		box-shadow: none;
	}

	header .content-wrapper.mobile901 {
		height: 80px;
	}

	.step3-page form {
		margin: auto;
	}

	.left-cart #checkout-step-3-wrapper h1 {
		text-align: left;
	}

	.step-final-header .shadow-header {
		background: #f4f4f4;
	}

	.step-final-header header .checkout-header {
		background-color: #fff;
	}

	.message-success {
		margin-top: 60px;
	}

	.order-summary-content .notice {
		flex-direction: column;
		align-items: unset;
	}

	.order-summary-content button {
		width: auto;
		margin-left: auto;
	}

	.wishlist-page .page-title {
		flex-direction: column;
		position: relative;
		padding: 24px 0;
	}

	.page-header-wishlist {
		background-color: #fff;
	}

	.wishlist-page > .content-wrapper.body {
		padding-top: 20px;
		padding-bottom: 82px;
	}

	.wishlist-page .page-title > div {
		margin: 24px auto 0;
		padding: 0;
	}

	.wishlist-page .page-title > button {
		margin: 3px 0 0;
		width: unset;
		position: absolute;
	}

	.edit-wishlist button {
		margin: auto;
	}

	.product-list {
		grid-template-columns: repeat(3, minmax(160px, 282px));
	}

	.one-wishlist h1 {
		margin: auto;
		padding-left: 16px;
	}

	.wishlist-page.one-wishlist .page-title {
		flex-direction: unset;
	}

	.wishlist-page.one-wishlist .page-title > div {
		margin: 0;
		width: unset;
	}

	.edit-wishlist img {
		margin: 0;
	}

	.embla_pers .embla__slide {
		flex: 0 0 32%;
	}

	.box-empty-list {
		margin-bottom: 0;
	}

	#footer-top .footer-level-1 {
		flex-direction: column;
		max-width: 600px;
		gap: 58px 0;
	}

	#footer-top .footer-level-1 .newsletter-area,
	.footer-clmns {
		width: 100%;
	}

	#footer-top .footer-level-1 .newsletter-area * {
		max-width: unset;
	}

	#footer-top .footer-level-1 .newsletter-area .newsletter-box {
		margin-bottom: 25px;
	}

	#footer-top .footer-level-1 .newsletter-area .social-links {
		justify-content: end;
	}

	.footer-bottom-1 .payment-methods {
		flex-wrap: wrap;
		margin-right: 0;
	}

	#footer-top {
		padding: 33px 0 40px;
	}

	.footer-bottom-2 .content-wrapper {
		justify-content: center;
		line-height: 19px;
		flex-wrap: wrap;
	}

	.payment-methods div:last-child {
		display: flex;
		padding: 0 15px;
		white-space: normal;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	#footer-top-boxes {
		flex-direction: column;
	}

	#footer-top-boxes .footer-box .box-title {
		font-size: 16px;
		line-height: 18px;
	}

	#footer-top-boxes .footer-box {
		width: 100%;
	}

	.news-sides {
		flex-direction: column;
	}

	.left-news-side {
		margin: auto;
	}

	.right-news-side {
		margin: auto;
		max-width: 400px;
	}

	.promoted-article h1,
	.entry-body h1 {
		font-size: 24px;
	}

	.share-buttons {
		display: flex;
		position: unset;
		margin: 15px 0 0;
	}

	.entry-info {
		margin-top: 8px;
		padding-top: 21px;
	}

	.banner-cat > div {
		flex-direction: column;
	}

	.right-side img {
		max-width: 160px;
	}

	.banner-cat > div:after {
		top: unset;
		bottom: -16px;
		width: 71px;
		height: 193px;
	}

	.cat-list h1 {
		text-align: center;
		margin-bottom: 31px;
	}

	#contact .cms-content {
		flex-direction: column;
		padding-bottom: 80px;
	}

	#contact .form-area {
		margin: -43px auto 0;
	}

	#contact .info-area {
		max-width: 600px;
		margin: auto;
		width: 100%;
	}

	#contact .info-area .content-wrapper {
		padding: 0;
		display: flex;
		justify-content: space-between;
		gap: 60px 20px;
	}

	#contact .info-area .content-wrapper h2 {
		margin-top: 0;
	}

	.homepage-blogs .title.flex-col h1 {
		font-size: 24px;
	}

	.homepage-blogs .title {
		margin-bottom: 30px;
	}

	.notfound .content-wrapper h1 {
		font-size: 24px;
		line-height: 28px;
	}

	.notfound .content-wrapper .main-categories-banner {
		flex-direction: column;
	}

	.notfound .content-wrapper p {
		margin: 17px 0 23px;
		font-size: 14px;
	}
	.notfound .content-wrapper .backtohome-button {
		font-size: 14px;
		margin-bottom: 23px;
	}
}

@media (max-width: 750px) {
	.active-store {
		flex-direction: column;
	}

	.store-hours {
		max-width: 100%;
		padding: 38px 20px 51px;
	}

	.store-hours > div {
		max-width: 239px;
	}

	.store-gallery {
		padding: 32px 30px 20px;
	}

	.stores-slider .embla_controls {
		padding: 0 15px;
	}
}

@media (max-width: 640px) {
	.desktop641 {
		display: none;
	}

	button {
		font-size: 14px;
		line-height: 16px;
	}

	.arrow-button:after {
		width: 9px;
		height: 9px;
	}

	.address-item > ul {
		grid-template-columns: unset;
		height: unset;
		gap: 0;
		min-height: 220px;
		padding: 27px 32px;
	}

	.address-item > ul li {
		font-size: 14px;
	}

	.ordhist-item .prods > ul li:first-child {
		margin-left: 0;
	}

	.address-list li.controls {
		justify-self: unset;
		grid-template-columns: 1fr 1fr;
		gap: 10px;
		margin-top: 28px;
	}

	.address-list li.controls button {
		border: 1px solid #000;
		min-height: 45px;
		padding: 5px;
	}

	.add-address button {
		padding: 12px 16px 12px 22px;
		font-size: 14px;
	}

	.address-list li.controls button:last-child {
		background-color: #000;
		color: #ffffff;
	}

	.address-list li.controls button span {
		font-size: 14px;
		line-height: 16px;
	}

	.ordhist-item {
		position: relative;
	}
	.ordhist-item .controls {
		position: absolute;
		top: 90px;
		right: 10px;
	}

	.head-account {
		border-bottom: 1px solid #f4f4f4;
		padding: 23px 42px 16px;
	}

	.account-page .main-content {
		padding: 0;
	}

	.address-list {
		padding: 24px 22px 6px;
	}

	.address-item {
		margin-bottom: 20px;
	}

	.add-address {
		padding-right: 22px;
		padding-bottom: 64px;
	}

	.address1 {
		margin-top: 21px;
	}

	.my-account-form {
		max-width: unset;
	}

	h1.acc-title {
		margin-bottom: 0;
	}

	.account-page .main-content:nth-child(2) h1.acc-title {
		border: none;
		margin-bottom: 0;
		padding-bottom: 23px;
	}

	.profile-page .main-content:first-child .head-account {
		margin-bottom: 24px;
	}

	.form-content {
		padding: 0 22px 48px;
	}

	.profile-page .center2 {
		box-shadow: 0px 10px 30px #00000029;
	}

	.profile-page .main-content {
		box-shadow: none;
	}

	.account-page .main-content:nth-child(2) {
		margin: 0;
	}

	.right-sit {
		margin-top: 22px;
		padding: 13.5px 15px;
	}

	.form-input {
		margin-bottom: 12px;
	}
	.password-input {
		margin-bottom: 45px !important;
	}

	.ordhist-item {
		margin-bottom: 15px;
	}

	.order-list + .address-list {
		padding-bottom: 59px;
	}

	.ordhist-item > ul {
		padding: 25px 17px;
		grid-template-columns: 1fr 1fr;
		min-height: 170px;
	}

	.ordhist-item .status {
		grid-column: span 1;
		margin-top: 10px;
		font-size: 14px;
		line-height: 14px;
		order: 1;
	}

	.ordhist-item .total {
		order: 2;
		text-align: right;
	}

	.ordhist-item .prods {
		grid-column: span 2;
		margin-top: 22px;
		order: 3;
	}

	.address-item.ordhist-item > ul li:nth-child(2) {
		justify-self: end;
	}

	.ordhist-item .prods > ul {
		justify-content: start;
	}

	#follow-order form {
		padding: 0 22px 150px;
	}

	#follow-order p {
		margin: 18px auto;
		padding: 0 22px;
	}

	#follow-order .further-msg p {
		margin: 58px auto 24px;
	}

	.further-msg a {
		font-size: 14px;
		padding: 11.5px 18px;
		min-width: unset;
		max-width: 290px;
		width: 100%;
		gap: 10px;
	}

	.order-status-item li.flex-col {
		flex-direction: column;
	}

	.order-status-item {
		align-items: start;
		gap: 21px;
	}

	.order-status-item:not(:first-child) li.img:after {
		border-top: 60px solid #ababab;
	}

	.order-status-item li.flex-col span {
		margin: 0 0 0 24px;
		font-size: 0px;
	}

	.order-status-item li.flex-col > div {
		line-height: 16px;
	}

	.address-add-page .inputs,
	.inputs {
		grid-template-columns: 1fr;
	}

	.address-add-page .form-content {
		padding-top: 24px;
	}

	.left-cart .head-account {
		margin-bottom: 19px;
		border: none;
	}

	.left-cart > div {
		padding: 3px 0 30px;
	}

	.right-cart > div {
		padding: 26px 0 34px;
	}

	.cart-box button,
	form button {
		padding: 13.5px 15px;
	}

	.arrow-button {
		min-height: 45px;
	}

	.left-cart #checkout-step-3-wrapper .payment-part h1 {
		margin-bottom: 19px;
	}

	#checkout .shipping-part .radio {
		margin-bottom: 40px;
	}

	#courier-fields > div {
		gap: 10px;
	}

	#courier-fields label {
		max-width: 160px;
		height: 90px;
		padding: 14px 0;
		font-size: 12px;
		line-height: 15px;
	}

	.payment-part label.radio-label {
		padding: 0 16px 0 58px;
	}

	.payment-part img {
		margin-right: 28px;
	}

	.payment-part input[type='radio'] + .radio-label:before {
		left: 17px;
	}

	.payment-part input[type='radio']:checked + .radio-label:after {
		left: 22px;
	}

	.shipping-synopsis {
		grid-template-columns: 1fr;
	}

	.order-completed-page > div:first-child {
		padding-bottom: 32px;
	}

	.wishlist-items {
		grid-template-columns: minmax(150px, 440px);
		justify-content: center;
	}

	.product-list {
		grid-template-columns: repeat(2, minmax(120px, 282px));
		justify-content: center;
		gap: 24px 10px;
	}

	.prod-details {
		padding: 10px 0;
	}

	.prod-details > .flex-col {
		gap: 8px;
		flex-direction: column;
		text-align: left;
	}

	.prod-price {
		font-size: 14px;
	}

	.prod-details > .flex-col > div {
		max-width: unset;
	}

	.prod-details h4 {
		margin-bottom: 10px;
	}

	.wishlist-page.one-wishlist > .content-wrapper.body {
		padding-bottom: 42px;
	}

	.embla_pers .embla__slide {
		flex: 0 0 49%;
		margin-right: 10px;
	}

	#personalised-products h3,
	.related-news h2 {
		font-size: 20px;
	}

	.guest-register-content main {
		max-height: unset;
	}

	.register-wrapper > .grid-col {
		grid-template-columns: 1fr;
	}

	.register-wrapper {
		max-width: 384px;
	}

	.login-modal main {
		min-height: unset !important;
		max-height: unset !important;
		padding: 69px 18px 100px;
		bottom: unset;
		height: 100%;
	}

	.register-wrapper > .grid-col button {
		min-width: 261px;
		width: auto;
		margin: 20px auto 0;
	}

	.first-col-reg form {
		text-align: center;
	}

	.modal-content {
		padding: 0 !important;
		height: 100%;
		max-width: unset;
		border-radius: unset;
	}

	.modal-content h2 {
		padding: 14px 22px;
		box-shadow: 0 10px 30px #00000029;
		justify-content: unset;
		font-size: 18px;
		margin-top: 0;
	}

	.wish-info {
		padding: 16px 22px 32px;
	}

	.add-new-wish p {
		margin-bottom: 28px;
	}

	.wish-info form {
		max-width: 500px;
		margin: auto;
	}

	.wish-btn {
		text-align: center;
		padding: 13.5px 15px;
	}

	button.arrow-button.submit.wish-btn:after {
		content: none;
	}

	.extra-del:before {
		right: -22px;
		left: -22px;
	}

	p.edit-wish-info {
		text-align: center;
		margin: 14px auto 35px;
	}

	.extra-del {
		margin-top: 53px;
		padding-top: 42px;
	}

	.extra-del button {
		margin: auto;
		width: 100%;
	}

	.extra-del p.edit-wish-info {
		margin: 0 auto 20px;
	}

	.wishlists-list {
		padding: 0;
	}

	.new-wishlist-addition p.mobile641 {
		margin-bottom: 46px;
	}

	.modal-content .add-address {
		margin-bottom: 0;
		padding: 15px 0;
	}

	.promoted-article h1,
	.entry-body h1 {
		font-size: 20px;
		margin-bottom: 0;
	}

	.news-details h3 {
		font-size: 16px;
		line-height: 22px;
	}

	.back-news-page .news-summary {
		font-size: 12px;
		margin-top: 5px;
	}

	.newslist {
		gap: 31px 12px;
	}

	.promoted-article {
		margin-bottom: 50px;
	}

	.banner-cat {
		padding: 18px 0 40px;
	}

	.cat-list {
		padding: 28px 0 62px;
	}

	.show-more-prods {
		margin-top: 40px;
	}

	.see-more {
		font-size: 14px;
		line-height: 15px;
	}

	.right-side {
		gap: 10px;
	}

	.product-filters {
		max-width: unset;
		padding: 0 0 80px;
	}

	.header-filters {
		margin: 0 0 33px;
		padding: 16px 21px 16px 16px;
		box-shadow: 0 10px 30px #00000029;
	}

	.filters-list {
		padding: 0 42px 81px;
		height: 80%;
	}

	.product-filters div.flex-col {
		margin-bottom: 12px;
	}

	.product-filters h2 {
		font-size: 16px;
		line-height: 18px;
	}

	.size-filter {
		grid-template-columns: repeat(3, minmax(50px, 90px));
	}

	.footer-filters {
		padding: 18px 20px;
		height: auto;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		box-shadow: 0 -10px 30px #00000029;
		z-index: 2;
		background-color: #fff;
	}

	.see-results {
		margin: 0 auto 0;
		padding: 14px 22px;
		background-color: #000;
		color: #fff;
	}

	#google-map {
		padding-top: 150px;
	}

	#google-map.active {
		height: 750px;
	}

	#google-map .search-overlay {
		top: 0;
		left: 0;
		width: 100%;
	}

	#google-map .store-full-info h1,
	#google-map h1,
	.back-store-page h1 {
		font-size: 18px;
		line-height: 18px;
	}

	#contact .form-area .form-container form .form-title {
		font-size: 18px;
	}

	#info-page > .content-wrapper {
		padding: 0;
	}

	#info-page > .content-wrapper .main-content {
		width: 100%;
		background-color: #f4f4f4;
		padding: 0 0 100px;
	}

	#info-page > .content-wrapper .main-content > .content-wrapper:nth-child(2) {
		background-color: #fff;
		margin-bottom: 28px;
	}

	#info-page > .content-wrapper .main-content .page-heading {
		padding: 45px 0 15px;
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 0;
	}
}

@media (max-width: 575px) {
	.content-wrapper {
		padding: 0 22px;
	}

	.top-pad {
		padding-top: 26px;
		padding-bottom: 59px;
	}
}

@media (max-width: 480px) {
	#main-wrapper,
	.main-wrapper {
		margin-top: 56px;
	}

	.page {
		padding-top: 56px;
	}

	.account-page h1,
	.navigation-page h1,
	.cart-box h1,
	.left-cart h1,
	.order-completed-page h1,
	.wishlist-page h1,
	#contact .form-area .form-container form .form-title,
	#info-page > .content-wrapper .main-content .page-heading {
		font-weight: 700;
		font-size: 16px;
		line-height: 18px;
	}

	.account-page .content-wrapper {
		padding: 0;
	}

	.right-sit {
		width: 100%;
	}

	.left-cart {
		padding: 0;
	}

	.right-cart {
		padding: 0 22px;
	}

	.cart-item {
		padding: 0 22px 30px;
		grid-template-columns: 75px 1fr auto;
		border-bottom-color: #f4f4f4;
	}

	.cart-item .color,
	.cart-item .dimension {
		font-size: 12px;
	}

	.cart-item > .flex-col {
		padding-left: 10%;
	}

	.cart-box h1 {
		margin-bottom: 16px;
	}

	.cart-box {
		padding: 21px 22px 30px;
	}

	.order-synopsis {
		margin-bottom: 20px;
		border: none;
	}

	.cart-ship-info li {
		font-size: 12px;
		line-height: 20px;
	}

	.cart-item h4 {
		margin-bottom: 9px;
	}

	#checkout .left-cart {
		padding-right: 22px;
		padding-left: 22px;
	}

	textarea {
		padding: 22px 19px;
	}

	#checkout .submit {
		text-align: center;
		margin-top: 12px;
	}

	#checkout .left-cart h4 {
		margin: 26px 0 20px;
	}

	#order-synopsis-checkout .cart-box {
		padding: 21px 21px 42px 13px;
	}

	#order-synopsis-checkout .line {
		margin-left: -13px;
		margin-right: -21px;
	}

	#order-synopsis-checkout .cart-items {
		gap: 26px;
	}

	#order-synopsis-checkout .cart-item {
		grid-template-columns: 75px 1fr;
	}

	#order-synopsis-checkout .cart-item > .flex-col {
		padding-left: 0;
	}

	.checkout-steps > div {
		font-size: 12px;
	}

	#checkout .radio {
		margin-bottom: 26px;
		flex-direction: column;
		gap: 15px;
	}

	.step3-page .acceptance .input-label {
		margin-top: 28px;
		margin-bottom: 25px;
		margin-left: 0;
	}

	#safe-payment {
		font-size: 12px;
	}

	#safe-payment img {
		margin-right: 9px;
		max-width: 14px;
	}

	header .checkout-header #checkout-logo img {
		max-width: 75px;
	}

	.order-completed-page h1 {
		text-align: center;
	}

	ul.shipping-synopsis {
		margin-top: 42px;
		margin-left: 6px;
	}

	.order-summary-content {
		padding: 21px 25px 45px;
	}

	.head-info {
		margin-bottom: 8px;
	}

	.order-summary-content .line {
		display: none;
	}

	.order-summary-content .notice {
		margin-top: 42px;
	}

	.order-summary-content button {
		width: 100%;
		margin: auto;
	}

	.message-success .order-number {
		margin: 26px auto 74px;
		font-size: 16px;
		line-height: 24px;
	}

	.masonry-pictures {
		height: 215px;
	}

	.masonry-pictures > div:first-child img {
		max-height: 215px;
	}

	.masonry-pictures .grid-col {
		gap: 11px;
	}

	.masonry-pictures .grid-col div img {
		max-height: 102px;
	}

	.masonry-pictures .grid-col .no-image {
		min-height: 102px;
	}

	.wishlist-page .name {
		margin-top: 16px;
	}

	.wishlist-page .add-address button {
		min-width: 204px;
		margin: auto;
	}

	.product-box .add-wish {
		top: 0px;
		right: -1px;
	}

	.prod-details h4 {
		font-size: 12px;
		line-height: 16px;
	}

	#personalised-products h3,
	.related-news h2 {
		font-size: 16px;
		line-height: 18px;
		margin-bottom: 24px;
	}

	#personalised-products {
		padding-top: 38px;
		padding-bottom: 20px;
	}

	.box-empty-list {
		padding: 34px 31px;
	}

	.box-empty-list p {
		margin-top: 12px;
	}

	.login-modal li button {
		min-width: 164px;
		margin-bottom: 25px;
		font-size: 14px;
	}

	.login-modal ul,
	.login-alter > div {
		gap: 10px;
	}

	#login-wrapper,
	.register-wrapper {
		max-width: 338px;
	}

	.simple-link {
		margin-top: 10px;
	}

	#login-form button.submit {
		margin-top: 42px;
	}

	#account-info .grid-col.gender-sec {
		margin: 27px 0 33px;
	}

	.grid-col.gender-sec {
		gap: 27px 25px;
	}

	.accept-sec {
		gap: 12px;
		margin-bottom: 12px;
	}

	.login-alter div > button {
		width: 164px;
	}

	#account-info button {
		min-width: 164px;
	}

	.info-box-message {
		padding: 40px 30px;
	}

	.info-box-message strong {
		font-size: 16px;
		line-height: 20px;
	}

	.info-box-message > div {
		font-size: 14px;
	}

	.info-box-message img {
		margin-bottom: 25px;
	}

	.info-box-message button {
		width: 100%;
	}

	.space-arround {
		padding: 70px 0 100px;
	}

	#footer-top .footer-level-1 .footer-menu-clmn {
		gap: 25px 0;
	}

	#footer-top .footer-level-1 .footer-menu-clmn a {
		font-size: 12px;
	}

	.footer-clmns {
		flex-wrap: wrap;
		justify-content: unset;
	}

	.footer-bottom-1 .partners-area {
		flex-wrap: wrap;
		gap: 20px;
		justify-content: center;
	}

	.partner-box:first-child img {
		// max-width: 64px;
	}

	.partner-box:last-child img {
		max-width: 125px;
	}

	.footer-bottom-2 {
		padding: 25px 0 24px 0;
	}

	#footer-top .footer-level-1 .newsletter-area .newsletter-title {
		font-size: 30px;
	}

	#footer-top .footer-level-1 .newsletter-area .newsletter-text {
		font-size: 12px;
		line-height: 16px;
	}

	#footer-top .footer-level-1 .newsletter-area input::placeholder,
	#footer-top .footer-level-1 .newsletter-area input {
		font-size: 12px;
	}

	.subscription .add-email {
		padding: 0 14px;
	}

	.subscription .submit-email {
		width: 48px;
	}

	.subscription .submit-email .before-submit {
		font-size: 0;
		background-image: url(../images/arrow-white-mobile.svg);
		width: 9px;
		background-repeat: no-repeat;
		background-position: center;
		right: 0;
		margin: auto;
	}

	.footer-bottom-1 .content-wrapper {
		margin-bottom: 35px;
	}

	#footer-top .footer-level-1 .newsletter-area .social-links {
		justify-content: center;
		width: 100%;
	}

	#footer-top .footer-level-1 {
		gap: 36px 0;
	}

	.payment-methods div:last-child svg {
		max-width: 110px;
	}

	.payment-methods div:last-child {
		padding: 0;
	}

	#user-login {
		display: none;
	}

	header #logo {
		left: 0;
		right: 0;
		width: fit-content;
		margin: auto;
		top: 0;
		bottom: 0;
		height: fit-content;
	}

	header .content-wrapper {
		height: 55px;
	}

	.modal-content h2 {
		font-size: 16px;
	}

	.mini-wishlist {
		gap: 10px;
	}

	.info-wishlist .name {
		margin-bottom: 7px;
		font-size: 14px;
		line-height: 16px;
	}

	.promoted-article h1,
	.entry-body h1 {
		font-size: 16px;
		line-height: 24px;
	}

	.promoted-article .news-desc {
		font-size: 14px;
		line-height: 20px;
	}

	.news-details h3 {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 5px;
	}

	.news-desc {
		font-size: 12px;
		line-height: 20px;
	}

	.news-desc span {
		display: block;
	}

	.back-news-page .news-summary {
		display: none;
	}

	.news-details,
	.promoted-article .news-details {
		padding: 42px 10px 10px 0;
	}

	.news-img {
		overflow: unset;
	}

	.news-box:hover img {
		transform: unset;
	}

	.category-label {
		bottom: -34px;
		left: 0;
		right: unset;
	}

	.promoted-article {
		margin-bottom: 27px;
	}

	.cats-list {
		margin-bottom: 59px;
	}

	.news-newsletter-area {
		padding-bottom: 53px;
	}

	.entry-body {
		padding-bottom: 0;
	}

	.related-news {
		padding: 44px 0 38px;
	}

	.store-box {
		padding: 15px 22px 42px;
	}

	#google-map .store-full-info h1,
	#google-map h1,
	.back-store-page h1 {
		font-size: 16px;
	}

	#google-map h1 {
		text-align: left;
	}

	#google-map h1,
	.back-store-page h1 {
		margin-bottom: 15px;
	}

	#google-map .closest-shop {
		margin-bottom: 19px;
	}

	#google-map .buttons {
		margin: 0 -21px -42px;
	}

	#google-map .buttons button:last-child {
		font-size: 14px;
		padding-right: 0;
	}

	#google-map .store-full-info h1 {
		margin-bottom: 19px;
	}

	#google-map .store-full-info button:before {
		background: url('../images/arrow back.svg') no-repeat right;
		transform: rotate(0deg);
	}

	.back-store-page {
		padding: 33px 0 30px;
	}

	.store-hours .hours {
		gap: 15px;
	}

	.numb {
		font-size: 14px;
	}

	.stores-slider .embla_controls .embla_control {
		width: 23px;
		height: 23px;
		padding: 0 8px;
	}

	.stores-slider .embla_controls .embla_control.embla__prev svg {
		width: 5px;
		height: 9px;
	}

	.stores-slider .embla_controls .embla_control:hover {
		padding: 0 8px;
		width: 23px;
	}

	#google-map,
	#google-map.active {
		height: 641px;
	}

	.bread-none {
		display: none;
	}

	.back-news-page #breadcrumbs .bread-item {
		padding-right: 0;
		padding-left: 20px;
	}

	.back-news-page #breadcrumbs .bread-item:after {
		content: '';
		background: url('../images/arrow-back-black.svg') no-repeat right;
		width: 18px;
		height: 12px;
		left: -9px;
		top: 4px;
	}

	#contact .info-area .content-wrapper {
		flex-direction: column;
	}

	#contact .form-area {
		margin: -59px auto 0;
	}

	#contact .form-area .form-container form .form-row {
		flex-direction: column;
		align-items: flex-start;
		margin: 12px 0 13px;
	}

	#contact .form-area .form-container form label .attach-top {
		margin-top: 20px;
	}

	#contact textarea {
		padding: 11px 15px;
		min-height: 73px;
	}

	#contact .form-area .form-container form .black-arrow-button {
		text-align: center;
		width: 100%;
		padding-left: 15px;
	}

	#contact .form-area .form-container form .capcha-align {
		width: 100%;
		text-align: left;
		margin: 0;
	}

	#contact .form-area .form-container form .captcha-wrapper iframe {
		transform-origin: left;
	}

	#contact .form-area .form-container form .capcha-align .captcha-wrapper {
		width: fit-content;
	}

	#contact .cms-content {
		padding-bottom: 64px;
	}

	#contact .breadcrumbs-container {
		display: none;
	}

	.product-box:hover .quick-buy {
		display: none;
	}

	#pager {
		margin-top: 40px;
	}

	.homepage-blogs .title a {
		padding-right: 16px !important;
		font-size: 14px;
	}

	.homepage-blogs .title a::after {
		width: 10px !important;
		height: 11px;
		background-size: cover;
	}

	.homepage-blogs .title.flex-col h1 {
		font-size: 16px;
		line-height: 18px;
	}

	.homepage-blogs .title {
		margin-bottom: 21px;
	}

	#cart-widget .buttons-cart {
		flex-wrap: wrap;
		justify-content: center;
	}

	.notfound .content-wrapper {
		padding-top: 35px;
		padding-bottom: 9px;
	}
}

@media (max-width: 350px) {
	header #logo img {
		max-width: 85%;
		margin: auto;
	}
	/*
    header #logo {
        left: 70px;
        right: unset;
    }*/
}

@media (max-height: 800px) {
	.register-content main {
		min-height: unset;
		height: 100%;
	}
}

@media (max-height: 600px) {
	.login-content main {
		min-height: unset;
		height: 100%;
	}

	.modal-content {
		height: 100%;
	}
}

@media (max-height: 410px) {
	.guest-register-content main {
		min-height: unset;
		height: 100%;
	}
}

/*end mobile*/
